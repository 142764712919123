<template>
  <div :class="['input-wrapper', { '-small': alt === 'small' }]">
    <label :for="name" v-if="$te(`${base}.inputs.${name}.label`) && alt !== 'small'">
      {{ $t(`${base}.inputs.${name}.label`) }}
    </label>
    <div class="select-wrapper" :class="{ 'is-disabled': disabled }">
      <select :value="value" :name="name" v-on:change="updateValue($event.target.value)" :disabled="disabled">
        <slot></slot>
        <slot name="options">
          <option v-for="(option, key) in options" :key="key" :value="key">
            {{ option }}
          </option>
        </slot>
      </select>
    </div>
    <div class="error-msg" v-if="$te(`${base}.inputs.${name}.error`)">
      <slot name="errors">
        {{ $t(`${base}.inputs.${name}.error`) }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputSelect',
  props: {
    base: String,
    name: String,
    value: [String, Number],
    options: Object,
    alt: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-select.scss"></style>
