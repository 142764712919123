<template>
  <Modal :id="base" :title="$t(`${base}.title`)" class="-thin">
    <FormElement>
      <div class="row mb-s">
        <div class="col-12">
          <InputText :base="base" name="firstname" type="text" :class="{ '-has-error': $v.adminModel.firstname.$error }" v-model="$v.adminModel.firstname.$model" />
        </div>
        <div class="col-12">
          <InputText :base="base" name="lastname" type="text" :class="{ '-has-error': $v.adminModel.lastname.$error }" v-model="$v.adminModel.lastname.$model" />
        </div>
      </div>
      <div class="row mb-s">
        <div class="col-24">
          <div class="form-element" :class="{ '-has-error': notUniqueError }">
            <InputText :base="base" name="email" type="email" :class="{ '-has-error': notUniqueError || $v.adminModel.email.$error }" v-model="$v.adminModel.email.$model" />
            <div class="error-msg" v-if="notUniqueError">
              {{ $t(`${base}.inputs.email.errorNotUnique`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-s">
        <div class="col-12">
          <InputText
            name="password"
            :base="base"
            type="password"
            :class="{ '-has-error': $v.adminModel.password.$error }"
            v-model="$v.adminModel.password.$model"
          >
            <PasswordStrength :pwScore="pwScore"></PasswordStrength>
            <template v-slot:errors>
              <template v-if="$v.adminModel.password.$error">
                <template v-if="base === 'adminModalCreate' && !$v.adminModel.password.required">
                  {{ $t('signUpForm.inputs.password.error.required') }}
                </template>
                <template v-else-if="!$v.adminModel.password.minLength">
                  {{ $t('signUpForm.inputs.password.error.minLength') }}
                </template>
                <template v-else-if="!$v.adminModel.password.minPasswordStrength">
                  {{ $t('signUpForm.inputs.password.error.minPasswordStrength') }}
                </template>
                <template v-else-if="!$v.adminModel.password.maxLength">
                  {{ $t('signUpForm.inputs.password.error.maxLength') }}
                </template>
                <template v-else>
                  {{ $t('signUpForm.inputs.password.error.default') }}
                </template>
              </template>
            </template>
          </InputText>
        </div>
        <div class="col-12">
          <InputText
            name="confirmPassword"
            :base="base"
            type="password"
            :class="{ '-has-error': $v.adminModel.confirmPassword.$error }"
            v-model="$v.adminModel.confirmPassword.$model"
          ></InputText>
        </div>
      </div>
      <div class="row mb-s">
        <div class="col-24">
          <h6 class="h6 mb-xs">{{ $t(`${base}.inputs.group.label`) }}</h6>
          <div class="form-element" :class="{ '-has-error': $v.adminModel.group.$error }">
            <InputCheckbox
              v-for="(group, key) in $t(`${base}.inputs.group.values`)"
              :key="key"
              :base="base"
              :name="key"
              :inputValue="key"
              v-model="adminGroups"
              class="display-inlineblock mr-m mb-xs"
            >
              <template slot="label">
                {{ group }}
              </template>
            </InputCheckbox>
            <div class="error-msg" v-if="$v.adminModel.group.$error">
              {{ $t(`${base}.inputs.group.error`) }}
            </div>
          </div>
        </div>
      </div>
    </FormElement>
    <template v-slot:footer>
      <div class="col-12">
        <Button v-if="base === 'adminModalEdit'" class="-red -outlined" @click.native="handleDeleteAdmin(user.uuid)">
          <Icon class="-inline -is-left">
            <TrashSVG/>
          </Icon>
          {{ $t(`${base}.buttons.delete`) }}
        </Button>
      </div>
      <div class="col-12 text-right">
        <Button class="mr-s -orange -outlined" @click.native="closeModal">
          {{ $t(`${base}.buttons.cancel`) }}
        </Button>
        <Button class="-orange" @click.native="saveAndExit">
          {{ $t(`${base}.buttons.save`) }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';
import pwScore from '@/mixins/pwScore';
import adminActions from '@/mixins/adminActions';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Modal from '@/components/elements/Modal.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import PasswordStrength from '@/components/elements/PasswordStrength.vue';

import TrashSVG from '@/assets/icons/trash.svg';

const PW_MIN_LENGTH = 6;
const PW_MAX_LENGTH = 32;

const minCount = (value) => ((value.length >= 1));

const blankAdmin = {
  firstname: '',
  lastname: '',
  password: '',
  confirmPassword: '',
  email: '',
  group: [],
  active: 1,
  role: 'admin',
  language: 'de',
};

export default {
  name: 'AdminModal',
  props: {
    base: String,
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [validationMixin, pwScore, adminActions],
  data() {
    return {
      notUniqueError: false,
      adminModel: {
        ...blankAdmin,
      },
    };
  },
  computed: {
    adminGroups: {
      get() {
        return this.adminModel.group;
      },
      set(newValue) {
        const deactivated = newValue.indexOf('deactivated');

        if (deactivated === 0) {
          this.adminModel.group = newValue.slice(1);
        } else if (deactivated === newValue.length - 1) {
          this.adminModel.group = newValue.slice(-1);
        } else {
          this.adminModel.group = newValue;
        }
      },
    },
  },
  async mounted() {
    this.adminModel = { ...blankAdmin, ...this.user };
  },
  watch: {
    user(newVal) {
      this.adminModel = { ...blankAdmin, ...newVal };
    },
  },
  methods: {
    resetUser() {
      this.$set(this, 'adminModel', { ...blankAdmin });
      this.notUniqueError = false;
      this.$v.$reset();
    },

    closeModal() {
      this.$eventBus.$emit(`close-modal-${this.base}`, true);
      this.pwScore = -1;
    },

    saveAndExit() {
      this.notUniqueError = false;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.base === 'adminModalCreate') {
          this.handleCreateAdmin(this.adminModel);
        }

        if (this.base === 'adminModalEdit') {
          this.handleEditAdmin(this.user.uuid, {
            firstname: this.adminModel.firstname,
            lastname: this.adminModel.lastname,
            ...(helpers.req(this.adminModel.password)) && { password: this.adminModel.password },
            email: this.adminModel.email,
            group: this.adminModel.group,
          });
        }
      }
    },

    handleApiSuccess() {
      this.$emit('update');
      this.$eventBus.$emit('notificate', { title: this.$t(`notifications.${this.base}.success.title`), message: this.$t(`notifications.${this.base}.success.message`) });
      this.resetUser();
      this.closeModal();
    },

    handleApiError(error) {
      if (error.response.status === 409 && error.response.data.message === 'unique violation') {
        this.notUniqueError = true;
      } else {
        this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.data.message });
      }
    },
  },
  validations() {
    let passwordRules = {};

    if (this.base === 'adminModalEdit') {
      passwordRules = {
        password: {
          minLength: function minL(value) {
            return !helpers.req(value) || value.length >= PW_MIN_LENGTH;
          },
          maxLength: function maxL(value) {
            return !helpers.req(value) || value.length <= PW_MAX_LENGTH;
          },
          minPasswordStrength: function minPwStr(value) {
            return !helpers.req(value) || this.minPasswordStrength(value);
          },
        },
      };
    } else {
      passwordRules = {
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(32),
          minPasswordStrength: function minPwStr(value) {
            return this.minPasswordStrength(value);
          },
        },
      };
    }

    return {
      adminModel: {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        ...passwordRules,
        confirmPassword: {
          sameAs: sameAs('password'),
        },
        group: { minCount },
      },
    };
  },
  components: {
    Modal,
    Button,
    Icon,
    InputText,
    InputCheckbox,
    FormElement,
    PasswordStrength,
    TrashSVG,
  },
};
</script>
