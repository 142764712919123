<template>
  <div>
    <a v-if="userCan('editAdmins')" @click.prevent.stop="$emit('openEdit', user.uuid)">{{ $t('adminList.actions.edit') }}</a>
    <a v-if="userCan('deleteAdmins')" @click.prevent.stop="handleDeleteAdmin(user.uuid)">{{ $t('adminList.actions.delete') }}</a>
  </div>
</template>

<script>
import adminActions from '@/mixins/adminActions';

export default {
  name: 'AdminListActions',
  mixins: [adminActions],
  props: {
    user: Object,
  },
};
</script>
