<template>
  <div class="radio-wrapper">
    <input type="radio"
           :name="name"
           :id="`${idPrefix}${name}-${choice}`"
           :value="choice"
           :checked="value === choice"
           :disabled="disabled"
           v-on:input="updateValue($event.target.value)">
    <label :for="`${idPrefix}${name}-${choice}`">
      <slot name="label"></slot>
    </label>
    <slot></slot>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'InputRadio',
  props: {
    base: String,
    name: String,
    choice: [String, Number],
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    id: String,
  },
  computed: {
    idPrefix() {
      return this.id ? `${this.id}-` : _.uniqueId();
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-radio.scss"></style>
