<template>
  <div :class="['input-wrapper', { '-small': alt === 'small' }]">
    <label :for="name">
      <slot name="label">
        <template v-if="$te(`${base}.inputs.${name}.label`) && alt !== 'small'">
          {{ $t(`${base}.inputs.${name}.label`) }}
        </template>
      </slot>
    </label>
    <input type="text"
           :name="name"
           :placeholder="($te(`${base}.inputs.${name}.placeholder`)) ? $t(`${base}.inputs.${name}.placeholder`) : false"
           :disabled="disabled"
           @blur="showCurrency = true"
           @focus="showCurrency = false"
           :value="formattedValue"
           v-on:change="updateValue($event.target.value)">
    <div class="error-msg" v-if="$te(`${base}.inputs.${name}.error`)">
      <slot name="errors">
        {{ $t(`${base}.inputs.${name}.error`) }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputCurrency',
  props: {
    base: String,
    name: String,
    value: {
      type: [String, Number],
    },
    prepend: {
      type: String,
      default: '',
    },
    append: {
      type: String,
      default: ' €',
    },
    alt: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCurrency: true,
    };
  },
  computed: {
    formattedValue() {
      if (this.showCurrency) {
        return `${this.prepend}${this.value}${this.append}`;
      }

      if (this.value) {
        return this.value;
      }

      return '';
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', Math.abs(parseInt(value.trim(), 10)));
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-currency.scss"></style>
