<template>
  <div class="lead-list">
    <div class="_filter">
      <div class="row" :class="{ ' display-flex  align-items-center': !$matchMedia.md }">
        <div class="col-4 col-lg-5 col-md-8">
          <InputText name="search" base="leadList.filter" class="_search -light" v-model="selectedFilter.search" @input="updateList" type="text">
            <Icon class="-size-20"><SearchSVG></SearchSVG></Icon>
          </InputText>
        </div>

        <div class="col-4 col-lg-5 col-md-8 valign-bottom">
          <InputSelect name="status" class="-light" base="leadList.filter" v-model="selectedFilter.status" :options="$t('leadList.filter.inputs.status.values')" @input="updateList">
            <option selected value="">
              {{ $t('leadList.filter.inputs.status.placeholder') }}
            </option>
          </InputSelect>
        </div>

        <div class="col-4 col-lg-5 col-md-8 valign-bottom">
          <InputSelect name="profit_center" class="-light" base="leadList.filter" v-model="selectedFilter.profit_center" :options="$t('projectInternals.inputs.pc.values')" @input="updateList">
            <option selected value="">
              {{ $t('leadList.filter.inputs.profit_center.placeholder') }}
            </option>
          </InputSelect>
        </div>

        <div class="col-4 col-lg-5 col-md-8 valign-bottom">
          <InputSelect name="assignee" class="-light" base="leadList.filter" v-model="selectedFilter.assignee" @input="updateList">
            <option selected value="">
              {{ $t('leadList.filter.inputs.assignee.placeholder') }}
            </option>
            <option v-for="admin in assigneeList" :key="admin.uuid" :value="admin.uuid">{{ admin.lastname }}, {{ admin.firstname }}</option>
          </InputSelect>
        </div>
      </div>
    </div>

    <div class="row mt-m-more lead-list-head">
      <div class="col-6">
        <a class="label -light" @click="handleSort('title')">
          {{ $t('leadList.fields.title.label') }}
          <Icon v-if="selectedSort.value === 'title'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-4">
        <a class="label -light" @click="handleSort('client')">
          {{ $t('leadList.fields.client.label') }}
          <Icon v-if="selectedSort.value === 'client'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-2">
        <a class="label -light" @click="handleSort('custom_id')">
          {{ $t('leadList.fields.custom_id.label') }}
          <Icon v-if="selectedSort.value === 'custom_id'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-3">
        <a class="label -light" @click="handleSort('status')">
          {{ $t('leadList.fields.status.label') }}
          <Icon v-if="selectedSort.value === 'status'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-2">
        <a class="label -light" @click="handleSort('profit_center')">
          {{ $t('leadList.fields.profit_center.label') }}
          <Icon v-if="selectedSort.value === 'profit_center'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-3">
        <a class="label -light" @click="handleSort('responsible.User.lastname')">
          {{ $t('leadList.fields.responsible.label') }}
          <Icon v-if="selectedSort.value === 'responsible.User.lastname'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-1">
        <a class="label -light" @click="handleSort('matches_count')">
          {{ $t('leadList.fields.matches.label') }}
          <Icon v-if="selectedSort.value === 'matches_count'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>

      <div class="col-2">
        <a class="label -light" @click="handleSort('updatedAt')">
          {{ $t('leadList.fields.updatedAt.label') }}
          <Icon v-if="selectedSort.value === 'updatedAt'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
    </div>

    <div class="lead-list-wraper">
      <router-link
        class="row lead-item"
        v-for="lead in leads"
        :key="lead.id"
        :to="{
          name: 'LeadDetail',
          params: {
            id: lead.id,
            lang: $i18n.locale,
          },
        }"
        :class="{ '-last-visited': lead.id === lastVisited }"
      >
        <div class="col-6">
          <span class="_title font-weight-semibold text-truncate">{{ lead.title }}</span>
        </div>

        <div class="col-4">
          <span class="font-weight-medium text-truncate">{{ lead.client }}</span>
        </div>

        <div class="col-2">
          <span class="font-weight-medium">{{ lead.custom_id }}</span>
        </div>

        <div class="col-3">
          <LeadStatus :statusKey="lead.status"></LeadStatus>
        </div>

        <div class="col-2">
          <span class="font-weight-medium">{{ $t(`projectInternals.inputs.pc.values.${lead.profit_center}`) }}</span>
        </div>

        <div class="col-3">
          <span class="font-weight-medium">
            <template v-if="lead.responsible">
              {{ lead.responsible.User.lastname }}, {{ lead.responsible.User.firstname }}
            </template>
            <template v-else>
              {{ $t('comments.deleted_user') }}
            </template>
          </span>
        </div>

        <div class="col-1">
          <span class="font-weight-medium">{{ lead.matches_count }}</span>
        </div>

        <div class="col-2">
          <span class="font-weight-medium">{{ lead.updatedAt | formatDate }}</span
          >&nbsp;
          <span class="font-weight-medium color-grey-400">({{ lead.updatedAt | formatTime }})</span>
        </div>

        <div class="col-1 pos-relative text-right">
          <FlyoutNav class="mt-xs" :ref="`flyout${lead.id}`">
            <LeadListActions :lead="lead" @update="updateList" @openEdit="handleEditLead" />
          </FlyoutNav>
          <FlyoutTrigger :flyoutEl="`flyout${lead.id}`" :parentRefs="$refs">
            <Icon class="-size-40 -outlined p-xxs-less -bg-white"><DotsSVG></DotsSVG></Icon>
          </FlyoutTrigger>
        </div>
      </router-link>
    </div>

    <div v-if="leads.length <= 0 && !loading" class="mt-xs">
      <div class="row">
        <div class="col-6">
          <h5 class="h5 mt-xs color-white pos-relative">
            {{ $t('leadList.noResults') }}
          </h5>
        </div>
      </div>
    </div>

    <div class="row mt-l" v-if="loading">
      <div class="col-24">
        <div class="_filter-info text-center">
          <Icon class="-size-24 color-white"><LoadingSVG></LoadingSVG></Icon>
        </div>
      </div>
    </div>

    <div v-if="leads.length === (offset + 1) * limit" class="text-center mt-xs">
      <Button class="-orange" @click.native="loadMore">{{ $t('leadList.loadMore') }}</Button>
    </div>

    <LeadModal base="leadModalCreate" @update="updateList" />
    <LeadModal base="leadModalEdit" @update="updateList" :lead="leadToEdit" />
  </div>
</template>

<script>
import { possibleAssignees } from '@/values';
import { listLeads } from '@/api/leads.api';
import { parseDates, queryObjects } from '@/mixins';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import LeadStatus from '@/components/atoms/LeadStatus.vue';
import ChevronFullSVG from '@/assets/icons/chevron-full.svg';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import SearchSVG from '@/assets/icons/search.svg';
import LeadListActions from '@/components/modules/Lead/admin/LeadListActions.vue';
import LeadModal from '@/components/modules/Lead/admin/LeadModal.vue';

export default {
  name: 'LeadList',

  mixins: [parseDates, queryObjects],

  props: {
    lastVisited: Number,
  },

  data() {
    return {
      leads: [],
      leadToEdit: null,
      assigneeList: [],
      selectedFilter: {},
      selectedSort: {
        value: 'updatedAt',
        direction: 'desc',
      },
      limit: 50,
      offset: 0,
      debounce: null,
      loading: true,
    };
  },

  computed: {},

  activated() {
    if (!this.$parent.savedPosition) {
      this.getPossibleAnsignees();
      this.updateList();
    }
  },

  methods: {
    getPossibleAnsignees() {
      this.$store
        .dispatch('getAdminList')
        .then((adminList) => {
          this.assigneeList = adminList.filter((admin) => admin.group.some((group) => possibleAssignees.leads.includes(group)));
        })
        .catch((error) => console.error(error));
    },

    updateList() {
      this.offset = 0;
      this.fetchLeads();
    },

    loadMore() {
      this.offset += 1;
      this.fetchLeads();
    },

    fetchLeads() {
      this.loading = true;
      this.$store.dispatch('isLoggedIn').then((token) => {
        listLeads(this.getFilterQuery(), token)
          .then((response) => response.data)
          .then(this.handleLeadsData);
      });
    },

    handleLeadsData({ data }) {
      if (this.offset <= 0) {
        this.$set(this, 'leads', data);
      } else {
        this.$set(this, 'leads', [...this.leads, ...data]);
      }

      this.loading = false;
    },

    handleSort(value) {
      if (this.selectedSort.value !== value) {
        this.$set(this.selectedSort, 'direction', 'desc');
      } else {
        this.$set(this.selectedSort, 'direction', this.selectedSort.direction === 'asc' ? 'desc' : 'asc');
      }

      this.$set(this.selectedSort, 'value', value);
      this.updateList();
    },

    getFilterQuery() {
      const filter = this.getFilter();

      return {
        ...this.buildFilterQuery(filter),
        ...this.buildSortQuery(this.selectedSort),
        limit: this.limit,
        offset: this.offset,
      };
    },

    getFilter() {
      const { search, ...filter } = this.selectedFilter;

      return {
        ...filter,
        query: {
          and: [...(search ? this.buildSearchFilter(search) : [])],
        },
      };
    },

    buildSearchFilter(input) {
      const splitInput = input.split(/[\s,]+/).filter((el) => el.trim().length > 0);
      const searchIn = ['$title$', '$custom_id$'];

      return splitInput.map((el) => ({
        or: searchIn.map((arg) => ({
          [arg]: {
            regexp: `${el}`,
          },
        })),
      }));
    },

    handleEditLead(id) {
      this.leadToEdit = this.leads.find((lead) => lead.id === id);
      this.$eventBus.$emit('open-modal-leadModalEdit');
    },
  },

  components: {
    Button,
    Icon,
    LeadStatus,
    ChevronFullSVG,
    InputText,
    InputSelect,
    FlyoutNav,
    FlyoutTrigger,
    DotsSVG: () => import('@/assets/icons/dots.svg'),
    LoadingSVG: () => import('@/assets/icons/loader.svg'),
    SearchSVG,
    LeadListActions,
    LeadModal,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/lead-list.scss"></style>
