<template>
  <section class="manager-comments">
    <ContentBox>
      <h4 class="h4">{{ $t('managerComments.headline') }}</h4>
      <div class="comment-list-wrapper">
        <CommentList :comments="descComments" ref="commentsList" />
      </div>
    </ContentBox>
  </section>
</template>
<script>
import { getComments } from '@/api/admins.api';

import ContentBox from '@/components/elements/ContentBox.vue';
import CommentList from '@/components/elements/CommentList.vue';

export default {
  name: 'ManagerComments',
  props: {
    uuid: String,
  },
  data() {
    return {
      comments: [],
    };
  },
  computed: {
    descComments() {
      return [...this.comments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
  },
  created() {
    this.loadComments();
    this.$eventBus.$on('comments-modal-updated', this.loadComments);
  },
  destroyed() {
    this.$eventBus.$off('comments-modal-updated', this.loadComments);
  },
  methods: {
    loadComments() {
      return this.$store.dispatch('isLoggedIn')
        .then((token) => getComments(this.uuid, token))
        .then((response) => {
          this.comments = response.data.comments;
        });
    },
  },
  components: {
    ContentBox,
    CommentList,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-comments.scss"></style>
