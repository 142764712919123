<template>
  <div class="project-applicant-detail" v-if="applicant.id">
    <div class="header">
      <div class="_header-inner  border-bottom  -blue-100">
        <div class="row  display-flex  align-items-center  pt-xs  pb-xs">
          <div class="col-12" v-if="editable">
            <div class="pos-relative">
              <FlyoutTrigger :flyoutEl="`flyout${applicant.id}`">
                <div class="display-flex  align-items-center">
                  <div class="status">{{ $t(`projectApplications.fields.${applicant.status}.label`) }}</div>
                  <Icon class="-size-18">
                    <ChevronSVG></ChevronSVG>
                  </Icon>
                </div>
              </FlyoutTrigger>
              <FlyoutNav class="-left-0  -right-auto" :ref="`flyout${applicant.id}`">
                <a v-for="state in applicantStatus" :key="state"
                   :disabled="applicant.status === state"
                   @click.prevent="handleSetStatus(state, applicant.id)">
                  {{ $t(`projectApplications.actions.${state}`) }}
                </a>
              </FlyoutNav>
            </div>
          </div>
          <div :class="[editable ? 'col-12' : 'col-24']">
            <div class="display-flex  justify-content-end">
              <a class="mr-xs -bordered-link">
                <AnchorLink @click.native="$eventBus.$emit('open-modal-match', { managerUuid: applicant.ManagerUuid })">
                  <Icon class="-size-24">
                    <PersonAddSVG></PersonAddSVG>
                  </Icon>
                </AnchorLink>
              </a>
              <AnchorLink class="mr-xs -bordered-link" @click.native="$eventBus.$emit('open-modal-comments', { manager: applicant.ManagerUuid, commentableType: 'ProjectApplication', commentableId: applicant.id })">
                <Icon class="-size-24">
                  <CommentsSVG></CommentsSVG>
                </Icon>
              </AnchorLink>
              <router-link class="mr-xs -bordered-link" :to="{ name: 'UserDetail', params: { username: applicant.Manager.username }}" target="_blank">
                <Icon class="-size-24">
                  <ContactPageSVG></ContactPageSVG>
                </Icon>
              </router-link>
              <a class="mr-xs -bordered-link">
                <AnchorLink @click.native="getDocument">
                  <Icon class="-size-24">
                    <DownloadSVG></DownloadSVG>
                  </Icon>
                </AnchorLink>
              </a>
              <router-link class="-bordered-link" :to="{ name: 'ProjectApplications', params: { projectId, lang: $i18n.locale }}">
                <Icon class="-size-24">
                  <CloseSVG></CloseSVG>
                </Icon>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-header">
        <div class="date">{{ applicant.Project.application_deadline | formatDate }}</div>
        <div class="title">
          <router-link :to="{ name: 'UserDetail', params: { username: applicant.Manager.username }}" target="_blank">
            {{ applicant.Manager.User.firstname }} {{ applicant.Manager.User.lastname }}
          </router-link>
        </div>
        <div class="row">
          <div class="col-5">
            <div class="font-size-s  font-weight-medium">{{ $t('projectApplications.project.headline') }}:</div>
          </div>
          <div class="col-19">
            <AnchorLink class="-orange font-size-s font-weight-medium"
                        @click.native="$router.push({ name: 'ProjectDetail', params: { id: projectId, lang: $i18n.locale } })">
              <span>{{ applicant.Project.title }}</span>
            </AnchorLink>
            <div class="display-flex  align-items-center  mt-xxs">
              <template v-if="applicant.Project.responsible">
                <Avatar class="mr-xxs">
                  <img class="display-block" :src="require('@/assets/taskforce-avatar.png')">
                </Avatar>
                <div class="font-size-s  font-weight-medium">{{ applicant.Project.responsible.User.firstname }} {{ applicant.Project.responsible.User.lastname }}</div>
              </template>
              <template v-else>
                <div class="font-size-s  font-weight-normal  color-blue-800">{{ $t('projectApplications.detail.responsible.notFound') }}</div>
              </template>
            </div>
          </div>
        </div>
        <div class="row  mt-s">
          <div class="col-5">
            <div class="font-size-s  font-weight-medium">{{ $t('projectApplications.application_deadline.label') }}:</div>
          </div>
          <div class="col-19">
            <div class="display-flex  align-items-center">
              <Icon class="-size-20  mr-xxs">
                <CalendarSVG></CalendarSVG>
              </Icon>
              <div class="font-size-s  font-weight-medium">{{ applicant.Project.application_deadline | formatDate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(message, index) in allMessages" :key="message.id" class="content-message-wrapper" :class="{'last-element': message === lastElement}">
        <div class="content-message-box"
             v-if="index === 0 || checkMessageAddresser(message.from.emailAddress.address)">
          <div class="row">
            <div class="display-flex  align-items-start  justify-content-start">
              <Avatar class="ml-xxs">
                <img class="display-block" :src="managerAvatar || require('@/assets/default-avatar.png')">
              </Avatar>
              <div class="col-20">
                <div class="content-message-date">{{ message.receivedDateTime | formatDateTime }} {{ $t('dateTime.time.clock') }}</div>
                <div class="content-message-body  border-top-radius-left-0">
                  <div v-html="message.uniqueBody.content" v-target-blank></div>
                  <div v-if="applicationCV && index === 0">
                    <div class="sub-head">
                      {{ $t('projectApplications.detail.message.file.sent') }}:
                    </div>
                    <AnchorLink class="-orange  font-size-s" @click.native="getDocument">
                      <Icon class="-size-18">
                        <PaperclipSVG></PaperclipSVG>
                      </Icon>
                      {{index === 0 ? linkToManagerCv : ''}}
                    </AnchorLink>
                  </div>
                  <div v-if="message.hasAttachments">
                    <div class="sub-head">{{ $t('projectApplications.detail.attachments.forward.subhead') }}</div>
                    <AnchorLink class="-orange  font-size-s"
                                @click.native="forwardOriginalMessage(message)"
                                target="_blank"
                                v-if="message.hasAttachments">
                      {{ $t('projectApplications.detail.attachments.forward.link') }}
                    </AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="content-message-box">
          <div class="row">
            <div class="display-flex  align-items-start  justify-content-end">
              <div class="col-20">
                <div class="content-message-date  text-right">{{ message.receivedDateTime | formatDateTime }} {{ $t('dateTime.time.clock') }}</div>
                <div class="content-message-body  border-top-radius-right-0">
                  <div v-html="message.uniqueBody.content" v-target-blank></div>
                </div>
              </div>
              <Avatar>
                <img class="display-block" :src="require('@/assets/taskforce-avatar.png')">
              </Avatar>
            </div>
          </div>
        </div>
      </div>
      <div v-if="emailMessages.length <= 0" class="content-message-wrapper">
        <p>{{ $t('projectApplications.errors.messages.notFound') }}</p>
      </div>
    </div>
    <div class="content-message-field">
      <InputTextarea v-model="message"></InputTextarea>
      <div class="buttons-wrapper">
        <div class="buttons">
          <div class="pos-relative">
            <FlyoutTrigger flyoutEl="messageSnippet">
              <div class="display-flex  align-items-center">
                <Icon class="add-icon  -size-20"><AddSVG class="color-grey-400"></AddSVG></Icon>
                <div class="text-template">{{$t('projectApplications.detail.inputs.message.buttons.template')}}</div>
              </div>
            </FlyoutTrigger>
            <FlyoutNav class="message-snippets-flyout" ref="messageSnippet">
              <a
                v-for="(snippet, snippetKey) in messageSnippets"
                :key="snippet.label"
                @click="pushMessageSnippetToTextarea(snippetKey)">
                  {{snippet.label}}
              </a>
            </FlyoutNav>
          </div>
          <Button @click.native="sendMessage" :class="['-orange  -thin', {'-disabled': buttonDisabled}]" :disabled="buttonDisabled">
            {{$t('projectApplications.detail.inputs.message.buttons.send')}}
            <Icon class="ml-xs  -size-14">
              <SendSVG></SendSVG>
            </Icon>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import parseDates from '@/mixins/parseDates';

import Icon from '@/components/atoms/Icon.vue';
import CloseSVG from '@/assets/icons/close.svg';
import ChevronSVG from '@/assets/icons/chevron.svg';
import CalendarSVG from '@/assets/icons/calendar.svg';
import SendSVG from '@/assets/icons/send.svg';
import AddSVG from '@/assets/icons/add.svg';
import CommentsSVG from '@/assets/icons/comments.svg';
import ContactPageSVG from '@/assets/icons/contact-page.svg';
import PersonAddSVG from '@/assets/icons/person-add.svg';
import DownloadSVG from '@/assets/icons/download.svg';
import PaperclipSVG from '@/assets/icons/paperclip.svg';
import Avatar from '@/components/atoms/Avatar.vue';
import AnchorLink from '@/components/atoms/Link.vue';
import Button from '@/components/atoms/Button.vue';
import InputTextarea from '@/components/elements/inputs/InputTextarea.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import { getProjectApplication, sendProjectApplicationMessage, forwardProjectApplicationOriginalMessage } from '@/api/projects.api';
import { getUserAsset, getDocuments, getDocument } from '@/api/users.api';

export default {
  name: 'ProjectApplicantDetail',
  mixins: [parseDates],
  props: {
    projectId: Number,
    applicationId: Number,
  },
  directives: {
    targetBlank: {
      // eslint-disable-next-line func-names, object-shorthand
      inserted: function (el) {
        const links = el.querySelectorAll('a');
        links.forEach((link) => {
          // eslint-disable-next-line no-param-reassign
          link.target = '_blank';
        });
      },
    },
  },
  data() {
    return {
      addresser: ['no-reply@taskforce.net', 'cocoon-applications@me-company.de', 'project-application@taskforce.net'],
      emailMessages: [],
      applicant: {},
      message: null,
      avatarManager: '',
      applicantStatus: ['unset', 'shortlist', 'longlist', 'backlog', 'onHold', 'accepted', 'declined'],
    };
  },
  computed: {
    ...mapState(['usermeta']),

    managerAvatar() {
      return this.avatarManager;
    },

    applicationCV() {
      if (!this.applicant.DocumentId) {
        return this.applicant.documents[0] ?? null;
      }

      if (!this.applicant.documents) {
        return null;
      }

      return this.applicant.documents.find((document) => document.id === this.applicant.DocumentId) ?? null;
    },

    linkToManagerCv() {
      return `${this.$t('projectApplications.detail.link.managerProfile.label')}-${this.applicant.Manager.User.firstname}-${this.applicant.Manager.User.lastname}.pdf`;
    },

    allMessages() {
      return this.emailMessages;
    },

    buttonDisabled() {
      return this.allMessages.length === 0 || !this.message || !this.editable;
    },

    messageSnippets() {
      return this.$t('projectApplications.messageSnippets');
    },

    lastElement() {
      return this.allMessages[this.allMessages.length - 1];
    },

    editable() {
      if (this.applicant.id) {
        return (this.applicant?.Project.status !== 'staffed');
      }

      return false;
    },
  },
  mounted() {
    this.fetchApplicant();
  },
  watch: {
    applicationId(newVal) {
      if (!newVal) {
        this.$set(this, 'applicant', {});
      } else {
        this.fetchApplicant();
      }
    },
  },
  methods: {
    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t(`projectApplications.confirm.${action}`)}`);
    },

    getManagerAvatar() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          getUserAsset(this.applicant.ManagerUuid, 'avatar', token)
            .then((res) => {
              const file = new Blob([res.data], { type: 'image/jpg' });
              this.blobToBase64(file)
                .then((imgBase64) => {
                  this.$set(this, 'avatarManager', imgBase64);
                });
            })
            .catch(() => {});
        });
    },

    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },

    getDocumentList() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => getDocuments(this.applicant.ManagerUuid, token))
        .then((response) => {
          this.$set(this.applicant, 'documents', response.data.data);
        });
    },

    async getDocument() {
      const cvWindow = window.open();

      try {
        const token = await this.$store.dispatch('isLoggedIn');
        const response = await getDocument(this.applicant.ManagerUuid, this.applicationCV.filename, token);
        const file = new Blob([response.data], { type: response.data.type });
        cvWindow.location.href = URL.createObjectURL((file));
      } catch (err) {
        cvWindow.location.href = '/404';
        this.$eventBus.$emit('notificate', { status: 404, title: this.$t('userMeta.fields.managerPdf.error') });
      }
    },

    sendMessage() {
      if (!this.message) return;
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          sendProjectApplicationMessage(this.projectId, this.applicationId, { content: this.message }, token)
            .then(() => {
              this.$eventBus.$emit('notificate', {
                title: this.$t('notifications.projectApplicationDetail.send.message.title'),
                message: this.$t('notifications.projectApplicationDetail.send.message.text'),
              });
              this.message = '';
              this.fetchApplicant();
              this.scrollToElement();
            })
            .catch((error) => {
              console.error(error);
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    forwardOriginalMessage({ id, receivedDateTime }) {
      if (!this.confirmAction('forwardMessage')) {
        return;
      }

      const date = new Date(receivedDateTime).toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          forwardProjectApplicationOriginalMessage(this.projectId, this.applicationId, {
            message: {
              id,
              comment: this.$t('projectApplications.detail.attachments.forward.comment', {
                date,
              }),
            },
          }, token)
            .then(() => {
              this.$eventBus.$emit('notificate', {
                title: this.$t('notifications.projectApplicationDetail.forward.message.title'),
                message: this.$t('notifications.projectApplicationDetail.forward.message.text'),
              });
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    scrollToElement() {
      const element = this.$el.querySelector('.content-message-wrapper.last-element');
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      }
    },

    checkMessageAddresser(from) {
      return this.applicant.Manager.User.email === from || !this.addresser.includes(from);
    },

    pushMessageSnippetToTextarea(snippetKey) {
      const formalGreeting = this.$t(`projectApplication.userMeta.inputs.formalTitle.values.${this.applicant.Manager.formalTitle}`);
      this.message = this.$t(`projectApplications.messageSnippets.${snippetKey}.content`, {
        projectTitle: this.applicant.Project.title, greeting: `${formalGreeting} ${this.applicant.Manager.User.lastname}`,
      });
    },

    handleSetStatus(status, applicationId) {
      this.$eventBus.$emit('handle-update-applicant-status', status, applicationId);
      this.fetchApplicant();
    },

    sortByDate(messages) {
      return [...messages].sort((a, b) => new Date(a.receivedDateTime) - new Date(b.receivedDateTime));
    },

    fetchApplicant() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          getProjectApplication(this.projectId, this.applicationId, token)
            .then((result) => result.data)
            .then(({ data, messages }) => {
              if (messages?.status !== 400) {
                this.$set(this, 'emailMessages', this.sortByDate(messages));
              }
              this.$set(this, 'applicant', data);
              this.getDocumentList();
              this.getManagerAvatar();
            })
            .catch((error) => {
              console.error(error);
            });
        });
    },
  },
  components: {
    Icon,
    CloseSVG,
    ChevronSVG,
    CalendarSVG,
    SendSVG,
    Avatar,
    AddSVG,
    AnchorLink,
    PaperclipSVG,
    CommentsSVG,
    ContactPageSVG,
    DownloadSVG,
    PersonAddSVG,
    InputTextarea,
    FlyoutTrigger,
    FlyoutNav,
    Button,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-applicant-detail.scss"></style>
