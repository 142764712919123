<template>
  <ul :class="['list', { '-no-bulls': !bullets }]">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'List',
  props: {
    bullets: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/list.scss"></style>
