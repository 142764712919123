<template>
  <section class="project-meta">
    <ContentBox>
      <FormElement>
        <div class="row">
          <div class="col-24">
            <InputText alt="big"
                       :class="{ '-has-error': $v.projectMeta.title.$error }"
                       type="text"
                       name="title"
                       base="projectMeta"
                       :disabled="!editMode"
                       @input="userEdited = true"
                       v-model="$v.projectMeta.title.$model"></InputText>
          </div>
        </div>
        <hr class="-grey-200  mt-xs  mb-s">
        <div class="row">
          <div class="col-8  col-md-12  col-sm-24  mb-md-xs">
            <table>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.start.label') }}:
                </td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectMeta.start.$error }"
                               base="projectMeta"
                               name="start"
                               :options="$t('projectMeta.inputs.start.values')"
                               :disabled="!editMode"
                               @input="userEdited = true"
                               v-model="$v.projectMeta.start.$model">
                    <option value="" selected disabled>{{ $t('projectMeta.inputs.start.placeholder') }}</option>
                  </InputSelect>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.duration.label') }}:
                </td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectMeta.duration.$error }"
                               base="projectMeta"
                               name="duration"
                               :options="$t('projectMeta.inputs.duration.values')"
                               :disabled="!editMode"
                               @input="userEdited = true"
                               v-model="$v.projectMeta.duration.$model">
                    <option value="" selected disabled>{{ $t('projectMeta.inputs.duration.placeholder') }}</option>
                  </InputSelect>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.application_deadline.label') }}:
                </td>
                <td>
                  <InputText alt="small"
                             :class="{ '-has-error': $v.projectMeta.application_deadline.$error }"
                             type="date"
                             name="application_deadline"
                             base="projectMeta"
                             :disabled="!editMode"
                             @input="userEdited = true"
                             v-model="$v.projectMeta.application_deadline.$model"></InputText>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-8  col-md-12  col-sm-24  mb-md-xs">
            <table>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.availability.label') }}:
                </td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectMeta.availability.$error }"
                               base="projectMeta"
                               name="availability"
                               :options="$t('projectMeta.inputs.availability.values')"
                               :disabled="!editMode"
                               @input="userEdited = true"
                               v-model="$v.projectMeta.availability.$model">
                    <option value="" selected disabled>{{ $t('projectMeta.inputs.availability.placeholder') }}</option>
                  </InputSelect>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.contract.label') }}:
                </td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectMeta.contract.$error }"
                               base="projectMeta"
                               name="contract"
                               :options="$t('projectMeta.inputs.contract.values')"
                               :disabled="!editMode"
                               @input="userEdited = true"
                               v-model="$v.projectMeta.contract.$model">
                  </InputSelect>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('projectMeta.buttons.messages.label') }}:
                </td>
                <td>
                  <AnchorLink class="-orange pl-xxs pr-xxs"
                              @click.native="$router.push({ name: 'ProjectApplications', params: { projectId: project.id, lang: $i18n.locale } })">
                    <span>{{ $t('projectMeta.buttons.messages.title') }}</span>
                  </AnchorLink>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-8  col-md-12  col-sm-24">
            <table>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.country.label') }}:
                </td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectMeta.country.$error }"
                               base="projectMeta"
                               name="country"
                               :options="$t('projectMeta.inputs.country.values')"
                               :disabled="!editMode"
                               @input="userEdited = true"
                               v-model="$v.projectMeta.country.$model">
                  </InputSelect>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('projectMeta.inputs.zipcode.label') }}:
                </td>
                <td>
                  <InputText alt="small"
                             :class="{ '-has-error': $v.projectMeta.zipcode.$error }"
                             type="text"
                             name="zipcode"
                             base="projectMeta"
                             :disabled="!editMode"
                             @input="userEdited = true"
                             v-model="$v.projectMeta.zipcode.$model"></InputText>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </FormElement>
      <div class="_footer">
        <hr class="-grey-200  mb-s">
        <div class="row">
          <div class="col-24  text-right">
            <Button v-if="editMode" class="-orange  -thin" @click.native="toggleEditMode">{{ $t('projectMeta.buttons.save') }}</Button>
            <Button v-if="!editMode" class="-grey  -thin" @click.native="toggleEditMode">{{ $t('projectMeta.buttons.edit') }}</Button>
            <Button class="-grey  -thin  ml-xs" @click.native="cancelEdit" v-if="editMode && !init">
              <Icon class="-size-20"><CloseSVG></CloseSVG></Icon>
            </Button>
          </div>
        </div>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';
import { patchProject } from '@/api/projects.api';
import parseDates from '@/mixins/parseDates';

import AnchorLink from '@/components/atoms/Link.vue';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';

import CloseSVG from '@/assets/icons/close.svg';

export default {
  name: 'ProjectMeta',
  mixins: [parseDates, validationMixin],
  props: {
    project: Object,
    init: Boolean,
  },
  data() {
    return {
      editMode: this.init,
      userEdited: this.init,
      projectMeta: {},
    };
  },
  watch: {
    project() {
      this.initMeta();
    },
  },
  validations: {
    projectMeta: {
      title: { required },
      start: { required },
      duration: { required },
      application_deadline: {
        required,
        minValue: (value) => value > new Date().toISOString(),
      },
      availability: { required },
      contract: { required },
      country: { required },
      zipcode: { required, numeric },
    },
  },
  mounted() {
    this.initMeta();
  },
  methods: {
    initMeta() {
      this.projectMeta = {
        title: this.project.title,
        start: this.project.start,
        duration: this.project.duration,
        application_deadline: (this.project.application_deadline) ? this.project.application_deadline.split('T')[0] : '',
        availability: this.project?.availability,
        contract: this.project.contract,
        country: this.project.country,
        zipcode: this.project.zipcode,
      };
    },

    toggleEditMode() {
      if (!this.editMode) {
        this.editMode = true;
      } else if (this.userEdited) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.updateProjectMeta();
        }
      } else {
        this.editMode = false;
      }
    },

    cancelEdit() {
      if (this.userEdited) {
        this.initMeta();
      }

      this.editMode = false;
    },

    updateProjectMeta() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchProject(this.project.id, this.projectMeta, token)
            .then(() => {
              this.editMode = false;
              this.userEdited = false;
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    AnchorLink,
    Button,
    Icon,
    ContentBox,
    FormElement,
    InputText,
    InputSelect,
    CloseSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-meta.scss"></style>
