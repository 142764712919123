<template>
  <div class="match-list">
    <div class="match-list-wrapper">
      <div class="row match-list-title">
        <h3 class="h3">{{ matches.length }} {{ $t('matchList.fields.matches.label') }}</h3>
      </div>
      <div v-if="matches.length > 0" class="row match-list-head">
        <div class="col-7">
          <a class="label" @click="handleSort('manager.User.lastname')">
            {{ $t('matchList.fields.manager.label') }}
            <Icon v-if="selectedSort.value === 'manager.User.lastname'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
              <ChevronFullSVG></ChevronFullSVG>
            </Icon>
          </a>
        </div>

        <div class="col-4">
          <a class="label" @click="handleSort('status')">
            {{ $t('matchList.fields.status.label') }}
            <Icon v-if="selectedSort.value === 'status'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
              <ChevronFullSVG></ChevronFullSVG>
            </Icon>
          </a>
        </div>

        <div class="col-8">
          <a class="label" @click="handleSort('phase')">
            {{ $t('matchList.fields.phase.label') }}
            <Icon v-if="selectedSort.value === 'phase'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
              <ChevronFullSVG></ChevronFullSVG>
            </Icon>
          </a>
        </div>

        <div class="col-4">
          <a class="label" @click="handleSort('updatedAt')">
            {{ $t('matchList.fields.updatedAt.label') }}
            <Icon v-if="selectedSort.value === 'updatedAt'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
              <ChevronFullSVG></ChevronFullSVG>
            </Icon>
          </a>
        </div>
      </div>

      <div class="match-list-items">
        <div
          class="row match-item"
          v-for="match in matchesSorted"
          :key="match.id"
        >
          <div class="col-7">
            <span class="font-weight-semibold text-truncate">{{ match.manager.User.lastname }}, {{ match.manager.User.firstname }}</span>
          </div>

          <div class="col-4">
            <Status :statusKey="getAvailabilityKey(match.manager)"></Status>
          </div>

          <div class="col-8">
            <MatchPhaseSelect :id="match.id" :phase="match.phase" @update:phase="$emit('update')" />
          </div>

          <div class="col-4">
            <span class="font-weight-medium">{{ match.updatedAt | formatDate }}</span
            >&nbsp;
            <span class="font-weight-medium color-grey-400">({{ match.updatedAt | formatTime }})</span>
          </div>

          <div class="col-1 pos-relative text-right">
            <FlyoutNav class="mt-xs" :ref="`flyout${match.manager.username}`">
              <MatchListActions :id="match.id" :leadId="leadId" :managerUsername="match.manager.username" :managerUuid="match.manager.uuid" @update="$emit('update')" />
            </FlyoutNav>
            <FlyoutTrigger :flyoutEl="`flyout${match.manager.username}`" :parentRefs="$refs">
              <Icon class="-size-40 -outlined p-xxs-less -bg-white">
                <DotsSVG></DotsSVG>
              </Icon>
            </FlyoutTrigger>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import Icon from '@/components/atoms/Icon.vue';
import ChevronFullSVG from '@/assets/icons/chevron-full.svg';
import DotsSVG from '@/assets/icons/dots.svg';
import Status from '@/components/atoms/Status.vue';
import { parseDates } from '@/mixins';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import MatchPhaseSelect from './MatchPhaseSelect.vue';
import MatchListActions from './MatchListActions.vue';

export default {
  name: 'MatchList',
  mixins: [parseDates],
  props: {
    leadId: Number,
    matches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedSort: {
        value: 'manager.User.lastname',
        direction: 'asc',
      },
    };
  },
  computed: {
    matchesSorted() {
      return orderBy(this.matches, [this.selectedSort.value], [this.selectedSort.direction]);
    },
  },
  methods: {
    getAvailabilityKey(user) {
      if (user.admin_status) return user.admin_status;
      if (user.availability) return 'available';
      if (!user.availability_reason || user.availability_reason === 'none') return 'notAvailable';

      return user.availability_reason;
    },

    handleSort(value) {
      if (this.selectedSort.value !== value) {
        this.$set(this.selectedSort, 'direction', 'desc');
      } else {
        this.$set(this.selectedSort, 'direction', this.selectedSort.direction === 'asc' ? 'desc' : 'asc');
      }

      this.$set(this.selectedSort, 'value', value);
    },
  },
  components: {
    Icon,
    ChevronFullSVG,
    Status,
    FlyoutNav,
    FlyoutTrigger,
    DotsSVG,
    MatchPhaseSelect,
    MatchListActions,
  },
};
</script>
<style scoped lang="scss" src="@/sass/08_modules/match-list.scss"></style>
