<template>
  <section class="manager-profile-info">
    <ContentBox class="content-box">
      <div class="row">
        <div class="col-20">
          <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}" v-html="$t('managerProfileInfo.headlineWithSoftHyphens')"></h5>
        </div>
        <div class="col-4 text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32"
                  v-if="canEdit"
                  @click.native="$eventBus.$emit('open-modal-managerProfileInfo')">
            <Icon class="-inline -pos-absolute-center">
              <EditSVG></EditSVG>
            </Icon>
          </Button>
        </div>
      </div>
      <template v-if="userProfileInfo.jobTitle">
        <div class="sub-headline">{{ $t('managerProfileInfo.jobTitle') }}</div>
        <div class="font-size-base  font-weight-medium  mb-s  line-height-1">
          {{ userProfileInfo.jobTitle }}
        </div>
      </template>
      <div class="sub-headline">{{ $t('managerProfileInfo.lastUpdate') }}</div>
      <div class="font-size-base  font-weight-medium  mb-s  line-height-1">
        {{ updatedAt | formatDateTime(true) }}
      </div>
      <template v-if="role === 'admin'">
        <div class="sub-headline">{{ $t('managerProfileInfo.consent.title') }}</div>
        <div class="font-size-base font-weight-medium  mb-s  line-height-1">
          <CheckmarkText
            :checked="consentAgb"
            :text="$t('managerProfileInfo.consent.agbAccepted')"
            :textUnchecked="$t('managerProfileInfo.consent.agbNotAccepted')"
          />
          <CheckmarkText
            :checked="consentPrivacy"
            :text="$t('managerProfileInfo.consent.privacyAccepted')"
            :textUnchecked="$t('managerProfileInfo.consent.privacyNotAccepted')"
          />
        </div>
      </template>
      <div class="sub-headline">{{ $t('managerProfileInfo.headline') }}</div>
      <Button v-if="role === 'admin'"
              @click.native="downloadPdf('de')"
              class="-orange -outlined -compact  mb-xxs">
        {{ $t('managerProfileInfo.pdfExport') }} DE
      </Button>
      <Button v-if="role === 'admin'"
              @click.native="downloadPdf('en')"
              class="-orange -outlined -compact  mb-xxs">
        {{ $t('managerProfileInfo.pdfExport') }} EN
      </Button>
      <div class="sub-headline mt-xs">{{ $t('managerProfileInfo.moreLinks') }}</div>
      <Button class="display-inlineblock -orange -outlined -compact-square -cube -size-28 -small"
              @click.native="copyToClipboard">
        <Icon class="-size-16 -inline">
          <LinkSVG></LinkSVG>
        </Icon>
      </Button>
      <Button v-if="userProfileInfo.linkedin"
              class="display-inlineblock -orange -outlined -compact-square -cube -size-28 -small"
              :href="`${userProfileInfo.linkedin}/`"
              target="_blank">
        <Icon class="-size-16 -inline">
          <LinkedInSVG></LinkedInSVG>
        </Icon>
      </Button>
      <Button v-if="userProfileInfo.xing"
              class="display-inlineblock -orange -outlined -compact-square -cube -size-28 -small"
              :href="`${userProfileInfo.xing}`"
              target="_blank">
        <Icon class="-size-16 -inline">
          <XingSVG></XingSVG>
        </Icon>
      </Button>
      <Button v-if="userProfileInfo.website"
              class="display-inlineblock -orange -outlined -compact-square -cube -size-28 -small"
              :href="userProfileInfo.website"
              target="_blank">
        <Icon class="-size-16 -inline">
          <HomepageSVG></HomepageSVG>
        </Icon>
      </Button>
    </ContentBox>
    <Modal id="managerProfileInfo"
           :title="$t('managerProfileInfo.headline')"
           class="-thin"
           v-if="canEdit"
           @close="handleModalClose">
      <div class="row">
        <div class="col-24">
          <h6 class="h6 font-weight-medium">{{ $t('managerProfileInfo.subheadings.yourActivity') }}</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-24  mb-xs">
          <InputText alt=""
                     :class="{ '-has-error': $v.userProfileInfo.jobTitle.$error }"
                     type="text"
                     name="jobTitle"
                     base="managerProfileInfo"
                     @input="userEdited = true"
                     v-model="$v.userProfileInfo.jobTitle.$model"
                     />
        </div>
      </div>
      <div class="row mt-s">
        <div class="col-24">
          <h6 class="h6  font-weight-medium">{{ $t('managerProfileInfo.subheadings.otherProfile') }}</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-24  mb-xs">
          <InputText alt=""
                     :class="{ '-has-error': $v.userProfileInfo.linkedin.$error }"
                     type="text"
                     name="linkedin"
                     base="managerProfileInfo"
                     @input="userEdited = true"
                     @focusout.native="ensureProtocolPrefix"
                     v-model="$v.userProfileInfo.linkedin.$model"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-24  mb-xs">
          <InputText alt=""
                     :class="{ '-has-error': $v.userProfileInfo.xing.$error }"
                     type="text"
                     name="xing"
                     base="managerProfileInfo"
                     @input="userEdited = true"
                     @focusout.native="ensureProtocolPrefix"
                     v-model="$v.userProfileInfo.xing.$model"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-24  mb-xs">
          <InputText alt=""
                     :class="{ '-has-error': $v.userProfileInfo.website.$error }"
                     type="text"
                     name="website"
                     base="managerProfileInfo"
                     @input="userEdited = true"
                     @focusout.native="ensureProtocolPrefix"
                     v-model="$v.userProfileInfo.website.$model"
          />
        </div>
      </div>
      <template slot="footer">
        <div class="text-right">
          <Button class="button -orange -outlined mr-s" @click.native="$eventBus.$emit('close-modal-managerProfileInfo');">
            {{ $t('managerProfileInfo.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="handleModalSubmit">
            {{ $t('managerProfileInfo.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  url, helpers,
} from 'vuelidate/lib/validators';

import parseDates from '@/mixins/parseDates';
import userAssets from '@/mixins/userAssets';

import { updateUser } from '@/api/users.api';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import Modal from '@/components/elements/Modal.vue';

import EditSVG from '@/assets/icons/edit.svg';
import HomepageSVG from '@/assets/icons/homepage.svg';
import LinkSVG from '@/assets/icons/link.svg';
import LinkedInSVG from '@/assets/icons/linkedin.svg';
import XingSVG from '@/assets/icons/xing.svg';
import CheckmarkText from '@/components/atoms/CheckmarkText.vue';

const alpha = helpers.regex('alpha', /^[a-zA-Z-/&(). ]*$/);

export default {
  name: 'ManagerProfileInfo',
  mixins: [parseDates, userAssets, validationMixin],
  props: {
    uuid: String,
    username: String,
    updatedAt: String,
    linkedin: String,
    xing: String,
    jobTitle: String,
    website: String,
    consentAgb: Boolean,
    consentPrivacy: Boolean,
  },
  data() {
    return {
      userEdited: false,
      userProfileInfo: {},
      protocolSeparator: '://',
    };
  },
  computed: {
    ...mapState([
      'role',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    canEdit() {
      return this.userCan('edit', this.uuid) || this.userCan('updateManagerProfileInfo', this.uuid);
    },

    defaultProtocol() {
      return `http${this.protocolSeparator}`;
    },

    defaultProtocolSecure() {
      return `https${this.protocolSeparator}`;
    },
  },
  validations: {
    userProfileInfo: {
      jobTitle: {
        alpha,
      },
      linkedin: {
        url,
      },
      xing: {
        url,
      },
      website: {
        url,
      },
    },
  },
  mounted() {
    this.initProfile();
  },
  methods: {
    initProfile() {
      this.userProfileInfo = {
        linkedin: this.linkedin,
        xing: this.xing,
        website: this.website,
        jobTitle: this.jobTitle,
      };
    },

    copyToClipboard() {
      navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}${this.$route.fullPath}`);
      this.$eventBus.$emit('notificate', { title: this.$i18n.t('managerProfileInfo.copyToClipboard.title'), message: this.$i18n.t('managerProfileInfo.copyToClipboard.message') });
    },

    ensureProtocolPrefix(event) {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      let protocol = this.defaultProtocol;

      if (this.isSocialMediaInput(inputName)) {
        protocol = this.defaultProtocolSecure;
      }

      if (inputValue) {
        if (inputValue === this.defaultProtocol || inputValue === this.defaultProtocolSecure) {
          this.userProfileInfo[`${inputName}`] = '';
        } else if (inputValue.indexOf(this.protocolSeparator) < 0) {
          this.userProfileInfo[`${inputName}`] = `${protocol}${inputValue}`;
        }
      }
    },

    handleModalClose() {
      if (this.userEdited) {
        this.initProfile();
      }
    },

    handleModalSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => updateUser(this.uuid, this.userProfileInfo, token))
          .then(() => {
            this.userEdited = false;
            this.$emit('update');
            this.$eventBus.$emit('notificate');
            this.$eventBus.$emit('close-modal-managerProfileInfo');
            this.$store.dispatch('fetchUserData');
          })
          .catch((error) => this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status }));
      }
    },
    isSocialMediaInput(inputName) {
      return ['linkedin', 'xing'].includes(inputName);
    },
  },
  components: {
    Button,
    Icon,
    ContentBox,
    InputText,
    Modal,
    EditSVG,
    HomepageSVG,
    LinkSVG,
    LinkedInSVG,
    XingSVG,
    CheckmarkText,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-profile-info.scss"></style>
