<template>
  <section class="manager-categorization">
    <ContentBox>
      <div class="row mb-s">
        <div class="col-20">
          <h4 class="h4 mb-0 font-component-headline">{{ $t('managerCategorization.headline') }}</h4>
        </div>
        <div class="col-4  text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32" @click.native="$eventBus.$emit('open-modal-managerCategorization')">
            <Icon class="-inline  -pos-absolute-center">
              <EditSVG></EditSVG>
            </Icon>
          </Button>
        </div>
      </div>
      <ContentBox>
        <h6 class="h6">{{ $t(`managerCategorization.operationals.headline`) }}</h6>
        <div class="row">
          <div class="col-12  col-md-24"
               v-for="category in ['fn_management', 'fn_business', 'fn_it', 'fn_operations']"
               :key="category">
            <p class="text-mb-xs">{{ $t(`managerCategorization.categories.${category}.headline`) }}</p>
            <List v-if="categories[category].length > 0">
              <li v-for="key in categories[category]" :key="key">
                {{ $t(`managerCategorization.categories.${category}.inputs.${key}`) }}
              </li>
            </List>
            <template v-else>
              <p class="_empty-note">{{ $t('managerCategorization.notes.empty') }}</p>
            </template>
          </div>
        </div>
      </ContentBox>
      <ContentBox>
        <h6 class="h6">{{ $t(`managerCategorization.categories.industries.headline`) }}</h6>
        <div class="row">
          <div class="col-8  col-md-24" v-for="(group, index) in groupedSelectedIndustries" :key="index">
            <List v-if="categories.industries.length > 0">
              <li v-for="input in group" :key="input">
                {{ $t(`managerCategorization.categories.industries.inputs.${input}`) }}
              </li>
            </List>
          </div>
          <div class="col-24" v-if="categories.industries.length <= 0">
            <span class="_empty-note">{{ $t('managerCategorization.notes.empty') }}</span>
          </div>
        </div>
      </ContentBox>
      <div class="row">
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">{{ $t(`managerCategorization.categories.company_sizes.headline`) }}</h6>
            <List v-if="categories.company_sizes.length > 0" class="-inlineblock  mb-0">
              <li v-for="key in categories.company_sizes" :key="key">
                {{ $t(`managerCategorization.categories.company_sizes.inputs.${key}`) }}
              </li>
            </List>
            <template v-if="categories.company_sizes.length <= 0">
              <span class="_empty-note">{{ $t('managerCategorization.notes.empty') }}</span>
            </template>
          </ContentBox>
        </div>
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">{{ $t(`managerCategorization.categories.daily_price.headline`) }}</h6>
            <div class="text-mb-xs">
              {{ categories.daily_price_from | toCurrency }} {{ $t('managerCategorization.categories.daily_price.inputs.to.label') }} {{ categories.daily_price_to | toCurrency }}</div>
          </ContentBox>
        </div>
      </div>
      <div class="row">
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">
              {{ $t(`managerCategorization.categories.international_exp.headline`) }}
              <span class="_hnote">
                  {{ $t('managerCategorization.categories.international_exp.note') }}
                </span>
            </h6>
            <div class="_tag-wrapper">
              <Tag class="-slim  -blue"
                   v-for="exp in categories.international_exp"
                   :key="exp">
                <template v-if="$te(`countries.popular.${exp.toUpperCase()}`)">{{ $t(`countries.popular.${exp.toUpperCase()}`) }}</template>
                <template v-else-if="$te(`countries.default.${exp.toUpperCase()}`)">{{ $t(`countries.default.${exp.toUpperCase()}`) }}</template>
                <template v-else>{{ exp }}</template>
              </Tag>
            </div>
            <template v-if="categories.international_exp.length <= 0">
              <span class="_empty-note">{{ $t('managerCategorization.notes.empty') }}</span>
            </template>
          </ContentBox>
        </div>
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">{{ $t(`managerCategorization.categories.languages.headline`) }}
              <span class="_hnote">{{ $t('managerCategorization.categories.languages.note') }}</span>
            </h6>
            <div class="_tag-wrapper">
              <Tag class="-slim  -blue"
                   v-for="lang in categories.languages"
                   :key="lang">
                {{ ($te(`languages.popular.${lang}`)) ? $t(`languages.popular.${lang}`) : $t(`languages.default.${lang}`) }}
              </Tag>
            </div>
            <template v-if="categories.languages.length <= 0">
              <span class="_empty-note">{{ $t('managerCategorization.notes.empty') }}</span>
            </template>
          </ContentBox>
        </div>
      </div>
      <ContentBox class="mb-s">
        <h6 class="h6">{{ $t(`managerCategorization.categories.skills.headline`) }}</h6>
        <div class="row">
          <div class="col-24">
            <template v-if="allBuzzwords.length > 0">
              <div class="_tag-wrapper">
                <Tag class="-slim  -blue"
                     v-for="item in allBuzzwords"
                     :key="item">
                  {{ ($te(`managerCategorization.categories.skills.inputs.${item}`)) ? $t(`managerCategorization.categories.skills.inputs.${item}`) : item }}
                </Tag>
              </div>
            </template>
            <template v-if="allBuzzwords.length <= 0">
              <span class="_empty-note">{{ $t('managerCategorization.notes.empty') }}</span>
            </template>
          </div>
        </div>
      </ContentBox>
    </ContentBox>
    <ManagerCategorizationModal :categorization="categorization"
                                @update="saveAndExit">
    </ManagerCategorizationModal>
  </section>
</template>

<script>
import { saveCategorization } from '@/api/admins.api';
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import List from '@/components/atoms/List.vue';
import Tag from '@/components/atoms/Tag.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import ManagerCategorizationModal from '@/components/modules/User/admin/ManagerCategorizationModal.vue';

import EditSVG from '@/assets/icons/edit.svg';

import arrayUtils from '@/mixins/arrayUtils';

export default {
  name: 'ManagerCategorization',
  mixins: [arrayUtils, formatNumbers],
  props: {
    uuid: String,
    categorization: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userEdited: false,
      categories: {},
    };
  },
  computed: {
    groupedSelectedIndustries() {
      return this.getGrouped(Object.keys(this.$t('managerCategorization.categories.industries.inputs')), 'industries');
    },

    groupedAllIndustries() {
      return this.getGrouped(Object.keys(this.$t('managerCategorization.categories.industries.inputs')), 'industries', false);
    },

    groupedAllSkills() {
      return this.getGrouped(Object.keys(this.$t('managerCategorization.categories.skills.inputs')), 'skills', false);
    },

    allBuzzwords() {
      return [...this.categories.skills, ...this.categories.buzzwords];
    },
  },
  watch: {
    categorization(newValue) {
      this.initDefaults();
      this.initCategorization(newValue);
    },
  },
  created() {
    this.initDefaults();
  },
  mounted() {
    this.initCategorization(this.categorization);
  },
  methods: {
    initDefaults() {
      Object.keys(this.$t('managerCategorization.categories')).forEach((category) => {
        if (category === 'daily_price') {
          this.$set(this.categories, 'daily_price_from', 0);
          this.$set(this.categories, 'daily_price_to', 0);
        } else {
          this.$set(this.categories, category, []);
        }
      });
    },

    initCategorization(newValue) {
      Object.keys(newValue).forEach((key) => {
        if (newValue[key] !== null) {
          if (newValue[key].length) {
            this.$set(this.categories, key, [...this.categories[key], ...newValue[key]]);
          } else if (newValue[key] > 0) {
            this.$set(this.categories, key, newValue[key]);
          }
        }
      });
    },

    getGrouped(items, cat, filterSelected = true) {
      const filtered = (filterSelected) ? items.filter((el) => this.categories[cat].includes(el)) : items;
      return this.chunkArray(filtered, Math.ceil(filtered.length / 3));
    },

    handleRemoveSelected(target, category) {
      this.$set(this.categories, category, this.removeFromArray(this.categories[category], target));
      this.setEdited();
    },

    setEdited() {
      this.userEdited = true;
    },

    handleCloseModal() {
      if (this.userEdited) {
        this.initDefaults();
      }
    },

    saveAndExit(categories) {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          saveCategorization(this.uuid, categories, token)
            .then(() => {
              this.userEdited = false;
              this.$set(this, 'categories', categories);
              this.$emit('update');
              this.$eventBus.$emit('close-modal-managerCategorization');
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              console.error(error);
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.data.message });
            });
        });
    },
  },
  components: {
    Button,
    Icon,
    List,
    Tag,
    ContentBox,
    ManagerCategorizationModal,
    EditSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-categorization.scss"></style>
