<template>
  <div>
    <a v-if="lead.project_id" @click.prevent="showRelatedProject(lead.project_id)">{{ $t('leadActions.actions.showRelatedProject') }}</a>
    <a v-else @click.prevent="createProjectFromLead(lead)">{{ $t('leadActions.actions.createProjectFromLead') }}</a>
    <a @click.prevent="$emit('openEdit', lead.id)">{{ $t('leadActions.actions.edit') }}</a>
    <a @click.prevent="handleDelete">{{ $t('leadActions.actions.delete') }}</a>
  </div>
</template>

<script>
import { leadActions } from '@/mixins';

export default {
  name: 'LeadListActions',
  mixins: [leadActions],

  props: {
    lead: Object,
  },
};
</script>
