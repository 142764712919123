<template>
  <div>
    <a :disabled="isAlreadyExpertManager || !canSetGroup" @click="handleSuperAdminActions('setAsExpert')">{{ $t('superadmins.actions.setAsExpert') }}</a>
    <a :disabled="isAlreadyExecutiveManager || !canSetGroup" @click="handleSuperAdminActions('setAsExecutive')">{{ $t('superadmins.actions.setAsExecutive') }}</a>
    <a :disabled="isAlreadyExecutivePartnerPotential || !canSetGroup" @click="handleSuperAdminActions('setAsExecutivePartnerPotential')">{{ $t('superadmins.actions.setAsExecutivePartnerPotential') }}</a>
    <a :disabled="isAlreadyExecutivePartner || !canSetGroup" @click="handleSuperAdminActions('setAsPartner')">{{ $t('superadmins.actions.setAsPartner') }}</a>
    <a :disabled="!canCreateMatch" @click.prevent="$eventBus.$emit('open-modal-match', { managerUuid: uuid })">{{ $t('superadmins.actions.createMatch') }}</a>
    <a v-if="active" :disabled="!canDeactivateUser" @click="handleSuperAdminActions('setInactive')">{{ $t('superadmins.actions.setInactive') }}</a>
    <a v-if="!active" :disabled="!canDeactivateUser" @click="handleSuperAdminActions('setActive')">{{ $t('superadmins.actions.setActive') }}</a>
    <a @click.prevent="$eventBus.$emit('open-modal-comments', { manager: uuid, commentableType: 'Manager', commentableId: userId })">{{ $t('superadmins.actions.showComments') }}</a>
    <a :disabled="!canDeleteUser" @click="handleDelete">{{ $t('superadmins.actions.deleteUser') }}</a>
  </div>
</template>

<script>
import adminActions from '@/mixins/adminActions';

export default {
  name: 'ActionsNavAdmin',
  mixins: [adminActions],
  props: {
    uuid: String,
    username: String,
    userId: Number,
    group: String,
    role: String,
    partner_potential: Boolean,
    partner: Boolean,
    active: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      redirectTimer: 3,
    };
  },
  computed: {
    isAdminUser() {
      return this.role === 'admin';
    },
    canDeactivateUser() {
      return this.userCan('deactivateUser');
    },
    canDeleteUser() {
      return this.userCan('deleteUser');
    },
    canSetGroup() {
      return this.userCan('setGroup');
    },
    canCreateMatch() {
      return this.userCan('createMatch');
    },
    isAlreadyExpertManager() {
      return this.group === 'expert';
    },
    isAlreadyExecutiveManager() {
      return this.group === 'executive' && !this.partner && !this.partner_potential;
    },
    isAlreadyExecutivePartnerPotential() {
      return this.group === 'executive' && this.partner_potential && !this.partner;
    },
    isAlreadyExecutivePartner() {
      return this.group === 'executive' && this.partner;
    },
  },
};
</script>
