<template>
  <section class="manager-application-info">
    <ContentBox class="mb-xs">
      <h6 class="h6  font-weight-medium  mb-s" :class="{'font-size-l': $matchMedia.sm}">
        {{ $t('managerApplicationInfo.headline') }}
      </h6>
      <div class="sub-headline">{{ $t('managerApplicationInfo.status') }}</div>
      <p>
        {{ $t(`states.${status}.title`) }} <template v-if="$te(`states.${status}.note`)">{{ $t(`states.${status}.note`) }}</template>
      </p>
      <div class="sub-headline">{{ $t('managerApplicationInfo.createdAt') }}</div>
      <p>
        {{ createdAt | formatDate }}
      </p>
      <div class="sub-headline">{{ $t('managerApplicationInfo.assignedTo') }}</div>
      <p>
        {{ ($te(`groups.${assignee}`)) ? $t(`groups.${assignee}`) : assignee }}
      </p>
      <div class="sub-headline">{{ $t('managerApplicationInfo.lastUpdate') }}</div>
      <p>
        {{ updatedAt | formatDateTime(true) }}
      </p>
    </ContentBox>
  </section>
</template>

<script>
import parseDates from '@/mixins/parseDates';
import userAssets from '@/mixins/userAssets';

import ContentBox from '@/components/elements/ContentBox.vue';

export default {
  name: 'ManagerApplicationInfo',
  mixins: [parseDates, userAssets],
  props: {
    uuid: String,
    username: String,
    status: String,
    assignee: String,
    createdAt: String,
    updatedAt: String,
  },
  methods: {
  },
  components: {
    ContentBox,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-application-info.scss"></style>
