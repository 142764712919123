<template>
  <div class="project-applicant">
    <div class="applicant-header">
      <Tag v-show="application.status === 'unset'" class="m-0-i  -circle  -orange  -no-padding"></Tag>
      <div class="pos-relative  ml-xxs" v-if="editable">
        <FlyoutTrigger :flyoutEl="`flyout${application.id}`">
          <Icon><SwapSVG class="-z-index-1"></SwapSVG></Icon>
        </FlyoutTrigger>
        <FlyoutNav :ref="`flyout${application.id}`">
          <slot name="actionsNav"></slot>
        </FlyoutNav>
      </div>
    </div>
    <div :class="{'-declined': application.status === 'declined'}">
      <div class="date">
        <template v-if="message">
          {{ message[application.mailId].receivedDateTime | formatDate }}
        </template>
        <template v-else>
          {{ application.createdAt | formatDate }}
        </template>
      </div>
      <div class="applicant-name">
        {{ user.firstname }} {{ user.lastname }}
      </div>
      <div v-if="message" class="applicant-desc">
        {{ message[application.mailId].bodyPreview }}
      </div>
    </div>
  </div>
</template>

<script>
import parseDates from '@/mixins/parseDates';

import Icon from '@/components/atoms/Icon.vue';
import Tag from '@/components/atoms/Tag.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import SwapSVG from '@/assets/icons/swap.svg';

export default {
  name: 'ProjectApplicant',
  mixins: [parseDates],
  props: {
    message: Object,
    application: Object,
    text: String,
    user: Object,
    editable: Boolean,
  },
  components: {
    Icon,
    Tag,
    FlyoutTrigger,
    FlyoutNav,
    SwapSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-applicant.scss"></style>
