<template>
  <nav>
    <li>
      <router-link :to="{ name: 'Applications', params: { lang: this.$i18n.locale } }">
        <Icon class="-inline  pr-xs  pr-sm-0"><ApplicationSVG></ApplicationSVG></Icon>
        {{ $t('navigations.admin.applications') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Users', params: { lang: this.$i18n.locale } }">
        <Icon class="-inline  pr-xs  pr-sm-0"><ManagersSVG></ManagersSVG></Icon>
        {{ $t('navigations.admin.managers') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Projects', params: { lang: this.$i18n.locale } }">
        <Icon class="-inline  pr-xs  pr-sm-0"><ProjectsSVG></ProjectsSVG></Icon>
        {{ $t('navigations.admin.projects') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Leads', params: { lang: this.$i18n.locale } }">
        <Icon class="-inline  pr-xs  pr-sm-0"><LeadsSVG></LeadsSVG></Icon>
        {{ $t('navigations.admin.leads') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'ProjectApplications', params: { lang: this.$i18n.locale } }">
        <Icon class="-inline  pr-xs  pr-sm-0"><MailSVG></MailSVG></Icon>
        {{ $t('navigations.admin.projectApplications') }}
      </router-link>
    </li>
    <li v-if="userCan('manageAdmins')">
      <router-link :to="{ name: 'Admins', params: { lang: this.$i18n.locale } }">
        <Icon class="-inline  pr-xs  pr-sm-0"><ManageUsersSVG></ManageUsersSVG></Icon>
        {{ $t('navigations.admin.userManagement') }}
      </router-link>
    </li>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/atoms/Icon.vue';

import ApplicationSVG from '@/assets/icons/application.svg';
import ProjectsSVG from '@/assets/icons/file.svg';
import ManagersSVG from '@/assets/icons/managers.svg';
import MailSVG from '@/assets/icons/inbox.svg';
import LeadsSVG from '@/assets/icons/contacts.svg';

export default {
  name: 'AdminNav',
  props: {
    groups: Array,
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),
  },
  components: {
    Icon,
    ApplicationSVG,
    ProjectsSVG,
    ManagersSVG,
    MailSVG,
    LeadsSVG,
    ManageUsersSVG: () => import('@/assets/icons/manage-users.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/users-nav.scss"></style>
