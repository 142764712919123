<template>
  <div class="input-wrapper">

    <label :for="name" v-if="$te(`${base}.inputs.${name}.label`) && alt !== 'small'">
      {{ $t(`${base}.inputs.${name}.label`) }}
    </label>

    <div v-if="editor">

      <div v-if="!disabled" class="flex  pl-xxs">
        <Button class="-compact  color-blue-600"
          @click.native="editor.chain().focus().undo().run()"
          :class="{ 'is-active': editor.isActive('undo') }"
          title="Rückgängig (Strg+Z)"
        >
          <Icon class="-size-18"><UndoIcon /></Icon>
        </Button>

        <Button class="-compact  color-blue-600"
          @click.native="editor.chain().focus().redo().run()"
          :class="{ 'is-active': editor.isActive('redo') }"
          title="Wiederholen (Strg+Y)"
        >
          <Icon class="-size-18"><RedoIcon /></Icon>
        </Button>

        <template v-if="allowHeadlines">
          <Button class="-compact  color-blue-600  ml-xs"
                  @click.native="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
          >
            <Icon class="-size-14"><FormatFontSizeIcon /></Icon>
          </Button>

          <Button class="-compact  color-blue-600"
                  @click.native="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
          >
            <Icon class="-size-18"><FormatFontSizeIcon /></Icon>
          </Button>
        </template>

        <Button class="-compact  color-blue-600  ml-xs"
                @click.native="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          title="Fett (Strg+B)"
        >
          <Icon class="-size-18"><FormatBoldIcon /></Icon>
        </Button>

        <Button class="-compact  color-blue-600"
          @click.native="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          title="Kursiv (Strg+I)"
        >
          <Icon class="-size-18"><FormatItalicIcon /></Icon>
        </Button>

        <Button class="-compact  color-blue-600"
          @click.native="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          title="Auflistung"
        >
          <Icon class="-size-18"><FormatBulletListIcon /></Icon>
        </Button>
      </div>

      <editor-content style="width: 100%;" ref="editor" :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Icon from '@/components/atoms/Icon.vue';
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'InputRichText',
  props: {
    base: String,
    name: String,
    alt: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    allowHeadlines: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  beforeMount() {
    this.buildEditor();
  },

  beforeDestroy() {
    this.destroyEditor();
  },
  watch: {
    placeholder() {
      this.reBuildEditor();
    },
    disabled() {
      if (this.editor) {
        this.editor.setEditable(!this.disabled);
      }
    },
    value(value) {
      if (!this.editor) {
        this.buildEditor();
      }

      if (value === null || value === this.editor.getHTML()) {
        return;
      }

      this.editor.commands.setContent(value);
    },
  },
  methods: {
    getOptions() {
      return {
        content: this.value,
        extensions: [
          StarterKit.configure({
            ...(this.allowHeadlines) && {
              heading: {
                levels: [6, 5],
              },
            },
            blockquote: false,
            code: false,
            codeBlock: false,
            collaboration: false,
            dropcursor: false,
            horizontalRule: false,
            strike: false,
          }),
          Placeholder.configure({
            placeholder: this.placeholder,
          }),
        ],
        editorProps: {
          attributes: {
            class: 'rich-text',
          },
        },
        editable: !this.disabled,
        onUpdate: (event) => {
          const { editor } = event;
          const html = editor.getHTML();
          const input = (html === '<p></p>') ? '' : html;
          this.$emit('input', input);
          this.$eventBus.$emit('richTextInputUpdated', event);
        },
        onCreate: (event) => {
          this.$eventBus.$emit('richTextInputCreated', event);
        },
      };
    },

    buildEditor() {
      this.editor = new Editor(this.getOptions());
    },

    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
      }
    },

    reBuildEditor() {
      this.destroyEditor();
      this.buildEditor();
    },
  },
  components: {
    EditorContent,
    Icon,
    Button,
    FormatBoldIcon: () => import('@/assets/icons/editor/format-bold.svg'),
    FormatItalicIcon: () => import('@/assets/icons/editor/format-italic.svg'),
    FormatBulletListIcon: () => import('@/assets/icons/editor/format-bullet-list.svg'),
    FormatFontSizeIcon: () => import('@/assets/icons/editor/format-font-size.svg'),
    UndoIcon: () => import('@/assets/icons/editor/undo.svg'),
    RedoIcon: () => import('@/assets/icons/editor/redo.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-richtext.scss"></style>
<style lang="scss" src="@/sass/07_elements/rich-text.scss"></style>
