<template>
  <div class="input-wrapper">
    <label :for="name" v-if="$te(`${base}.inputs.${name}.label`) && alt !== 'small'">
      {{ $t(`${base}.inputs.${name}.label`) }}
    </label>
    <textarea :name="name"
              :placeholder="($te(`${base}.inputs.${name}.placeholder`)) ? $t(`${base}.inputs.${name}.placeholder`) : false"
              :value="value"
              :rows="rows"
              :readonly="disabled"
              v-on:input="updateValue($event.target.value)">
    </textarea>
  </div>
</template>

<script>
export default {
  name: 'InputTextarea',
  props: {
    base: String,
    name: String,
    alt: String,
    rows: {
      type: Number,
      default: 4,
    },
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-textarea.scss"></style>
