<template>
  <div>
    <router-link :to="{ name: 'UserDetail', params: { username: managerUsername } }">{{ $t('matchListActions.actions.managerProfile') }}</router-link>
    <a @click.prevent="openComments">{{ $t('matchListActions.actions.note') }}</a>
    <a @click.prevent="downloadPdf('de')">{{ $t('matchListActions.actions.exportCvDE') }}</a>
    <a @click.prevent="downloadPdf('en')">{{ $t('matchListActions.actions.exportCvEN') }}</a>
    <a @click.prevent="handleDelete()">{{ $t('matchListActions.actions.delete') }}</a>
  </div>
</template>

<script>
import { matchActions } from '@/mixins';

export default {
  name: 'MatchListActions',
  mixins: [matchActions],
  props: {
    id: Number,
    leadId: Number,
    managerUsername: String,
    managerUuid: String,
  },
  methods: {
    openComments() {
      this.$eventBus.$emit('open-modal-comments', {
        manager: this.managerUuid,
        commentableType: 'Lead',
        commentableId: this.leadId,
      });
    },
  },
};
</script>
