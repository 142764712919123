import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import zxcvbnDePackage from '@zxcvbn-ts/language-de';

export default {
  created() {
    this.pwScore = -1;
  },
  mounted() {
    ZxcvbnOptions.setOptions({
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
        ...zxcvbnDePackage.dictionary,
      },
      translations: (this.$i18n.locale === 'de') ? zxcvbnDePackage.translations : zxcvbnEnPackage.translations,
    });
  },
  methods: {
    minPasswordStrength(password) {
      if (!password || password.length <= 0) {
        this.pwScore = -1;
        return false;
      }

      if (password.length < 6) {
        this.pwScore = 0;
        return false;
      }

      const rating = zxcvbn(password);
      this.pwScore = rating.score;

      return rating.score > 2;
    },
  },
};
