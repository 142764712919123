<template>
  <section class="user-header">
    <div class="row" :class="{'text-center': $matchMedia.xs}">
      <div class="col-3  col-md-4  col-xs-10  mb-sm-xs  valign-middle">
        <Avatar class="-no-border">
          <img :src="userAvatar">
        </Avatar>
      </div>
      <div class="col-21  col-md-20  col-xs-24  pl-m-less  pl-xs-0  valign-middle" :class="{'text-center': $matchMedia.xs}">
        <div v-if="role === 'admin'" class="display-flex align-items-start">
          <Tag class="-slim" :class="tagColor">{{ $t(`groups.${userGroup}`) }}</Tag>
          <Tag v-if="user.partner_potential && !user.partner" class="-slim  -yellow">{{ $t(`groups.executivePartnerPotential`) }}</Tag>
          <StarRating :rating="user.averageRating" :increment="0.5" :show-rating="false" :star-size="16" :padding="4" :read-only="true" active-color="#F5AE2A" inactive-color="#7F8E9A" />
        </div>
        <h2 class="h2  mb-s">
          {{ user.firstname }} {{ user.lastname }}
        </h2>
        <div class="display-flex" :class="{'flex-column': $matchMedia.md}">
          <div v-if="user.birthday" class="_contact">
            <Icon class="-inline  -is-left">
              <AccountSVG></AccountSVG>
            </Icon>
            {{ calculateYears(user.birthday) }} {{ $t('userMeta.generalInfos.years.label')}}
          </div>
          <div v-if="user.phone || user.mobile" class="_contact">
            <Icon class="-inline  -is-left">
              <PhoneSVG></PhoneSVG>
            </Icon>
            {{ user.phone || user.mobile }}
          </div>
          <div class="_contact">
            <AnchorLink :href="`mailto:${user.email}`" class="-white">
              <Icon class="-inline  -is-left">
                <MailSVG></MailSVG>
              </Icon>
              {{ user.email }}
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StarRating from 'vue-star-rating';
import { mapState } from 'vuex';

import AnchorLink from '@/components/atoms/Link.vue';
import Avatar from '@/components/atoms/Avatar.vue';
import Icon from '@/components/atoms/Icon.vue';
import Tag from '@/components/atoms/Tag.vue';

import AccountSVG from '@/assets/icons/account.svg';
import PhoneSVG from '@/assets/icons/phone.svg';
import MailSVG from '@/assets/icons/mail.svg';
import parseDates from '@/mixins/parseDates';

export default {
  mixins: [parseDates],
  name: 'UserHeader',
  props: {
    user: Object,
    avatar: String,
  },
  computed: {
    ...mapState([
      'role',
    ]),

    tagColor() {
      return [{
        '-blue': this.userGroup === 'expert',
      }, {
        '-green': this.userGroup === 'executive',
      }, {
        '-grey': this.userGroup === 'pending',
      }, {
        '-yellow': this.userGroup === 'executivePartnerPotential',
      }, {
        '-orange': this.userGroup === 'partner',
      }];
    },

    userGroup() {
      if (this.user.partner) {
        return 'partner';
      }

      return this.user.group;
    },

    userAvatar() {
      // eslint-disable-next-line
      return this.avatar || require('@/assets/default-avatar.png');
    },
  },
  components: {
    AnchorLink,
    Avatar,
    Icon,
    StarRating,
    Tag,
    AccountSVG,
    PhoneSVG,
    MailSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-header.scss"></style>
