<template>
  <nav :class="['flyout-nav', { 'is-open': isOpen }]">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'FlyoutNav',
  props: {
    defaultOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.defaultOpen,
    };
  },
  mounted() {
    this.$eventBus.$on('flyout-closeall', this.close);

    this.$on('open', this.open);
    this.$on('close', this.close);
    this.$on('toggle', this.toggle);
  },
  methods: {
    open() {
      this.$eventBus.$emit('flyout-closeall');
      this.isOpen = true;
      this.$emit('flyout-change', this.isOpen);

      if (this.isOpen) {
        document.addEventListener('click', this.close, { once: true });
      }
    },

    close() {
      this.isOpen = false;
      this.$emit('flyout-change', this.isOpen);
    },

    toggle(e) {
      e.stopImmediatePropagation();
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/flyout-nav.scss"></style>
