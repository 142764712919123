<template>
  <div class="input-wrapper file-wrapper" :class="{ '-has-error': sizeError }">
    <div class="label" :for="name" v-if="$te(`${base}.inputs.${name}.label`)">
      {{ $t(`${base}.inputs.${name}.label`) }}
    </div>
    <label class="_input-file">
      <Icon class="-inline"><UploadSVG></UploadSVG></Icon>
      {{ $t('inputFile.button') }}
      <input type="file"
             :name="name"
             :accept="accept"
             v-on:input="updateValue($event.target)">
    </label>
    <span class="_selected">
    <Icon class="-inline"><FileSVG></FileSVG></Icon>
      {{ filename }}
    </span>
    <div class="error-msg">
      <slot name="errors">
        <template v-if="!sizeError">
          {{ $t(`${base}.inputs.${name}.error`) }}
        </template>
      </slot>
      <template v-if="sizeError">
        {{ $t(`${base}.inputs.${name}.sizeError`) }}
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';
import UploadSVG from '@/assets/icons/upload.svg';
import FileSVG from '@/assets/icons/file.svg';

export default {
  name: 'InputFile',
  props: {
    base: String,
    name: String,
    accept: String,
    value: File,
    maxFileSize: {
      type: Number,
      default: () => 5242880,
    },
  },
  data() {
    return {
      filename: this.getDefaultFilename(),
      sizeError: false,
    };
  },
  methods: {
    updateValue({ files }) {
      if (files.length > 0) {
        if (files[0].size <= this.maxFileSize) {
          this.sizeError = false;
          this.filename = files[0].name;
          this.$emit('input', files[0]);
        } else {
          this.sizeError = true;
          this.filename = this.getDefaultFilename();
          this.value = null;
        }
      }
    },
    getDefaultFilename() {
      return this.$t(`${this.base}.inputs.${this.name}.placeholder`) || this.$t('inputFile.default');
    },
  },
  watch: {
    value(value) {
      if (!value) {
        this.sizeError = false;
        this.filename = this.getDefaultFilename();
      }
    },
  },
  components: {
    Icon,
    UploadSVG,
    FileSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-file.scss"></style>
