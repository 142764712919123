<template>
  <div class="users-list">
    <Background class="-slim"></Background>
    <div class="row  mt-xs  mb-m  pos-relative">
      <div class="col-24">
        <h2 class="h2  mb-0  mt-ß  font-weight-normal  color-white">{{ $t('usersList.headline') }}</h2>
      </div>
    </div>
    <UserList role="manager" :defaultFilter="filter" :defaultSort="sort" :lastVisited="lastVisitedManager"></UserList>
  </div>
</template>

<script>
import Background from '@/components/atoms/Background.vue';
import UserList from '@/components/modules/User/UserList.vue';

export default {
  name: 'Users',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.savedPosition = window.event?.type === 'popstate';
    });
  },
  data() {
    return {
      filter: {},
      sort: {
        value: 'createdAt',
        direction: 'desc',
      },
      savedPosition: false,
      lastVisitedManager: '',
    };
  },
  mounted() {
    this.$eventBus.$on('visited-user-detail', (username) => { this.lastVisitedManager = username; });
  },
  components: {
    Background,
    UserList,
  },
};
</script>
