<template>
  <div class="avatar" :class="{ '-editable': editable }">
    <div class="_img">
      <slot></slot>
    </div>
    <div class="_overlay" v-if="editable">
      <Icon class="-size-32"><AccountEditSVG></AccountEditSVG></Icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    sizeError: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon: () => import('@/components/atoms/Icon.vue'),
    AccountEditSVG: () => import('@/assets/icons/account-edit.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/avatar.scss"></style>
