<template>
  <div class="status" :class="colorClass">
    <slot></slot>
    <template v-if="statusKey">
      {{ $t(`managerAvailabilityReasons.${statusKey}.label`) }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    statusKey: String,
  },
  data() {
    return {
      keys: {
        available: {
          color: '-light-green',
        },
        notAvailable: {
          color: '-red',
        },
        inProject: {
          color: '-green',
        },
        inEmployment: {
          color: '-orange',
        },
        blacklisted: {
          color: '-black',
        },
        retired: {
          color: '-yellow',
        },
        check: {
          color: '-turquese',
        },
      },
    };
  },
  computed: {
    colorClass() {
      return (this.statusKey) ? this.keys[this.statusKey]?.color : '';
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/status.scss"></style>
