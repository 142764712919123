<template>
  <div class="leads-list">
    <Background></Background>
    <div class="row mt-xs mb-m pos-relative">
      <div class="col-16 col-md-24">
        <h2 class="h2 mb-0 mt-0 color-white font-weight-normal" :class="{ 'font-size-xl  font-weight-medium': $matchMedia.xs }">{{ $t('leadList.headline') }}</h2>
      </div>
      <div class="col-8 col-md-24 valign-bottom mt-md-xs" :class="{ 'text-right': !$matchMedia.md }">
        <SubNav class="_menu">
          <Button class="-orange" @click.native="handleCreateLead">
            {{ $t('leadList.nav.create') }}
          </Button>
        </SubNav>
      </div>
    </div>
    <LeadList :lastVisited="lastVisitedLead"></LeadList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Background from '@/components/atoms/Background.vue';
import Button from '@/components/atoms/Button.vue';
import SubNav from '@/components/elements/SubNav.vue';
import LeadList from '@/components/modules/Lead/admin/LeadList.vue';

export default {
  name: 'AdminLeads',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.savedPosition = window.event?.type === 'popstate';
    });
  },
  data() {
    return {
      savedPosition: false,
      lastVisitedLead: 0,
    };
  },
  computed: {
    ...mapGetters(['loggedIn']),
  },
  mounted() {
    this.$eventBus.$on('visited-lead-detail', (id) => { this.lastVisitedLead = id; });
  },
  methods: {
    handleCreateLead() {
      this.$eventBus.$emit('open-modal-leadModalCreate', true);
    },
  },
  components: {
    Background,
    Button,
    SubNav,
    LeadList,
  },
};
</script>
