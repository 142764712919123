<template>
  <div class="projects-list">
    <Background></Background>
    <div class="row  mt-xs  mb-m  pos-relative">
      <div class="col-16  col-md-24">
        <h2 class="h2  mb-0  mt-0  color-white  font-weight-normal" :class="{'font-size-xl  font-weight-medium': $matchMedia.xs}">{{ $t('projectsList.headline') }}</h2>
      </div>
      <div class="col-8  col-md-24  valign-bottom  mt-md-xs" :class="{'text-right': !$matchMedia.md}">
        <SubNav class="_menu">
          <Button class="-orange" @click.native="handleCreateProject">
            {{ $t(isAdmin ? 'projectsList.nav.create_admin' : 'projectsList.nav.create') }}
          </Button>
        </SubNav>
      </div>
    </div>
    <ProjectList></ProjectList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createProject } from '@/api/projects.api';

import Background from '@/components/atoms/Background.vue';
import Button from '@/components/atoms/Button.vue';
import SubNav from '@/components/elements/SubNav.vue';
import ProjectList from '@/components/modules/Project/List.vue';

export default {
  name: 'AdminProjects',
  computed: {
    ...mapGetters([
      'loggedIn',
      'isAdmin',
    ]),
  },
  methods: {
    handleCreateProject() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          createProject({}, token)
            .then((result) => result.data)
            .then(({ data }) => {
              this.$router.push({ name: 'ProjectDetail', params: { id: data.id, init: true, lang: this.$i18n.locale } });
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    Background,
    Button,
    SubNav,
    ProjectList,
  },
};
</script>
