<template>
  <section class="user-availability">
    <ContentBox>
      <div class="row">
        <div class="col-20">
          <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}" v-html="$t('userAvailability.headlineWithSoftHyphens')"></h5>
        </div>
        <div class="col-4  text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32"
                  v-if="canEdit"
                  @click.native="$eventBus.$emit('open-modal-userAvailabilityEdit')">
            <Icon class="-inline  -pos-absolute-center">
              <EditSVG />
            </Icon>
          </Button>
        </div>
      </div>
      <div v-if="showAvailabilityConfirmed" class="font-size-base  font-weight-medium  line-height-1 font-color-confirmed">
        <Icon class="-inline -is-left"><ConfirmedSVG /></Icon>
        <span>
          {{ $t('userAvailability.headlines.sidebar.confirmed') }}
        </span>
      </div>
      <div v-else-if="showAvailabilityUnConfirmed" class="font-size-base  font-weight-medium  line-height-1 font-color-unconfirmed">
        <Icon class="-inline -is-left"><UnConfirmedSVG /></Icon>
        <span>
          {{ $t('userAvailability.headlines.sidebar.unconfirmed') }}
        </span>
      </div>
      <div v-else-if="showUnavailableUntil" class="font-size-base  font-weight-medium  line-height-1 font-color-unavailable">
        <Icon class="-inline -is-left"><UnavailableSVG /></Icon>
        <span>
          {{ $t('userAvailability.headlines.sidebar.unavailable') }}
        </span>
      </div>
      <div v-if="(showAvailabilityConfirmed || showAvailabilityUnConfirmed) && this.availabilityData.perWeek" class="font-size-base  font-weight-medium mt-s line-height-1">
        <Icon class="-inline -is-left"><CalendarSVG /></Icon>
        <span v-if="parseInt(this.availabilityData.perWeek, 10) < 5">
          {{ $t('userAvailability.headlines.sidebar.part-time') }}
          ({{ $t(`userAvailability.inputs.perWeek.choices.` + this.availabilityData.perWeek) }})
        </span>
        <span v-if="parseInt(this.availabilityData.perWeek, 10) === 5">
          {{ $t('userAvailability.headlines.sidebar.full-time') }}
        </span>
      </div>
      <div v-if="showUnavailableReason" class="font-size-base font-weight-medium mt-s line-height-1">
        {{ $t(`userAvailability.headlines.sidebar.${this.availabilityData.reason}`) }}
      </div>
      <div v-if="showUnavailableUntil && germanDateFormat.length" class="font-size-base  font-weight-medium mt-s line-height-1">
        <Icon class="-inline -is-left"><CalendarSVG /></Icon> {{ $t('userAvailability.headlines.sidebar.until') }} {{ germanDateFormat }}
      </div>
    </ContentBox>
    <Modal id="userAvailabilityEdit"
           :title="$t('userAvailability.headline')"
           v-if="canEdit"
           class="-thin"
           @close="handleModalClose"
    >
      <FormElement>
        <div class="row text-mb-s">
          <div class="col-24 mb-xxs font-size-xl font-weight-medium">
            {{ $t('userAvailability.headlines.modal.availability') }}
          </div>
          <div class="col-24">
            <div class="radio-group">
              <InputRadio v-for="(choice, key) in $t('userAvailability.inputs.available.choices')"
                          :key="`availability-${key}`"
                          :choice="`${key}`"
                          name="availability"
                          base="userAvailability"
                          @input="dataChanged()"
                          @change="dataChanged()"
                          class="mr-s -small"
                          v-model="$v.availabilityData.available.$model"
                          :class="{ '-has-error': $v.availabilityData.available.$error }"
              >
                <template slot="label">{{ choice }}</template>
              </InputRadio>
            </div>
          </div>
        </div>
        <div class="row text-mb-s" v-if="availabilityData.available === 'true'">
          <div class="col-24 mb-xxs font-size-xl font-weight-medium">
            {{ $t('userAvailability.headlines.modal.perWeek') }}
          </div>
          <div class="col-24">
            <div class="radio-group">
              <InputSelect name="perWeek"
                           base="userAvailability"
                           @input="dataChanged()"
                           v-model="$v.availabilityData.perWeek.$model"
                           :class="{ '-has-error': $v.availabilityData.perWeek.$error }"
              >
                <option selected :value="null">
                  {{ $t('userAvailability.inputs.perWeek.placeholder') }}
                </option>
                <template v-slot:options>
                  <option v-for="(choice, key) in $t('userAvailability.inputs.perWeek.choices')" :key="key" :value="key">{{ choice }}</option>
                </template>
              </InputSelect>
            </div>
          </div>
        </div>
        <div class="row text-mb-s" v-if="availabilityData.available === 'false'">
          <div class="col-24 mb-xxs font-size-xl font-weight-medium">
            {{ $t('userAvailability.headlines.modal.reason') }}
          </div>
          <div class="col-24">
            <InputSelect name="reason"
                         base="userAvailability"
                         class="display-inlineblock"
                         @input="dataChanged()"
                         v-model="$v.availabilityData.reason.$model"
                         :class="{ '-has-error': $v.availabilityData.reason.$error }"
            >
              <option selected disabled :value="null">
                {{ $t('userAvailability.inputs.reason.placeholder') }}
              </option>
              <template v-slot:options>
                <option v-for="(choice, key) in $t('userAvailability.inputs.reason.choices')" :key="key" :value="key">{{ choice }}</option>
              </template>
            </InputSelect>
          </div>
        </div>
        <div class="row text-mb-s" v-if="availabilityData.available === 'false' && ['inProject', 'none'].includes(availabilityData.reason)">
          <div class="col-24 mb-xxs font-size-xl font-weight-medium">
            {{ $t('userAvailability.headlines.modal.backIn') }}
          </div>
          <div class="col-24">
            <div class="radio-group input-wrapper">
              <p class="label">
                {{ $t('userAvailability.inputs.backIn.label') }}
              </p>
              <InputRadio v-for="(choice, key) in $t('userAvailability.inputs.backIn.choices')"
                          :key="`backIn-${key}`"
                          :choice="`${key}`"
                          name="backIn"
                          base="userAvailability"
                          @input="dataChanged(); setOtherDate()"
                          class="mr-s -small"
                          v-model="availabilityData.backIn"
              >
                <template slot="label">{{ choice }}</template>
              </InputRadio>
            </div>
          </div>
        </div>
        <div class="row" v-if="availabilityData.available === 'false' && ['inProject', 'none'].includes(availabilityData.reason)">
          <div class="col-8 col-sm-24">
            <InputText type="date"
                       name="otherDate"
                       base="userAvailability"
                       :min="tomorrow"
                       @input="dataChanged(); clearBackIn()"
                       v-model="$v.availabilityData.otherDate.$model"
                       :class="{ '-has-error': $v.availabilityData.otherDate.$error }"
            />
          </div>
        </div>
      </FormElement>
      <template slot="footer">
        <div class="text-right">
          <Button class="-orange -outlined mr-xs" @click.native="$eventBus.$emit('close-modal-userAvailabilityEdit')">
            {{ $t('userAvailability.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="saveAndExit">
            {{ $t('userAvailability.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, requiredIf, helpers,
} from 'vuelidate/lib/validators';
import { updateAvailability } from '@/api/users.api';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Modal from '@/components/elements/Modal.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputRadio from '@/components/elements/inputs/InputRadio.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import FormElement from '@/components/elements/FormElement.vue';

import EditSVG from '@/assets/icons/edit.svg';
import CalendarSVG from '@/assets/icons/calendar.svg';
import ConfirmedSVG from '@/assets/icons/check_circle_outline_black_24dp.svg';
import UnConfirmedSVG from '@/assets/icons/panorama_fish_eye_black_24dp.svg';
import UnavailableSVG from '@/assets/icons/block_black_24dp.svg';

const truthy = ['1', 'true', true, 1, 'yes', null];
const otherDateRegex = /^(20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const otherDateVuelidateRule = helpers.regex('otherDate', otherDateRegex);

export default {
  name: 'UserAvailability',
  mixins: [validationMixin],
  props: {
    user: Object,
  },
  watch: {
    user(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.initData();
      }
    },
  },
  data() {
    return {
      dataWasChanged: false,
      availabilityData: {},
      availabilityDataInitial: {},
    };
  },
  computed: {
    ...mapState([
      'uuid',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    tomorrow() {
      const tomorrow = new Date((new Date()).setDate((new Date()).getDate() + 1));

      return tomorrow.toISOString().split('T')[0];
    },

    lastLoginLessThanSixMonthsAgo() {
      if (!this.user.loggedInAt) {
        return false;
      }

      const xDaysAgo = 14;
      const nowXDaysAgo = (new Date()).setDate((new Date()).getDate() - xDaysAgo);
      const latestLogin = new Date(Date.parse(this.user.loggedInAt));

      return latestLogin > nowXDaysAgo;
    },

    available() {
      const otherDate = new Date(Date.parse(this.availabilityData.otherDate));

      return truthy.includes(this.availabilityData.available) || (otherDate < (new Date()).setUTCHours(0, 0, 0, 0));
    },

    showAvailabilityConfirmed() {
      return this.available && this.lastLoginLessThanSixMonthsAgo;
    },

    showAvailabilityUnConfirmed() {
      return this.available && !this.lastLoginLessThanSixMonthsAgo;
    },

    showUnavailableUntil() {
      return !this.available;
    },

    showUnavailableReason() {
      return !this.available && ['inProject', 'inEmployment', 'retired'].includes(this.availabilityData.reason);
    },

    germanDateFormat() {
      if (!otherDateRegex.test(this.availabilityData.otherDate)) {
        return '';
      }

      try {
        return (new Date(Date.parse(this.availabilityData.otherDate)))
          .toLocaleDateString(
            'de-DE',
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },
          );
      } catch (e) {
        return '';
      }
    },

    canEdit() {
      return this.userCan('edit', this.user.uuid) || this.userCan('updateUserAvailability');
    },
  },
  validations: {
    availabilityData: {
      available: {
        required,
      },
      perWeek: {
        required: requiredIf((vm) => vm.available === 'true'),
      },
      reason: {
        required: requiredIf((vm) => vm.available === 'false'),
      },
      otherDate: {
        required: requiredIf((vm) => vm.available === 'false' && ['inProject', 'none'].includes(vm.reason)),
        otherDateVuelidateRule,
      },
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.availabilityData = {
        available: truthy.includes(this.user.availability) ? 'true' : 'false',
        reason: this.user.availability_reason,
        perWeek: this.user.availability_per_week,
        otherDate: this.user.availability_other_date?.split('T')[0],
      };

      this.dataWasChanged = false;

      this.availabilityData.available = this.available.toString();

      Object.assign(this.availabilityDataInitial, this.availabilityData);
    },

    setInitToCurrent() {
      this.availabilityDataInitial = this.availabilityData;
    },

    dataChanged() {
      this.dataWasChanged = true;
      this.$v.$touch();
    },

    clearBackIn() {
      this.availabilityData.backIn = null;
    },

    setOtherDate() {
      const firstOfThisMonth = new Date((new Date()).setDate(1));
      const newDate = firstOfThisMonth.setMonth(firstOfThisMonth.getMonth() + parseInt(this.availabilityData.backIn, 10));

      // eslint-disable-next-line prefer-destructuring
      this.availabilityData.otherDate = (new Date(newDate)).toISOString().split('T')[0];
    },

    handleModalClose() {
      if (this.dataWasChanged) {
        this.initData();
      }
    },

    saveAndExit() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            updateAvailability(this.user.uuid, truthy.includes(this.availabilityData.available), this.availabilityData.perWeek, this.availabilityData.reason, this.availabilityData.otherDate, token)
              .then(() => {
                this.$emit('update');
                this.$eventBus.$emit('notificate');
                this.dataWasChanged = false;
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              })
              .finally(() => {
                this.setInitToCurrent();
                this.$eventBus.$emit('close-modal-userAvailabilityEdit');
              });
          });
      }
    },
  },
  components: {
    InputText,
    Button,
    Icon,
    ContentBox,
    FormElement,
    Modal,
    InputSelect,
    InputRadio,
    EditSVG,
    CalendarSVG,
    ConfirmedSVG,
    UnConfirmedSVG,
    UnavailableSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-availability.scss"></style>
