<template>
  <div class="comment-list">
    <ContentBox v-for="(comment, index) in comments" :key="comment.id" class="comment -slim mb-xs" :ref="(comments.length - 1) === index ? 'lastComment': null">
      <div class="display-flex justify-content-start mb-xs">
        <strong class="block mr-xs">
          <template v-if="comment.Admin">
            {{ comment.Admin.User.firstname }} {{ comment.Admin.User.lastname }}
          </template>
          <template v-else>
            {{ $t('comments.deleted_user') }}
          </template>
        </strong>
        <span class="color-blue-400">{{ comment.createdAt | formatDate }}</span>
        <StarRating v-if="comment.rating > 0" class="ml-xs" :rating="comment.rating" :show-rating="false" :star-size="16" :padding="4" active-color="#F5AE2A" inactive-color="#E5E8EA" :read-only="true" />
        <Tag class="-slim -grey -no-margin-bottom" style="margin-left: auto">
          {{ $t(`commentableTypes.${comment.commentableType}`) }}
        </Tag>
        <slot :comment="comment"></slot>
      </div>
      <div class="ProseMirror rich-text" v-html="comment.content"></div>
    </ContentBox>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

import ContentBox from '@/components/elements/ContentBox.vue';
import Tag from '@/components/atoms/Tag.vue';

import { parseDates, formatStrings } from '@/mixins';

export default {
  name: 'CommentList',
  mixins: [parseDates, formatStrings],
  props: {
    comments: Array,
  },
  components: {
    ContentBox,
    StarRating,
    Tag,
  },
  methods: {
    scrollToLastComment() {
      this.$nextTick(() => {
        if (!this.$refs.lastComment) {
          return;
        }

        this.$refs.lastComment[0].$el.scrollIntoView({ behavior: 'smooth' });
      });
    },
  },
};
</script>
