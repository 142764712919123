<template>
  <section class="project-details">
    <ContentBox>
      <FormElement>
        <div class="row  mb-m">
          <div class="col-12  col-md-24">
            <h6 class="h6">{{ $t('projectDetails.daily_price.headline') }}</h6>
            <InputCurrency v-for="(input, key) in $t('projectDetails.daily_price.inputs')"
                           :key="key"
                           :class="['-inline',  'mr-xs', { '-has-error': $v.projectDetails[`daily_price_${key}`].$error }]"
                           alt="small"
                           type="number"
                           base="projectDetails.daily_price"
                           :name="key"
                           :disabled="!editMode"
                           @input="userEdited = true"
                           v-model="$v.projectDetails[`daily_price_${key}`].$model">
              <template slot="label">{{ input.label }}</template>
            </InputCurrency>
            <InputCheckbox name="daily_price_show"
                           class="display-inlineblock  mt-xs-xs  mb-xs-s"
                           :disabled="!editMode"
                           @input="userEdited = true"
                           v-model="$v.projectDetails.daily_price_show.$model">
              <template slot="label">
                {{ $t('projectDetails.daily_price.show.label') }}
              </template>
            </InputCheckbox>
          </div>
          <div class="col-12  col-md-24">
            <h6 class="h6">{{ $t('projectDetails.experience.headline') }}</h6>
            <InputSelect alt="small"
                         :class="['display-inlineblock', { '-has-error': $v.projectDetails.experience.$error }]"
                         name="experience"
                         :disabled="!editMode"
                         :options="$t('categories.experiences')"
                         @input="userEdited = true"
                         v-model="$v.projectDetails.experience.$model">
              <option disabled selected value="">{{ $t('projectDetails.experience.inputs.placeholder') }}</option>
            </InputSelect>
          </div>
        </div>
        <div class="row  mb-m">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectDetails.operationals.headline') }}</h6>
          </div>
          <div class="col-8  col-md-24" v-for="index in [0, 1, 2]" :key="index">
            <table :class="{ '-inactive': projectDetails.main_operationals.length < index }">
              <tr v-if="editMode || projectDetails.main_operationals[index]">
                <td>{{ $t('projectDetails.operationals.inputs.main.label') }}:</td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectDetails.main_operationals.$error }"
                               base="projectDetails.operationals"
                               name="main"
                               :disabled="!editMode"
                               @input="userEdited = true; handleOperationalSelect($event)"
                               v-model="projectDetails.main_operationals[index]">
                    <option selected value="">{{ $t('projectDetails.operationals.inputs.main.placeholder') }}</option>
                    <template name="options">
                      <option v-for="(value, key) in $t('categories.operationals.main')"
                              :key="key"
                              :disabled="projectDetails.main_operationals.includes(key)"
                              :value="key">{{ value }}</option>
                    </template>
                  </InputSelect>
                </td>
              </tr>
              <tr v-if="editMode || projectDetails.sub_operationals[index]" :class="{ '-inactive': !projectDetails.main_operationals[index] }">
                <td>{{ $t('projectDetails.operationals.inputs.sub.label') }}:</td>
                <td>
                  <InputSelect alt="small"
                               :class="{ '-has-error': $v.projectDetails.sub_operationals.$error }"
                               base="projectDetails.operationals"
                               name="sub"
                               :disabled="!editMode"
                               @input="userEdited = true"
                               v-model="projectDetails.sub_operationals[index]">
                    <option selected value="">{{ $t('projectDetails.operationals.inputs.sub.placeholder') }}</option>
                    <template name="options" v-if="projectDetails.main_operationals[index]">
                      <option v-for="(value, key) in $t(`categories.operationals.sub.${projectDetails.main_operationals[index]}`)"
                              :value="`${projectDetails.main_operationals[index]}_${key}`"
                              :key="key">{{ value }}</option>
                    </template>
                  </InputSelect>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row  mb-m">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectDetails.industry.headline') }}</h6>
            <table>
              <tr>
                <td>{{ $t('projectDetails.industry.inputs.main.label') }}:</td>
                <td>
                  <InputSelect alt="small"
                               :class="['display-inlineblock', { '-has-error': $v.projectDetails.main_industry.$error }]"
                               base="projectDetails.industry"
                               name="main"
                               :disabled="!editMode"
                               :options="$t('categories.industries.main')"
                               @input="userEdited = true"
                               v-model="$v.projectDetails.main_industry.$model">
                    <option disabled selected value="">{{ $t('projectDetails.industry.inputs.main.placeholder') }}</option>
                  </InputSelect>
                </td>
              </tr>
              <tr v-if="(editMode && projectDetails.main_industry && subIndustryOptions) || projectDetails.sub_industry">
                <td>{{ $t('projectDetails.industry.inputs.sub.label') }}:</td>
                <td>
                  <InputSelect alt="small"
                               :class="['display-inlineblock', { '-has-error': $v.projectDetails.sub_industry.$error }]"
                               base="projectDetails.industry"
                               name="sub"
                               :disabled="!editMode"
                               :options="subIndustryOptions"
                               @input="userEdited = true"
                               v-model="$v.projectDetails.sub_industry.$model">
                    <option selected value="">{{ $t('projectDetails.industry.inputs.sub.placeholder') }}</option>
                  </InputSelect>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </FormElement>
      <div class="_footer">
        <hr class="-grey-200  mb-s">
        <div class="row">
          <div class="col-24  text-right">
            <Button v-if="editMode" class="-orange  -thin" @click.native="toggleEditMode">{{ $t('projectContent.buttons.save') }}</Button>
            <Button v-if="!editMode" class="-grey  -thin" @click.native="toggleEditMode">{{ $t('projectContent.buttons.edit') }}</Button>
            <Button class="-grey  -thin  ml-xs" @click.native="cancelEdit" v-if="editMode && !init">
              <Icon class="-size-20"><CloseSVG></CloseSVG></Icon>
            </Button>
          </div>
        </div>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { patchProject } from '@/api/projects.api';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputCurrency from '@/components/elements/inputs/InputCurrency.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';

import CloseSVG from '@/assets/icons/close.svg';

const greaterThanRef = (value, vm) => !vm.daily_price_show || value > vm.daily_price_from;

export default {
  name: 'ProjectDetails',
  mixins: [validationMixin],
  props: {
    project: Object,
    init: Boolean,
  },
  data() {
    return {
      editMode: this.init,
      userEdited: this.init,
      projectDetails: {
        main_operationals: [],
        sub_operationals: [],
      },
    };
  },
  computed: {
    subIndustryOptions() {
      if (this.projectDetails.main_industry) {
        const options = this.$t(`categories.industries.sub.${this.projectDetails.main_industry}`);

        return (Object.keys(options).length > 0) ? options : false;
      }

      return false;
    },
  },
  watch: {
    project() {
      this.initDetails();
    },

    // eslint-disable-next-line
    'projectDetails.main_industry': function (newVal, oldVal) {
      if (oldVal && (newVal !== oldVal)) {
        this.$set(this.projectDetails, 'sub_industry', null);
      }
    },
  },
  validations: {
    projectDetails: {
      experience: { required },
      daily_price_from: {
        minValue: (value, vm) => !vm.daily_price_show || value > 0,
      },
      daily_price_to: {
        greaterThanRef,
      },
      daily_price_show: {},
      main_operationals: { required },
      sub_operationals: {},
      main_industry: { required },
      sub_industry: {},
    },
  },
  mounted() {
    this.initDetails();
  },
  methods: {
    initDetails() {
      this.projectDetails = {
        experience: this.project.experience,
        daily_price_from: this.project.daily_price_from,
        daily_price_to: this.project.daily_price_to,
        daily_price_show: this.project.daily_price_show,
        main_operationals: [...(this.project.main_operationals || [])],
        sub_operationals: [...(this.project.sub_operationals || [])],
        main_industry: this.project.main_industry,
        sub_industry: this.project.sub_industry,
      };
    },

    toggleEditMode() {
      if (!this.editMode) {
        this.editMode = true;
      } else if (this.userEdited) {
        this.cleanUpOperationals();
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.updateProjectDetails();
        }
      } else {
        this.editMode = false;
      }
    },

    cancelEdit() {
      if (this.userEdited) {
        this.initDetails();
      }

      this.editMode = false;
    },

    cleanUpOperationals() {
      this.projectDetails.main_operationals.forEach((el, index) => {
        if (el === '') {
          this.projectDetails.main_operationals.splice(index, 1);
          this.projectDetails.sub_operationals.splice(index, 1);
        }
      });
    },

    handleOperationalSelect(value) {
      const index = this.projectDetails.main_operationals.indexOf(value);

      if (index >= 0) {
        this.$set(this.projectDetails.sub_operationals, index, '');
      }
    },

    updateProjectDetails() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchProject(this.project.id, this.projectDetails, token)
            .then(() => {
              this.editMode = false;
              this.userEdited = false;
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    Button,
    Icon,
    ContentBox,
    FormElement,
    InputCheckbox,
    InputCurrency,
    InputSelect,
    CloseSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-details.scss"></style>
