<template>
  <nav class="sub-nav">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'SubNav',
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/sub-nav.scss"></style>
