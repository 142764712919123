<template>
  <div class="input-toggle-switch">
    <div class="input-wrapper">
      <label :for="`${idPrefix}${base}-${name}`">
        <input type="checkbox"
              :name="name"
              :id="`${idPrefix}${base}-${name}`"
              v-on:input="updateValue($event.target.checked)"
              :disabled="disabled"
              :checked="value">
        <span class="_slider -round"></span>
      </label>
    </div>
    <span class="_label">
      <slot name="label">
        <template v-if="$te(`${base}.inputs.${name}.label`)">
          {{ $t(`${base}.inputs.${name}.label.${value}`) }}
        </template>
      </slot>
    </span>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'InputToggleSwitch',
  props: {
    base: String,
    name: String,
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    idPrefix() {
      return this.id ? `${this.id}-` : _.uniqueId();
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-toggle-switch.scss"></style>
