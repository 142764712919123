import { render, staticRenderFns } from "./AccordionContent.vue?vue&type=template&id=67daee54&scoped=true&"
import script from "./AccordionContent.vue?vue&type=script&lang=js&"
export * from "./AccordionContent.vue?vue&type=script&lang=js&"
import style0 from "@/sass/07_elements/accordion-content.scss?vue&type=style&index=0&id=67daee54&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67daee54",
  null
  
)

export default component.exports