<template>
  <section class="manager-evaluation">
    <ContentBox>
      <h4 class="h4">{{ $t('managerEvaluation.headline') }}</h4>
      <template v-if="user.status === 'checkup' && checkupPermission">
        <ContentBox>
          <p class="mb-s">{{ $t('managerEvaluation.checkup.approveTitle') }}</p>
          <Button class="-orange" @click.native="approveCheckup">{{ $t('managerEvaluation.checkup.button') }}</Button>
        </ContentBox>
      </template>
      <template v-else-if="user.status === 'checkup' && !checkupPermission">
        <ContentBox>
          <div class="row">
            <div class="col-16  col-offset-4  col-md-24  col-md-offset-0  text-center">
              <p class="_note">{{ $t('managerEvaluation.checkup.waitTitle') }}</p>
            </div>
          </div>
        </ContentBox>
      </template>
      <template v-else-if="user.status === 'selection' && shouldVote">
        <ContentBox>
          <div class="row">
            <div class="col-16  col-md-24">
              <p>{{ $t('managerEvaluation.evaluate.title') }}</p>
              <List class="_criteria">
                <li v-for="item in $t('managerEvaluation.evaluate.list')" :key="item">
                  {{ item }}
                </li>
              </List>
            </div>
          </div>
          <div class="row">
            <div class="col-14  col-md-24  mb-md-s">
              <div class="sub-headline">
                {{ $t('managerEvaluation.evaluate.submitLabel') }}
              </div>
              <InputSelect class="display-inlineblock  mr-xxs" v-model="selectedVote">
                <option value="" disabled>{{ $t('managerEvaluation.evaluate.input.placeholder') }}</option>
                <template v-slot:options>
                  <option v-for="(option, key) in $t('managerEvaluation.evaluate.input.options')" :key="key" :value="key">{{ option }}</option>
                </template>
              </InputSelect>
              <Button class="-orange mr-xxs" @click.native="voteSelection">{{ $t('managerEvaluation.evaluate.submit') }}</Button>
              <Button v-if="!this.hasVoted" class="-orange -outlined" @click.native="$eventBus.$emit('open-modal-comments', { manager: user.uuid, commentableType: 'ApplicationDetail', commentableId: user.id })">
                {{ $t('managerEvaluation.evaluate.comments') }}
              </Button>
            </div>
            <div class="col-10  col-md-24  text-right  valign-bottom">
              <p class="_note">{{ $t('managerEvaluation.evaluate.info') }}</p>
            </div>
          </div>
        </ContentBox>
      </template>
      <template v-else>
        <div class="row  display-flex">
          <div class="col-12  col-sm-24  col-md-12  -same-height  mb-md-xs">
            <ContentBox>
              <p class="font-size-l  mb-s">
                {{ $t('managerEvaluation.evaluate.voicesFor.label') }}
                <Tag class="-slim  -blue">{{ $t('managerEvaluation.evaluate.input.options.expert') }}</Tag>
                ({{ voteCount(adminsByVote.expert) }})
              </p>
              <List class="_votes">
                <li v-for="admin in adminsByVote.expert" :key="admin.uuid">{{ admin.firstname }} {{ admin.lastname }} ({{ voteValue(admin) }})</li>
              </List>
            </ContentBox>
          </div>
          <div class="col-12  col-sm-24  col-md-12  -same-height  mb-md-xs">
            <ContentBox>
              <p class="font-size-l  mb-s">
                {{ $t('managerEvaluation.evaluate.voicesFor.label') }}
                <Tag class="-slim  -green">{{ $t('managerEvaluation.evaluate.input.options.executive') }}</Tag>
                ({{ voteCount(adminsByVote.executive) }})
              </p>
              <List class="_votes">
                <li v-for="admin in adminsByVote.executive" :key="admin.uuid">{{ admin.firstname }} {{ admin.lastname }} ({{ voteValue(admin) }})</li>
              </List>
            </ContentBox>
          </div>
          <div class="col-12  col-sm-24  col-md-12  -same-height">
            <ContentBox>
              <p class="font-size-l  mb-s">
                {{ $t('managerEvaluation.evaluate.voicesFor.label') }}
                <Tag class="-slim  -orange">{{ $t('managerEvaluation.evaluate.input.options.executivePartner') }}</Tag>
                ({{ voteCount(adminsByVote.executivePartner) }})
              </p>
              <List class="_votes">
                <li v-for="admin in adminsByVote.executivePartner" :key="admin.uuid">{{ admin.firstname }} {{ admin.lastname }} ({{ voteValue(admin) }})</li>
              </List>
            </ContentBox>
          </div>
        </div>
      </template>
    </ContentBox>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { applicationStates } from '@/values';
import { checkupApproveUser, selectionVoteUser } from '@/api/admins.api';

import objectUtils from '@/mixins/objectUtils';

import ContentBox from '@/components/elements/ContentBox.vue';

export default {
  name: 'ManagerEvaluation',
  mixins: [objectUtils],
  props: {
    user: Object,
  },
  data() {
    return {
      selectedVote: '',
      groupByVote: this.groupBy('vote'),
    };
  },
  computed: {
    ...mapState([
      'uuid',
      'usermeta',
      'group',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    currentStateIndex() {
      return applicationStates.findIndex((state) => {
        if (Array.isArray(state)) {
          return state.indexOf(this.user.status) >= 0;
        }

        return state === this.user.status;
      });
    },

    checkupPermission() {
      return this.userCan('approveCheckup');
    },

    votePermission() {
      return this.userCan('voteSelection');
    },

    hasVoted() {
      return !!(this.adminVotes.find((admin) => admin.uuid === this.uuid));
    },

    shouldVote() {
      return this.votePermission && !this.hasVoted;
    },

    adminVotes() {
      return (this.user.adminsVoted)
        ? this.user.adminsVoted.map(({ User, ManagerAdminSelectionVote, ...others }) => ({ ...User, ...ManagerAdminSelectionVote, ...others }))
        : [];
    },

    adminsByVote() {
      return this.groupByVote(this.adminVotes);
    },
  },
  methods: {
    voteValue(admin) {
      return admin?.group.includes('executive-mgmt') ? 2 : 1;
    },

    voteCount(votes) {
      return votes?.reduce((acc, admin) => acc + this.voteValue(admin), 0) || 0;
    },

    approveCheckup() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          checkupApproveUser(this.user.uuid, token)
            .then((r) => r.data)
            .then(() => {
              this.$set(this.user, 'status', 'selection');
              this.$eventBus.$emit('notificate', {
                message: this.$t('notifications.managerEvaluation.approved.message.label'),
              });
            })
            .catch((error) => {
              console.error(error);
              this.$eventBus.$emit('notificate', {
                title: this.$t('notifications.managerEvaluation.notApproved.title'),
                message: error.response.data.message,
                status: error.response.status,
              });
            });
        });
    },

    voteSelection() {
      if (this.selectedVote) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            selectionVoteUser(this.user.uuid, { vote: this.selectedVote }, token)
              .then((r) => r.data)
              .then(() => {
                this.$emit('update');
                this.$eventBus.$emit('notificate', {
                  message: this.$t('notifications.managerEvaluation.votedSuccessfully.message.label'),
                });
              })
              .catch((error) => {
                console.error(error);
                this.$eventBus.$emit('notificate', {
                  title: this.$t('notifications.managerEvaluation.votedUnSuccessfully.title'),
                  message: error.response.data.message,
                  status: error.response.status,
                });
              });
          });
      }
    },
  },
  components: {
    Button: () => import('@/components/atoms/Button.vue'),
    List: () => import('@/components/atoms/List.vue'),
    Tag: () => import('@/components/atoms/Tag.vue'),
    InputSelect: () => import('@/components/elements/inputs/InputSelect.vue'),
    ContentBox,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-evaluation.scss"></style>
