import { render, staticRenderFns } from "./UserLanguageSkills.vue?vue&type=template&id=2c6645f3&scoped=true&"
import script from "./UserLanguageSkills.vue?vue&type=script&lang=js&"
export * from "./UserLanguageSkills.vue?vue&type=script&lang=js&"
import style0 from "@/sass/08_modules/user-language-skills.scss?vue&type=style&index=0&id=2c6645f3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6645f3",
  null
  
)

export default component.exports