<template>
  <section class="user-language-skills">
    <ContentBox>
      <div class="row">
        <div class="col-20">
          <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}">{{ $t('userLanguageSkills.headline') }}</h5>
        </div>
        <div class="col-4  text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32"
                  v-if="canEdit"
                  @click.native="$eventBus.$emit('open-modal-userLanguageSkills');">
            <Icon class="-inline -pos-absolute-center">
              <EditSVG></EditSVG>
            </Icon>
          </Button>
        </div>
      </div>
      <template v-if="languageSkills.length > 0">
        <div class="font-size-base  font-weight-medium line-height-1"
             :class="{'mt-s': index > 0}"
             v-for="(languageSkill, index) in languageSkills"
             :key="index"
        >
          <p class=" font-weight-medium  mb-xxs display-block"
             v-if="$te(`languages.popular.${languageSkill.language}`)">
            {{ $t(`languages.popular.${languageSkill.language}`) }}
          </p>
          <p class=" font-weight-medium  mb-xxs display-block"
             v-if="$te(`languages.default.${languageSkill.language}`)">
            {{ $t(`languages.default.${languageSkill.language}`) }}
          </p>
          <p v-if="$te(`userLanguageSkills.level.${languageSkill.level}`)">{{ $t(`userLanguageSkills.level.${languageSkill.level}`) }}</p>
        </div>
      </template>
      <template v-if="languageSkills.length === 0 || !languageSkills[0].language">
        <div class="font-size-base  font-weight-medium line-height-1">
          <p>{{ $t('userLanguageSkills.none') }}</p>
        </div>
      </template>
    </ContentBox>
    <Modal id="userLanguageSkills"
           :title="$t('userLanguageSkills.headline')"
           class="-thin"
           v-if="canEdit"
           @open.once="initModal"
           @close="handleModalClose">
      <FormElement>
        <div class="row">
          <div class="col-24">
            <h6 class="h6 font-size-l  font-weight-medium  text-mb-xs">{{ $t('userLanguageSkills.subheadings.edit') }}</h6>
          </div>
        </div>
        <div class="row">
          <div class="_select-wrapper" :class="{'mt-s': key > 0}" v-for="(v, key) in $v.languageSkills.$each.$iter" :key="key">
            <div class="col-11">
              <InputSelect base="userLanguageSkills"
                          :class="{'-has-error': v.language.$error}"
                           name="language"
                           @input="v.$model.language = $event;userEdited = true"
                           :value="v.$model.language || ''">
                <option disabled>{{ $t('managerCategorization.categories.languages.placeholder') }}</option>
                <template slot="options">
                  <optgroup :label="$t('countries.labels.popular')">
                    <option v-for="(country, key) in $t('languages.popular')" :key="key"
                            :disabled="isCountryAlreadySelected(key)"
                            :value="key">
                      {{ country }}
                    </option>
                  </optgroup>
                  <optgroup :label="$t('countries.labels.default')">
                    <option v-for="(country, key) in $t('languages.default')" :key="key"
                            :disabled="isCountryAlreadySelected(key)"
                            :value="key">
                      {{ country }}
                    </option>
                  </optgroup>
                </template>
              </InputSelect>
            </div>
            <div class="col-11">
              <InputSelect base="userLanguageSkills"
                           :class="{'-has-error': v.level.$error}"
                           name="level"
                           @input="v.$model.level = $event;userEdited = true"
                           :value="v.$model.level || ''">
                <option disabled>{{ $t('userLanguageSkills.inputs.level.placeholder') }}</option>
                <template slot="options">
                  <option v-for="(country, key) in $t('userLanguageSkills.level')" :key="key"
                          :value="key">
                    {{ country }}
                  </option>
                </template>
              </InputSelect>
            </div>
            <div class="col-2 valign-bottom text-pb-xs">
              <Link v-if="key > 0" href="#" class="-orange" @click.native.prevent="removeLanguage(key)">
                <Icon class="-inline">
                  <RemoveCircleSVG/>
                </Icon>
              </Link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-24 mt-xs">
            <Link href="#"
                  @click.native.prevent="addLanguage"
                  class="-orange"
            >
              {{ $t('userLanguageSkills.buttons.addLanguage') }}
              <Icon class="-inline">
                <PlusSVG/>
              </Icon>
            </Link>
          </div>
        </div>
      </FormElement>
      <template slot="footer">
        <div class="text-right">
          <Button class="mr-s -orange -outlined" @click.native="$eventBus.$emit('close-modal-userLanguageSkills')">
            {{ $t('userLanguageSkills.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="saveAndExit">
            {{ $t('userLanguageSkills.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { objectUtils } from '@/mixins';

import { updateLanguageSkills } from '@/api/users.api';
import userAssets from '@/mixins/userAssets';
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import Icon from '@/components/atoms/Icon.vue';
import Link from '@/components/atoms/Link.vue';
import Modal from '@/components/elements/Modal.vue';

import PlusSVG from '@/assets/icons/plus.svg';
import EditSVG from '@/assets/icons/edit.svg';
import RemoveCircleSVG from '@/assets/icons/remove-circle.svg';

export default {
  name: 'UserLanguageSkills',
  mixins: [userAssets, formatNumbers, validationMixin, objectUtils],
  props: {
    user: Object,
  },
  data() {
    return {
      userEdited: false,
      languageSkills: [],
    };
  },

  computed: {
    ...mapState([
      'uuid',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    canEdit() {
      return this.userCan('edit', this.uuid) || this.userCan('updateLanguageSkills', this.uuid);
    },
  },
  validations: {
    languageSkills: {
      $each: {
        language: {
          required,
        },
        level: {
          required,
        },
      },
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.languageSkills = this.cloneObject(this.user.languageSkills);
    },
    initModal() {
      if (this.languageSkills.length === 0) {
        this.addLanguage();
        this.userEdited = false;
      }
    },
    addLanguage() {
      this.userEdited = true;
      this.languageSkills.push({
        language: null,
        level: null,
      });
    },
    handleModalClose() {
      if (this.userEdited) {
        this.userEdited = false;
        this.initData();
      }
    },
    isCountryAlreadySelected(key) {
      return this.languageSkills.filter((languageSkill) => languageSkill.language === key).length > 0;
    },
    removeLanguage(key) {
      this.userEdited = true;
      this.languageSkills.splice(key, 1);
    },

    saveAndExit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateLanguageSkills();
      }
    },
    updateLanguageSkills() {
      if (this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            updateLanguageSkills(this.user.uuid, this.languageSkills, token)
              .then(() => {
                this.userEdited = false;
                this.$emit('update');
                this.$eventBus.$emit('close-modal-userLanguageSkills');
                this.$eventBus.$emit('notificate');
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              });
          });
      }
    },
  },
  components: {
    Button,
    ContentBox,
    FormElement,
    Icon,
    InputSelect,
    Link,
    Modal,

    EditSVG,
    PlusSVG,
    RemoveCircleSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-language-skills.scss"></style>
