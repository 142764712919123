<template>
  <section class="manager-admin-actions">
    <ContentBox class="mb-xs">
      <div>
        <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}">{{ $t('managerAdminActions.headline') }}</h5>
        <div v-if="userCan('setGroup')" class="mb-xs">
          <div class="sub-headline">{{ $t('managerAdminActions.actions.setGroup.label') }}</div>
          <InputSelect :class="['display-inlineblock']"
                       name="groups"
                       base="userDetail"
                       v-if="userCan('setGroup')"
                       :value="userGroup"
                       @input="handleGroupSelect">
            <option v-for="(option, key) in $t('managerAdminActions.actions.setGroup.values')"
                    :key="key"
                    :value="key">
              {{ option }}
            </option>
            <optgroup :label="$t('managerAdminActions.actions.setGroup.pending.label')">
              <option v-for="(option, key) in $t('managerAdminActions.actions.setGroup.pending.values')"
                      :key="key"
                      :value="key">
                {{ option }}
              </option>
            </optgroup>
          </InputSelect>
        </div>
        <div class="mb-xs">
          <div class="sub-headline">{{ $t('managerAdminActions.actions.setAdminStatus.label') }}</div>
          <InputSelect :class="['display-inlineblock']"
                       name="adminStatus"
                       base="userDetail"
                       :options="$t('managerAdminActions.actions.setAdminStatus.values')"
                       :value="adminStatus"
                       :disabled="!userCan('setAdminStatus')"
                       @input="handleSetAdminStatus">
            <option value="">{{ $t('managerAdminActions.actions.setAdminStatus.placeholder') }}</option>
          </InputSelect>
        </div>
        <div class="mb-xs">
          <div class="sub-headline">{{ $t('managerAdminActions.actions.setOwner.label') }}</div>
          <InputSelect :class="['display-inlineblock']"
                       name="owner"
                       base="userDetail"
                       :value="owner"
                       :disabled="!userCan('setOwner')"
                       @input="handleSetOwner">
            <option value="">{{ $t('managerAdminActions.actions.setOwner.placeholder') }}</option>
            <template v-slot:options>
              <option v-for="admin in ownerList" :key="admin.uuid" :value="admin.uuid" :selected="owner === admin.uuid">{{ admin.firstname }} {{ admin.lastname }}</option>
            </template>
          </InputSelect>
        </div>
      </div>
      <div>
        <div class="sub-headline">{{ $t('managerAdminActions.account') }}</div>
        <AnchorLink class="-orange"
                    v-if="$route.name === 'UserDetail'"
                    @click.native="$router.push({ name: 'ApplicationDetail', params: { username, lang: $i18n.locale } })">
          <Icon class="mr-xxs">
            <ContentPasteSVG></ContentPasteSVG>
          </Icon>
          <span>{{ $t('managerAdminActions.links.application') }}</span>
        </AnchorLink>
        <AnchorLink class="-orange"
                    v-if="$route.name === 'UserDetail'"
                    @click.native="$eventBus.$emit('open-modal-match', { managerUuid: uuid, managerMatches: matches })">
          <Icon class="mr-xxs">
            <PersonAddSVG></PersonAddSVG>
          </Icon>
          <span>{{ $t('managerAdminActions.links.matchModal') }}</span>
        </AnchorLink>
        <AnchorLink class="-orange"
                    v-if="$route.name === 'ApplicationDetail'"
                    @click.native="$router.push({ name: 'UserDetail', params: { username, lang: $i18n.locale } })">
          <Icon class="mr-xxs">
            <AccountSVG></AccountSVG>
          </Icon>
          <span>{{ $t('managerAdminActions.links.userDetail') }}</span>
        </AnchorLink>
        <AnchorLink class="-orange"
                    v-if="commentableType !== null"
                    @click.native="$eventBus.$emit('open-modal-comments', { manager: uuid, commentableType: commentableType, commentableId: userId })">
          <Icon class="mr-xxs">
            <CommentsSVG></CommentsSVG>
          </Icon>
          <span>{{ $t('managerAdminActions.links.managerComment') }}</span>
        </AnchorLink>
        <AnchorLink class="-orange"
                    v-if="active && userCan('deactivateUser')"
                    @click.native="handleSuperAdminActions('setInactive')">
          <Icon class="-inline  -is-left">
            <UserOffSVG></UserOffSVG>
          </Icon>
          <template>{{ $t('superadmins.actions.setInactive') }}</template>
        </AnchorLink>
        <AnchorLink class="-orange"
                    v-if="!active && userCan('deactivateUser')"
                    @click.native="handleSuperAdminActions('setActive')">
          <Icon class="-inline  -is-left">
            <AccountSVG></AccountSVG>
          </Icon>
          <template>{{ $t('superadmins.actions.setActive') }}</template>
        </AnchorLink>
        <AnchorLink class="-orange"
                    v-if="userCan('deleteUser')"
                    @click.native="handleDelete">
          <Icon class="-inline  -is-left">
            <TrashSVG></TrashSVG>
          </Icon>
          <span>{{ $t('superadmins.actions.deleteUser') }}</span>
        </AnchorLink>
      </div>
      <div v-if="migrated" class="pt-xs">
        <div class="sub-headline">{{ $t('managerAdminActions.migrated_from.headline') }}</div>
        <p class="font-size-s  font-weight-medium">{{ $t('managerAdminActions.migrated_from.label') }} {{ $t(`managerAdminActions.migrated_from.${migrated}`) }}</p>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { patchUser } from '@/api/admins.api';
import adminActions from '@/mixins/adminActions';
import { possibleAssignees } from '@/values';

import AnchorLink from '@/components/atoms/Link.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';

import PersonAddSVG from '@/assets/icons/person-add.svg';

export default {
  name: 'ManagerAdminActions',
  mixins: [adminActions],
  props: {
    uuid: String,
    userId: Number,
    username: String,
    active: Boolean,
    status: String,
    group: String,
    partner_potential: Boolean,
    partner: Boolean,
    adminStatus: String,
    owner: String,
    migrated: String,
    matches: Array,
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ownerList: [],
      actions: {
        'self-assessment': 'setAsPending',
        checkup: 'setAsPending',
        selection: 'setAsPending',
        sighting: 'setAsPending',
        consolidation: 'setAsPending',
        decision: 'setAsPending',
        expert: 'setAsExpert',
        executive: 'setAsExecutive',
        executivePartnerPotential: 'setAsExecutivePartnerPotential',
        partner: 'setAsPartner',
      },
      redirectTimer: 2,
    };
  },
  computed: {
    userGroup() {
      if (this.status === 'completed' && this.partner) {
        return 'partner';
      }

      if (this.status === 'completed' && this.partner_potential) {
        return 'executivePartnerPotential';
      }

      if (this.group === 'pending') {
        return this.status;
      }

      return this.group;
    },
    commentableType() {
      const types = {
        UserDetail: 'Manager',
        ApplicationDetail: 'ApplicationDetail',
      };

      return types[this.$route.name] ?? null;
    },
  },
  mounted() {
    this.getPossibleOwner();
  },
  methods: {
    handleGroupSelect(action) {
      this.handleSuperAdminActions(this.actions[action], true, { status: action });
    },

    handleSetAdminStatus(status) {
      if (this.userCan('setAdminStatus')) {
        this.patchUser({
          admin_status: (status.length > 0) ? status : null,
        });
      }
    },

    handleSetOwner(adminUuid) {
      this.patchUser({
        owner: (adminUuid.length > 0) ? adminUuid : null,
      });
    },

    patchUser(fields) {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchUser(this.uuid, fields, token)
            .then(() => {
              this.$emit('update');
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    getPossibleOwner() {
      this.$store.dispatch('getAdminList')
        .then((adminList) => {
          this.ownerList = adminList.filter((admin) => admin.group.some((group) => possibleAssignees.managers.includes(group)));
        })
        .catch((error) => console.error(error));
    },
  },
  components: {
    AnchorLink,
    Icon,
    ContentBox,
    InputSelect,
    CommentsSVG: () => import('@/assets/icons/comments.svg'),
    ContentPasteSVG: () => import('@/assets/icons/content-paste.svg'),
    AccountSVG: () => import('@/assets/icons/account.svg'),
    TrashSVG: () => import('@/assets/icons/trash.svg'),
    UserOffSVG: () => import('@/assets/icons/user-off.svg'),
    PersonAddSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-admin-actions.scss"></style>
