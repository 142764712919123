<template>
  <div class="user-detail">
    <Background></Background>
    <UserHeader v-if="user.uuid"
                :user="user"
                :avatar="avatar">
    </UserHeader>
    <div class="row" v-if="$matchMedia.md">
      <!-- Mobile -->
      <div class="col-24">
        <ManagerProfileInfo v-if="user.uuid"
                            :uuid="user.uuid"
                            :jobTitle="user.jobTitle"
                            :linkedin="user.linkedin"
                            :xing="user.xing"
                            :website="user.website"
                            :username="user.username"
                            :updatedAt="user.updatedAt"
                            :consentAgb="user.consentAgb"
                            :consentPrivacy="user.consentPrivacy"
                            @update="fetchUser">
        </ManagerProfileInfo>
        <ManagerAdminActions v-if="user.uuid"
                            :uuid="user.uuid"
                            :userId="user.id"
                            :group="user.group"
                            :status="user.status"
                            :partner_potential="user.partner_potential"
                            :partner="user.partner"
                            :active="user.active"
                            :username="username"
                            :adminStatus="user.admin_status"
                            :owner="user.owner"
                            :migrated="user.migrated_from"
                            :redirect="true"
                            :matches="matches"
                            @update="fetchUser">
        </ManagerAdminActions>
        <ManagerDocumentManager v-if="user.uuid" :user="{...user }">
        </ManagerDocumentManager>
        <SubscribeMarketingMails v-if="user.uuid"
                                 @update="fetchUser"
                                 :user="{ uuid: user.uuid, subscribe_projects: user.subscribe_projects }">
        </SubscribeMarketingMails>
        <ManagerDailyRate v-if="user.uuid"
                          :user="{...user}"
                          @update="fetchUser">
        </ManagerDailyRate>
        <UserAvailability v-if="user.uuid"
                          :user="{ ...user }"
                          @update="fetchUser">
        </UserAvailability>

        <HighlightText>
          <ManagerSkills v-if="user.uuid"
                        :userUuid="user.uuid"
                        :skills="user.skills"
                        @update="fetchUser">
          </ManagerSkills>
          <keep-alive>
            <component :is="showCvVersion"
                      v-if="user.uuid"
                      v-bind="cvProps"
                      @switchCvView="handleCvSwitch"
                      @update="fetchUser">
            </component>
          </keep-alive>
          <UserEducation v-if="user.uuid && !user.keep_old_cv"
                        :uuid="user.uuid"
                        :entries="user.education_entries"
                        @update="fetchUser">
          </UserEducation>
          <UserCategories v-if="user.uuid"
                          :userUuid="user.uuid"
                          :categories="userCategories"
                          @update="fetchUser">
          </UserCategories>
          <UserWillingnessToTravel v-if="user.uuid"
                                  :user="user"
                                  @update="fetchUser" />
          <UserLanguageSkills v-if="user.uuid"
                              :user="user"
                              @update="fetchUser">
          </UserLanguageSkills>
          <ManagerCategorization v-if="user.uuid"
                                :uuid="user.uuid"
                                :categorization="user.categorization"
                                @update="fetchUser">
          </ManagerCategorization>
          <ManagerLeadHistory v-if="user.uuid" :uuid="user.uuid" />
          <ManagerApplicationHistory v-if="user.uuid" :applications="user.projectApplications" />
          <UserMeta v-if="user.uuid"
                    :user="user"
                    :avatar="avatar"
                    @update="fetchUser">
          </UserMeta>
        </HighlightText>
      </div>
    </div>
    <div class="row" v-if="!$matchMedia.md">
      <!-- Desktop -->
      <div class="col-18">
        <HighlightText>
          <ManagerSkills v-if="user.uuid"
                        :userUuid="user.uuid"
                        :skills="user.skills"
                        @update="fetchUser">
          </ManagerSkills>
          <ManagerCvAddendum position="before"
                            v-if="user.uuid && !user.keep_old_cv"
                            :uuid="user.uuid"
                            :addendum="{ de: user.cv_before_de, en: user.cv_before_en }"
                            @update="fetchUser">
          </ManagerCvAddendum>
          <keep-alive>
            <component :is="showCvVersion"
                      v-if="user.uuid"
                      v-bind="cvProps"
                      @switchCvView="handleCvSwitch"
                      @update="fetchUser">
            </component>
          </keep-alive>
          <UserEducation v-if="user.uuid && !user.keep_old_cv"
                        :uuid="user.uuid"
                        :entries="user.education_entries"
                        @update="fetchUser">
          </UserEducation>
          <ManagerCvAddendum position="after"
                            v-if="user.uuid && !user.keep_old_cv"
                            :uuid="user.uuid"
                            :addendum="{ de: user.cv_after_de, en: user.cv_after_en }"
                            @update="fetchUser">
          </ManagerCvAddendum>
          <UserCategories v-if="user.uuid"
                          :userUuid="user.uuid"
                          :categories="userCategories"
                          @update="fetchUser">
          </UserCategories>
          <ManagerCategorization v-if="user.uuid"
                                :uuid="user.uuid"
                                :categorization="user.categorization"
                                @update="fetchUser">
          </ManagerCategorization>
          <ManagerLeadHistory v-if="user.uuid" :uuid="user.uuid" @fetchedMatches="updateMatches" />
          <ManagerApplicationHistory v-if="user.uuid" :applications="user.projectApplications" />
          <UserMeta v-if="user.uuid"
                    :user="user"
                    :avatar="avatar"
                    @update="fetchUser">
          </UserMeta>
        </HighlightText>
      </div>
      <div class="col-6">
        <ManagerAdminActions v-if="user.uuid"
                            :uuid="user.uuid"
                            :userId="user.id"
                            :group="user.group"
                            :status="user.status"
                            :partner_potential="user.partner_potential"
                            :partner="user.partner"
                            :active="user.active"
                            :username="username"
                            :adminStatus="user.admin_status"
                            :owner="user.owner"
                            :migrated="user.migrated_from"
                            :redirect="true"
                            :matches="matches"
                            @update="fetchUser">
        </ManagerAdminActions>
        <ManagerProfileInfo v-if="user.uuid"
                            :uuid="user.uuid"
                            :jobTitle="user.jobTitle"
                            :linkedin="user.linkedin"
                            :xing="user.xing"
                            :website="user.website"
                            :username="user.username"
                            :updatedAt="user.updatedAt"
                            :consentAgb="user.consentAgb"
                            :consentPrivacy="user.consentPrivacy"
                            @update="fetchUser">
        </ManagerProfileInfo>
        <ManagerDocumentManager v-if="user.uuid" :user="{...user}">
        </ManagerDocumentManager>
        <SubscribeMarketingMails v-if="user.uuid"
                                 @update="fetchUser"
                                 :user="{ uuid: user.uuid, subscribe_projects: user.subscribe_projects }">
        </SubscribeMarketingMails>
        <ManagerDailyRate v-if="user.uuid"
                          :user="{...user}"
                          @update="fetchUser">
        </ManagerDailyRate>
        <UserAvailability v-if="user.uuid"
                          :user="{ ...user }"
                          @update="fetchUser">
        </UserAvailability>
        <UserWillingnessToTravel v-if="user.uuid"
                                :user="user"
                                @update="fetchUser" />
        <UserLanguageSkills v-if="user.uuid"
                            :user="user"
                            @update="fetchUser">
        </UserLanguageSkills>
      </div>
    </div>
  </div>
</template>

<script>
import { findUser } from '@/api/admins.api';
import { parseDates, userAssets } from '@/mixins';

import Background from '@/components/atoms/Background.vue';

import ManagerAdminActions from '@/components/modules/User/admin/ManagerAdminActions.vue';
import ManagerApplicationHistory from '@/components/modules/User/admin/ManagerApplicationHistory.vue';
import ManagerCategorization from '@/components/modules/User/admin/ManagerCategorization.vue';
import ManagerDailyRate from '@/components/modules/User/ManagerDailyRate.vue';
import ManagerProfileInfo from '@/components/modules/User/ManagerProfileInfo.vue';
import ManagerDocumentManager from '@/components/modules/User/ManagerDocumentManager.vue';
import ManagerSkills from '@/components/modules/User/ManagerSkills.vue';
import UserAvailability from '@/components/modules/User/UserAvailability.vue';
import UserCategories from '@/components/modules/User/UserCategories.vue';
import UserHeader from '@/components/modules/User/UserHeader.vue';
import UserLanguageSkills from '@/components/modules/User/UserLanguageSkills.vue';
import UserMeta from '@/components/modules/User/UserMeta.vue';
import UserWillingnessToTravel from '@/components/modules/User/UserWillingnessToTravel.vue';
import ManagerLeadHistory from '@/components/modules/User/admin/ManagerLeadHistory.vue';
import HighlightText from '@/components/elements/HighlightText.vue';
import SubscribeMarketingMails from '@/components/modules/User/SubscribeMarketingMails.vue';

export default {
  mixins: [parseDates, userAssets],
  name: 'UserDetail',
  props: {
    username: String,
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      switchCvView: false,
      redirectTimer: 3,
      user: {},
      matches: [],
      avatar: '',
    };
  },
  computed: {
    userCategories() {
      return {
        main_operationals: (this.user.main_operationals.length > 0) ? this.user.main_operationals : [],
        sub_operationals: (this.user.sub_operationals.length > 0) ? this.user.sub_operationals : [],
        main_industries: (this.user.main_industries.length > 0) ? this.user.main_industries : [],
        sub_industries: (this.user.sub_industries.length > 0) ? this.user.sub_industries : [],
      };
    },

    showCvVersion() {
      return (this.user.keep_old_cv === this.switchCvView) ? 'UserCv' : 'UserVita';
    },

    cvProps() {
      return {
        uuid: this.user.uuid,
        keep_old_cv: this.user.keep_old_cv,
        ...(this.showCvVersion === 'UserCv') && {
          cv_entries: this.user.cv_entries,
          oldCvLink: !!this.user.vita,
        },
        ...(this.showCvVersion === 'UserVita') && {
          vita: this.user.vita,
          useTemplate: true,
        },
      };
    },
  },
  mounted() {
    this.$eventBus.$emit('visited-user-detail', this.username);
    this.fetchUser();
  },
  methods: {
    handleCvSwitch() {
      this.switchCvView = !this.switchCvView;
    },

    fetchUser() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          findUser(this.username, token)
            .then((r) => r.data)
            .then(({ data }) => {
              const { User, ...fields } = data;
              this.$set(this, 'user', { ...User, ...fields });
              this.getAvatar(this.user.uuid);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.$router.replace({ name: 'NotFound', params: { lang: this.$i18n.locale } });
              }
            });
        });
    },

    updateMatches(matches) {
      this.matches = matches;
    },
  },
  components: {
    Background,
    ManagerDocumentManager,
    ManagerAdminActions,
    ManagerApplicationHistory,
    ManagerCategorization,
    ManagerDailyRate,
    ManagerProfileInfo,
    ManagerSkills,
    UserAvailability,
    UserCategories,
    UserHeader,
    UserLanguageSkills,
    UserMeta,
    UserWillingnessToTravel,
    ManagerLeadHistory,
    ManagerCvAddendum: () => import('@/components/modules/User/admin/ManagerCvAddendum.vue'),
    UserCv: () => import('@/components/modules/User/UserCv.vue'),
    UserVita: () => import('@/components/modules/User/UserVita.vue'),
    UserEducation: () => import('@/components/modules/User/UserEducation.vue'),
    HighlightText,
    SubscribeMarketingMails,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-detail.scss"></style>
