<template>
  <div class="users-list">
    <Background class="-slim"></Background>
    <div class="row  mt-xs  mb-m  pos-relative">
      <div class="col-16  col-md-24">
        <h2 class="h2  mb-0  mt-ß  font-weight-normal  color-white">{{ $t('adminList.headline') }}</h2>
      </div>
      <div v-if="userCan('createAdmins')" class="col-8  col-md-24  valign-bottom  mt-md-xs" :class="{'text-right': !$matchMedia.md}">
        <Button class="-orange" @click.native="handleOpenCreate">
          {{ $t('adminList.nav.create') }}
        </Button>
      </div>
    </div>
    <AdminList :defaultSort="sort"></AdminList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Background from '@/components/atoms/Background.vue';
import Button from '@/components/atoms/Button.vue';
import AdminList from '@/components/modules/User/admin/AdminList.vue';

export default {
  name: 'Admins',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.savedPosition = window.event?.type === 'popstate';
    });
  },
  data() {
    return {
      sort: {
        value: 'createdAt',
        direction: 'desc',
      },
      savedPosition: false,
    };
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),
  },
  methods: {
    handleOpenCreate() {
      this.$eventBus.$emit('open-modal-adminModalCreate');
    },
  },
  components: {
    Background,
    Button,
    AdminList,
  },
};
</script>
