<template>
  <form class="form-element" @submit.prevent="submitHandler">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'FormElement',
  methods: {
    submitHandler() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/form-element.scss"></style>
