<template>
  <div :class="['modal', { 'is-open': isOpen }]">
    <div v-if="closeOnBgClick" tabindex="0" class="modal-backdrop" @click="close"></div>
    <div class="row  display-flex  justify-content-center  align-items-center">
      <div class="col-24">
        <div class="modal-content">
          <div class="_header">
            <div class="row">
              <div class="col-20  valign-middle">
                <h4 class="mb-0  font-weight-normal">{{ title }}</h4>
              </div>
            </div>
          </div>
          <ContentBox :class="{'without-footer': !this.$slots.footer}">
            <slot></slot>
          </ContentBox>
          <div class="_footer" v-if="this.$slots.footer">
            <div class="row">
              <div class="col-24 valign-middle">
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentBox from '@/components/elements/ContentBox.vue';

export default {
  name: 'Modal',
  props: {
    id: String,
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    title: String,
    disable: {
      type: Boolean,
      default: false,
    },
    closeOnBgClick: {
      type: Boolean,
      default: false,
    },
    closeOnEsc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    this.$eventBus.$on(`open-modal-${this.id}`, this.open);
    this.$eventBus.$on(`close-modal-${this.id}`, this.close);

    if (this.defaultOpen) {
      this.open();
    }
  },
  methods: {
    open(ev) {
      this.isOpen = true;
      this.$emit('open', ev);
      document.querySelector('html').style.overflow = 'hidden';

      if (this.closeOnEsc) {
        document.addEventListener('keydown', this.onEscape);
      }
    },

    close(force = false) {
      if (!this.disable || force) {
        this.isOpen = false;
        this.$emit('close');
        document.querySelector('html').style.overflow = '';
        document.removeEventListener('keydown', this.onEscape);
      }
    },

    onEscape(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },
  },
  components: {
    ContentBox,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/modal.scss"></style>
