<template>
  <div class="highlight-text" ref="content">
    <slot></slot>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'HighlightText',
  props: {
    highlight: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      highlightWords: [],
    };
  },
  beforeMount() {
    const { keywords } = this.$route.query;
    const higlights = keywords ? decodeURIComponent(keywords).split(',') : [];
    this.highlightWords = [...higlights, ...this.highlight];
  },
  mounted() {
    if (!window.find) return; // cancel if find() is not supported by browser

    // observe slot and run highlighAll() when content in slot changes
    this.observer = new MutationObserver(_.debounce(this.highlightAll, 500));
    this.observer.observe(this.$refs.content, { subtree: true, attributes: false, childList: true });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    highlightWord(word, backward = false) {
      while (window.find(word, false, backward)) {
        const range = window.getSelection().getRangeAt(0);

        if (
          this.$refs.content
          && this.$refs.content.contains(range.commonAncestorContainer)
          && range.commonAncestorContainer.parentElement.nodeName !== 'MARK'
        ) {
          try {
            range.surroundContents(document.createElement('mark'));
            range.detach();
          } catch (e) {
            console.log(e);
          }
        }
      }
    },

    highlightAll() {
      this.highlightWords.forEach((word) => {
        this.highlightWord(word); // forward search
        this.highlightWord(word, true); // backward search
      });

      // find() scrolls to result, so resut when finished highlightinh
      if (this.highlightWords.length > 0) window.scrollTo(0, 0);
      window.getSelection().removeAllRanges();
      this.observer.disconnect();
    },
  },
};
</script>

<style>
  .highlight{
    background-color: gold;
  }
</style>
