<template>
  <div class="pdfViewer" :style="`width:${width};`">
    <template v-if="isLoading">
      <div class="mt-xl text-center">
        <Icon>
          <LoaderSVG />
        </Icon>
      </div>
    </template>
    <pdf v-else v-for="pageNum in numPages" :key="`${file}_${pageNum}`" ref="pdfView" :resize="true" :page="pageNum" :src="src" @loading="(e) => onLoaded(e, pageNum)" style="width: calc(100% - 60px)" />
  </div>
</template>

<script>
import pdf from 'pdfvuer';
import LoaderSVG from '@/assets/icons/loader.svg';
import Icon from '@/components/atoms/Icon.vue';
import { getDocument } from '@/api/users.api';

export default {
  name: 'PdfViewer',
  props: {
    file: String,
    manager_uuid: String,
    highlightWords: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '80vw',
    },
  },
  data() {
    return {
      src: null,
      numPages: 0,
      isLoading: true,
    };
  },
  watch: {
    file() {
      if (this.file && this.manager_uuid) {
        this.getSrc();
      } else {
        this.src = null;
        this.numPages = 0;
      }
    },
  },
  methods: {
    getSrc() {
      this.isLoading = true;

      this.$store.dispatch('isLoggedIn').then((token) => {
        getDocument(this.manager_uuid, this.file, token)
          .then(async (res) => {
            this.blobToBase64(res.data).then((src) => {
              this.src = pdf.createLoadingTask(src);

              // get number of pages to render all pages at once
              this.src.then((pdfInstance) => {
                this.numPages = pdfInstance.numPages;
                this.isLoading = false;
              });
            });
          })
          .catch(() => {
            this.$eventBus.$emit('notificate', { status: 404, title: this.$t('userMeta.fields.managerPdf.error') });
          });
      });
    },

    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    onLoaded(isLoading, pageNum) {
      // TODO: Check why every page renders twice
      if (isLoading === false) {
        this.highlightText(pageNum);
      }
    },
    highlightText(pageNum) {
      if (this.highlightWords === '') return;

      const pdfObject = this.$refs.pdfView[pageNum - 1];

      if (pdfObject) {
        pdfObject.pdfFindController.executeCommand('find', {
          caseSensitive: false,
          findPrevious: undefined,
          highlightAll: true,
          phraseSearch: false,
          query: this.highlightWords,
        });
      }
    },
  },
  components: {
    pdf,
    LoaderSVG,
    Icon,
  },
};
</script>

<style lang="scss" src="@/sass/07_elements/pdf-viewer.scss"></style>
