<template>
  <div class="project-application">
    <div class="display-flex  justify-content-between  align-items-start">
      <Tag :class="['-slim -no-margin-bottom -text-xs', statusClass]">
        {{ $t(`projectApplications.project.status.${projectStatus}`) }}
      </Tag>
      <div class="display-flex  justify-content-between  align-items-center">
        <Tag v-if="unsetApplications > 0" class="project-status-count-tag  -circle  -orange  -no-padding -no-margin-bottom"></Tag>
        <Tag class="-width-24  -white  -outlined  text-center  -no-margin -no-margin-bottom">
          {{ count }}
        </Tag>
      </div>
    </div>
    <div class="font-weight-medium mt-xxs">
      {{ project.title }}
    </div>
    <div v-if="project.pc" class="font-size-xs">
      <span v-if="project.pc" class="mr-xs">{{ $t(`projectInternals.inputs.pc.values.${project.pc}`) }}</span>
    </div>
    <div v-if="project.assignee" class="font-size-xs  color-blue-1000  font-weight-normal">{{ project.assignee.User.firstname }} {{ project.assignee.User.lastname }}</div>
  </div>
</template>

<script>
import Tag from '@/components/atoms/Tag.vue';

export default {
  name: 'Project',
  props: {
    project: Object,
    count: Number,
    unsetApplications: Number,
  },
  computed: {
    projectStatus() {
      if (this.project.status === 'staffed') return this.project.status;
      return (new Date(this.project.application_deadline) < new Date()) ? 'closed' : this.project.status;
    },

    statusClass() {
      switch (this.projectStatus) {
        case 'staffed':
          return '-green';
        case 'closed':
        case 'draft':
          return '-grey';
        default:
          return '-blue';
      }
    },
  },
  components: {
    Tag,
  },
};
</script>
