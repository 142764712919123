<template>
  <section class="manager-interviews" v-if="activeState || isCompleted">
    <ContentBox>
      <template v-for="state in states">
        <AccordionContent
          :key="state"
          :disabled="shouldBeDisabled(state)"
          :open="shouldBeOpen(state)"
          :recommendation="getRecommendation(state)"
        >
          <template v-slot:beforeTitle v-if="isFinished(state)">
            <Icon class="display-flex">
              <CheckmarkCircleFilledSVG class="color-green-300"></CheckmarkCircleFilledSVG>
            </Icon>
          </template>

          <template v-slot:title>
            <h6 class="h6 mb-0 -full-width display-block font-weight-semibold">
              {{ getTitleForState(state) }}
            </h6>
          </template>

          <template v-slot:afterTitle v-if="isFinished(state)">
            <Tag
              class="tag -slim -no-margin-bottom"
              :class="groupTagClass(stateData[state].recommendation)"
            >
              {{ $t(`managerInterviews.evaluation.steps.${state}.result.choices.${stateData[state].recommendation}`) }}
            </Tag>
          </template>

          <template v-slot:content>
            <template v-if="state === activeState && shouldAssign">
              <div class="row">
                <div class="col-24">
                  <p class="font-size-m font-weight-medium">{{ $t('managerInterviews.assign.headline', {step: getTitleForState(state)}) }}</p>
                  <InputSelect class="-dark  display-inlineblock  mr-s" v-model="selectedAssignee">
                    <option value="" selected disabled>{{ $t('managerInterviews.assign.input.placeholder') }}</option>
                    <template v-slot:options>
                      <option v-for="admin in assigneeList[state]" :key="admin.uuid" :value="admin.uuid">{{ admin.firstname }} {{ admin.lastname }}</option>
                    </template>
                  </InputSelect>
                  <Button class="-orange" @click.native="onSetAssignee">{{ $t('managerInterviews.assign.submit') }}</Button>
                </div>
              </div>
            </template>
            <FormElement v-if="state !== activeState || isAssigned">
              <div class="interview-meta">
                <div class="_meta">
                  <div class="_meta-content">
                    <div>
                      <span class="_title">{{ $t('managerInterviews.assignee') }}:</span>
                    </div>
                    <template v-if="assigneeEdit === state">
                      <InputSelect class="-light  display-inlineblock  mr-s"
                                   v-model="stateData[state].assignee.uuid"
                                   @input="changeAssignee(state)">
                        <template v-slot:options>
                          <option v-for="admin in assigneeList[state]"
                                  :key="admin.uuid"
                                  :value="admin.uuid"
                          >
                            {{ admin.firstname }} {{ admin.lastname }}
                          </option>
                        </template>
                      </InputSelect>
                    </template>
                    <template v-else>
                      <div class="font-weight-medium pos-relative"
                           :class="{'date-edittable': shouldEdit(state)}"
                           @mouseenter="showEditAssigneeButton = true"
                           @mouseleave="showEditAssigneeButton = false"
                      >
                        {{ stateData[state].assignee.firstname }} {{ stateData[state].assignee.lastname }}
                        <Button v-if="canAssign"
                                class="ml-xxs -no-padding negative-top-xxs"
                                :class="{'visible': showEditAssigneeButton, 'invisible': !showEditAssigneeButton}"
                                @click.native="assigneeEdit = state; showEditAssigneeButton = false">
                          <Icon class="-inline -size-14">
                            <EditSVG></EditSVG>
                          </Icon>
                        </Button>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="_meta">
                  <div class="_meta-content">
                    <div><span class="_title">{{ $t('managerInterviews.date') }}:</span></div>
                    <div>
                      <span class="font-weight-medium" v-if="!shouldEdit(state)">{{ dateToGerman(stateData[state].date) }}</span>
                      <InputText v-if="shouldEdit(state)"
                                 class="-small  -inline"
                                 type="date"
                                 alt="-small"
                                 :disabled="!shouldEdit(state)"
                                 @input="userEdited = true"
                                 v-model="$v.stateData.$model[state].date"></InputText>
                    </div>
                  </div>
                </div>
                <div class="_meta">
                  <div class="_meta-content">
                    <div>
                      <span class="_title">{{ $t('managerInterviews.via.title') }}:</span>
                    </div>
                    <div class="radio-group font-weight-medium" :class="{'date-edittable': shouldEdit(state)}">
                    <InputRadio v-for="(input, key) in $t('managerInterviews.via.inputs')"
                                :key="`via-${key}`"
                                :choice="key"
                                :name="`via`"
                                :disabled="!shouldEdit(state)"
                                @input="userEdited = true"
                                v-model="stateData[state].via"
                                class="-small"
                    >
                      <template slot="label">{{ input }}</template>
                    </InputRadio>
                  </div>
                  </div>
                </div>
              </div>
              <div class="eval-matrix">
                <div class="row">
                  <div class="col-16  col-md-12  col-sm-24">
                    <h6 class="h6">{{ $t('managerInterviews.evaluation.headline') }}</h6>
                  </div>
                  <div class="col-8  col-md-12  col-sm-24  text-right">
                    <span class="h6  _eval-th" v-for="choice in $t(`managerInterviews.evaluation.steps.${state}.choices`)" :key="choice">{{ choice }}</span>
                  </div>
                </div>
                <div class="row  _eval-row"
                     v-for="(criteria, groupkey) in getInputs(state).default"
                     :key="groupkey">
                  <div class="col-16  col-md-12  col-sm-24">
                    {{ criteria }}
                  </div>
                  <div class="col-8  col-md-12  col-sm-24  text-right">
                    <div class="radio-group">
                      <InputRadio v-for="choice in $t(`managerInterviews.evaluation.steps.${state}.choices`)"
                                  :key="`${groupkey}-${choice}`"
                                  :choice="`${choice}`"
                                  :name="`${groupkey}`"
                                  :disabled="!shouldEdit(state)"
                                  @input="userEdited = true"
                                  v-model="stateData[state].rating[groupkey]"
                      >
                      </InputRadio>
                    </div>
                  </div>
                </div>
                <div class="_note  text-right">{{ $t('managerInterviews.evaluation.description') }}</div>
              </div>
              <div class="interview-notes">
                <h6 class="h6">{{ $t('managerInterviews.evaluation.notes') }}</h6>
                <InputRichText name="notes"
                               :disabled="!shouldEdit(state)"
                               @input="userEdited = true"
                               v-model="stateData[state].notes">
                </InputRichText>
              </div>
              <div class="interview-footer">
                <div class="row">
                  <div :class="{'col-6': shouldComplete(state), 'col-24': !shouldComplete(state)}">
                    <Button class="-blue"
                            @click.native="onSaveInterview"
                            v-if="shouldComplete(state)">
                      {{ $t('managerInterviews.evaluation.buttons.save') }}
                    </Button>
                    <Button class="-grey"
                            @click.native="editMode = true"
                            v-if="!shouldEdit(state) && $store.state.group.includes('superadmin')">
                      {{ $t('managerInterviews.evaluation.buttons.edit') }}
                    </Button>
                    <Button class="-blue"
                            @click.native="onPatchInterview(state)"
                            v-if="editMode && $store.state.group.includes('superadmin')">
                      {{ $t('managerInterviews.evaluation.buttons.save') }}
                    </Button>
                  </div>
                  <div class="col-18 text-right" v-if="shouldComplete(state)">
                    <span class="mr-s">{{ $t('managerInterviews.evaluation.result.label') }}</span>
                    <InputSelect class="-dark  display-inlineblock"
                                 :options="$t(`managerInterviews.evaluation.steps.${state}.result.choices`)"
                                 v-model="$v.stateData.$model[state].recommendation">
                      <option selected disabled value="">
                        {{ $t('managerInterviews.evaluation.result.placeholder') }}
                      </option>
                    </InputSelect>
                    <Button class="-orange  -thin  ml-xs"
                            @click.native="onCompleteInterview"
                            :disabled="$v.stateData.$each[state].$invalid"
                            v-if="shouldComplete(state)">
                      {{ $t('managerInterviews.evaluation.buttons.complete') }}
                    </Button>
                  </div>
                </div>
                <div class="row mt-s" v-if="isFinished(state)">
                  <div class="col-24">
                    <h6 class="h6">{{ $t('managerInterviews.evaluation.result.label') }}</h6>
                    <Tag
                      class="tag -slim -no-margin-bottom"
                      :class="groupTagClass(stateData[state].recommendation)"
                    >
                      {{ $t(`managerInterviews.evaluation.steps.${state}.result.choices.${stateData[state].recommendation}`) }}
                    </Tag>
                  </div>
                </div>
                <div class="text-right _note" v-if="shouldComplete(state)">{{ $t('managerInterviews.evaluation.buttons.completeNote') }}</div>
              </div>
            </FormElement>
            <template v-if="state === activeState && !isAssigned && !shouldAssign">
              <div class="row  mb-s  mt-s  text-center">
                <div class="col-12">
                  <p class="font-size-l  mb-m">{{ $t('managerInterviews.assign.wait') }}</p>
                </div>
              </div>
            </template>
          </template>
        </AccordionContent>
      </template>
    </ContentBox>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minValue, helpers } from 'vuelidate/lib/validators';
import { setAssignee, saveInterview, completeInterview } from '@/api/admins.api';
import { superUserAction } from '@/api/superadmins.api';
import { possibleAssignees } from '@/values';

import Button from '@/components/atoms/Button.vue';
import Tag from '@/components/atoms/Tag.vue';

import ContentBox from '@/components/elements/ContentBox.vue';
import AccordionContent from '@/components/elements/AccordionContent.vue';

import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputRadio from '@/components/elements/inputs/InputRadio.vue';
import InputRichText from '@/components/elements/inputs/InputRichText.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';

import Icon from '@/components/atoms/Icon.vue';
import CheckmarkCircleFilledSVG from '@/assets/icons/checkmark-circle-filled.svg';
import EditSVG from '@/assets/icons/edit.svg';

const dateRegex = /^(20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const dateVuelidateRule = helpers.regex('otherDate', dateRegex);

export default {
  name: 'ManagerInterviews',
  mixins: [validationMixin],
  props: {
    status: String,
    uuid: String,
    interviews: Array,
  },
  data() {
    return {
      states: ['sighting', 'consolidation', 'decision'],
      stateData: {
        sighting: {},
        consolidation: {},
        decision: {},
      },
      selectedAssignee: '',
      assigneeList: {
        sighting: [],
        consolidation: [],
        decision: [],
      },
      assigneeEdit: false,
      userEdited: false,
      editMode: false,
      showEditAssigneeButton: false,
    };
  },
  validations: {
    stateData: {
      $each: {
        assignee: { required },
        rating: {
          $each: {
            minValue: minValue(1),
          },
        },
        date: { required, dateVuelidateRule },
        via: { required },
        recommendation: { required },
      },
    },
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),

    activeState() {
      return (this.states.indexOf(this.status) >= 0) ? this.status : false;
    },

    isCompleted() {
      return this.status === 'completed';
    },

    canAssign() {
      return this.userCan('setAssignee', this.uuid) === true;
    },

    isAssigned() {
      if (this.activeState) {
        return !!this.stateData[this.status].assignee.uuid;
      }

      return false;
    },

    shouldAssign() {
      if (this.activeState) {
        return (!this.stateData[this.status].assignee.uuid)
          && (this.canAssign);
      }

      return false;
    },
  },
  watch: {
    interviews(newVal) {
      this.initInterviews(newVal);
    },

    status(newVal) {
      if (this.activeState) {
        this.getPossibleAssignees(newVal)
          .then((assignees) => {
            this.assigneeList[this.activeState] = assignees;
          });
      }
    },
  },
  created() {
    Object.keys(this.$t('managerInterviews.evaluation.steps')).forEach((state) => {
      this.setEmptyStateData(state);
    });
  },
  beforeMount() {
    this.initInterviews(this.interviews);

    if (this.activeState) {
      this.states.forEach((state) => {
        this.getPossibleAssignees(state)
          .then((assignees) => {
            this.assigneeList[state] = assignees;
          });
      });
    }
  },
  methods: {
    initInterviews(newVal) {
      this.states.forEach((state) => {
        const interview = newVal.find((el) => el.session === state);

        if (interview) {
          this.$set(this.stateData, interview.session, {
            assignee: interview.assignee.User,
            date: (interview.date) ? interview.date.split('T')[0] : '',
            via: interview.via,
            rating: { ...this.stateData[interview.session].rating, ...interview.rating },
            notes: interview.notes,
            recommendation: (interview.recommendation === 'executiveAm') ? 'executivePartner' : interview.recommendation,
          });
        } else if (this.stateData[state].assignee?.uuid) {
          this.setEmptyStateData(state);
        }
      });
    },

    setEmptyStateData(state) {
      this.$set(this.stateData, state, {});
      this.$set(this.stateData[state], 'rating', {});
      this.$set(this.stateData[state], 'assignee', {});
      Object.keys(this.$t(`managerInterviews.evaluation.steps.${state}.inputs`)).forEach((key) => {
        this.$set(this.stateData[state].rating, key, '0');
      });
    },

    getTitleForState(state) {
      let title = this.$t(`states.${state}.title`);

      if (this.$te(`states.${state}.note`)) {
        const note = this.$t(`states.${state}.note`);

        title = `${title} ${note}`;
      }

      return title;
    },

    groupTagClass(groupType) {
      switch (groupType) {
        case 'expert':
          return '-blue';
        case 'executive':
          return '-green';
        case 'executivePartner':
          return '-orange';
        default:
          return '';
      }
    },

    shouldEdit(state) {
      return ((this.activeState === state) && (this.userCan('edit', this.stateData[this.status].assignee.uuid))) || this.editMode;
    },

    shouldComplete(state) {
      return (this.activeState === state) && (this.userCan('edit', this.stateData[this.status].assignee.uuid));
    },

    shouldBeDisabled(state) {
      const currentIndex = this.states.indexOf(this.status);
      const stateIndex = this.states.indexOf(state);

      if (this.isCompleted) {
        return stateIndex > (this.interviews.length - 1);
      }

      return stateIndex > currentIndex;
    },

    shouldBeOpen(state) {
      return this.activeState === state;
    },

    isFinished(state) {
      return !!this.interviews.find((interview) => interview.session === state)?.recommendation;
    },

    getRecommendation(state) {
      if (this.stateData[state].recommendation) {
        return this.$t(`managerInterviews.evaluation.steps.${state}.result.choices.${this.stateData[state].recommendation}`);
      }

      return '';
    },

    getPossibleAssignees(state) {
      return this.$store.dispatch('getAdminList')
        .then((adminList) => adminList.filter((admin) => admin.group.some((group) => possibleAssignees[state].includes(group))))
        .catch((error) => console.error(error));
    },

    getInputs(state) {
      const stateObj = this.$t(`managerInterviews.evaluation.steps.${state}`);
      const stateInputs = { default: stateObj.inputs };

      return stateInputs;
    },

    lastRecommendation(state) {
      const index = this.states.indexOf(state) || 0;
      const lastState = this.states[(index > 0) ? index - 1 : index];
      const lastInterview = this.interviews.find((interview) => interview.session === lastState);

      return (lastInterview) ? lastInterview.recommendation : '';
    },

    dateToGerman(dateString) {
      try {
        return (new Date(Date.parse(dateString)))
          .toLocaleDateString(
            'de-DE',
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },
          );
      } catch (e) {
        return dateString;
      }
    },

    onSetAssignee() {
      const session = this.status;
      const label = 'notifications.managerInterviews.created.message.label';

      this.setAssignee(session, label);
    },

    changeAssignee(session) {
      const label = 'notifications.managerInterviews.saved.message.label';

      this.setAssignee(session, label);
    },

    setAssignee(session, label) {
      const assignee = this.stateData[session].assignee.uuid || this.selectedAssignee;

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          setAssignee(this.uuid, {
            assignee,
            session,
          }, token)
            .then(() => {
              this.assigneeEdit = false;
              this.selectedAssignee = '';
              this.$emit('update');
              this.$eventBus.$emit('notificate', { message: this.$t(label) });
            })
            .catch((error) => {
              console.error(error.response);
              this.$eventBus.$emit('notificate', { message: error.response.message, status: error.response.status });
            });
        });
    },

    async onSaveInterview() {
      const {
        date, notes, rating, via, assignee,
      } = this.stateData[this.status];

      return new Promise((resolve, reject) => {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            saveInterview(this.uuid, {
              session: this.status,
              assignee: assignee.uuid,
              date: date || null,
              notes,
              rating,
              via,
            }, token)
              .then(() => {
                this.userEdited = false;
                this.$eventBus.$emit('notificate', { message: this.$t('notifications.managerInterviews.saved.message.interviews') });
                resolve();
              })
              .catch((error) => {
                console.error(error.response);
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
                reject();
              });
          });
      });
    },

    async onCompleteInterview() {
      this.$v.stateData.$each[this.status].$touch();

      if (!this.$v.stateData.$each[this.status].$invalid) {
        if (this.userEdited) {
          await this.onSaveInterview();
        }

        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            completeInterview(this.uuid, {
              session: this.status,
              assignee: this.stateData[this.status].assignee.uuid,
              recommendation: this.stateData[this.status].recommendation,
            }, token)
              .then(() => {
                this.$emit('update');
                this.$eventBus.$emit('notificate', {
                  title: this.$t('notifications.managerInterviews.saved.valuation.title'),
                  message: this.$t('notifications.managerInterviews.saved.valuation.message'),
                });
              })
              .catch((error) => {
                console.error(error.response);
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              });
          });
      }
    },

    onPatchInterview(session) {
      const {
        assignee, date, notes, rating, via,
      } = this.stateData[session];

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          superUserAction('patchInterview', {
            id: this.uuid,
            payload: {
              assignee: assignee.uuid,
              fields: {
                date,
                notes,
                rating,
                via,
              },
            },
          }, token)
            .then(() => {
              this.userEdited = false;
              this.editMode = false;
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              console.error(error.response);
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    AccordionContent,
    Button,
    ContentBox,
    FormElement,
    InputText,
    InputRadio,
    InputRichText,
    InputSelect,
    CheckmarkCircleFilledSVG,
    Icon,
    Tag,
    EditSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-interviews.scss"></style>
