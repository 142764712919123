import { mapGetters } from 'vuex';
import { superUserAction } from '@/api/superadmins.api';
import { createUser, patchUser, removeUser } from '@/api/admins.api';

export default {
  computed: {
    ...mapGetters([
      'userCan',
    ]),
  },
  methods: {
    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t('superadmins.confirm')} ${this.$t(`superadmins.actions.${action}`)}`);
    },

    redirectTimeout(routeName = 'Users') {
      setTimeout(() => {
        if (this.redirectTimer > 1) {
          this.redirectTimer -= 1;
          this.redirectTimeout(routeName);
        } else {
          this.$router.push({ name: routeName, lang: this.$i18n.locale });
        }
      }, 1000);
    },

    handleDelete() {
      if (!this.confirmAction('deleteUser')) {
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          removeUser(this.uuid, token)
            .then(() => {
              if (this.redirect) {
                this.$eventBus.$emit('notificate', { title: this.$t('notifications.actionsNavAdmin.deleted.title'), message: this.$t('notifications.actionsNavAdmin.deleted.message.withRedirect') });
                this.redirectTimeout(this.$route.meta?.redirectParent || undefined);
              } else {
                this.$eventBus.$emit('notificate', { title: this.$t('notifications.actionsNavAdmin.deleted.title'), message: this.$t('notifications.actionsNavAdmin.deleted.message.label') });
                this.$emit('update');
              }
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    patchActive(active) {
      if (!this.confirmAction((active) ? 'setActive' : 'setInactive')) {
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchUser(this.uuid, { active }, token)
            .then(() => {
              if (this.redirect) {
                this.$eventBus.$emit('notificate', { message: this.$t('notifications.actionsNavAdmin.deactivated.message.withRedirect') });

                this.redirectTimeout();
              } else {
                this.$eventBus.$emit('notificate', { message: this.$t('notifications.actionsNavAdmin.deactivated.message.label') });

                this.$emit('update');
              }
            })

            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    handleSuperAdminActions(action, confirm = true, payload = {}) {
      if (confirm) {
        if (!this.confirmAction(action)) {
          return;
        }
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          superUserAction(action, {
            id: this.uuid,
            payload,
          }, token)
            .then(() => {
              this.$emit('update');
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    handleCreateAdmin(admin) {
      if (!this.userCan('createAdmins')) {
        this.$eventBus.$emit('notificate', { status: 401 });
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          createUser(admin, token)
            .then(this.handleApiSuccess)
            .catch(this.handleApiError);
        });
    },

    handleDeleteAdmin(uuid) {
      if (!this.userCan('deleteAdmins')) {
        this.$eventBus.$emit('notificate', { status: 401 });
        return;
      }

      if (!this.confirmAction('deleteAdmin')) {
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          removeUser(uuid, token)
            .then(() => {
              this.$eventBus.$emit('notificate', { title: this.$t('notifications.adminList.deleted.title'), message: this.$t('notifications.adminList.deleted.message') });
              this.$emit('update');
              this.closeModal();
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },

    handleEditAdmin(uuid, fields) {
      if (!this.userCan('editAdmins')) {
        this.$eventBus.$emit('notificate', { status: 401 });
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchUser(uuid, { role: 'admin', ...fields }, token)
            .then(this.handleApiSuccess)
            .catch(this.handleApiError);
        });
    },
  },
};
