import { render, staticRenderFns } from "./LeadStatus.vue?vue&type=template&id=042ba376&scoped=true&"
import script from "./LeadStatus.vue?vue&type=script&lang=js&"
export * from "./LeadStatus.vue?vue&type=script&lang=js&"
import style0 from "@/sass/06_atoms/lead-status.scss?vue&type=style&index=0&id=042ba376&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042ba376",
  null
  
)

export default component.exports