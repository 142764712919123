<template>
  <Modal id="managerSelectModal" class="manager-select-modal -thin" :title="$t('projectCampaign.title')" :defaultOpen="false">
    <p class="font-weight-medium font-size-md">{{ $t('projectCampaign.intro') }}</p>

    <div class="_filter">
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.availability"
                     :options="getFilterOptions('availability')"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('availability', $event)"
                     @select="handleFilter('availability', $event)">
          <template slot="option" slot-scope="{ option }">
            <span>{{ $t(`usersList.filter.inputs.availability.options.${option}`) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.availability.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.industries"
                     ref="industriesMultiselect"
                     :options="industriesOptions"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('industries', $event)"
                     @select="handleFilter('industries', $event)">
          <template slot="beforeList">
            <div class="_option-search" v-if="false">
              <InputText
                name="industriesSearch"
                base="usersList.filter"
                class="_search  -light"
                v-model="industriesSearch"
                type="text">
                <Icon class="-size-18"><SearchSVG></SearchSVG></Icon>
              </InputText>
            </div>
          </template>
          <template slot="option" slot-scope="{ option }">
            <span :class="[ option.split('_').length > 1 ? '_sub' : '_main' ]">{{ getCategoryLabel('industries', option) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.industries.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.operationals"
                     ref="operationalsMultiselect"
                     :options="operationalsOptions"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('operationals', $event)"
                     @select="handleFilter('operationals', $event)">
          <template slot="beforeList">
            <div class="_option-search" v-if="false">
              <InputText
                name="operationalsSearch"
                base="usersList.filter"
                class="_search  -light"
                v-model="operationalsSearch"
                type="text">
                <Icon class="-size-18"><SearchSVG></SearchSVG></Icon>
              </InputText>
            </div>
          </template>
          <template slot="option" slot-scope="{ option }">
            <span :class="[ option.split('_').length > 1 ? '_sub' : '_main' ]">{{ getCategoryLabel('operationals', option) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.operationals.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.group"
                     :options="getFilterOptions('groups')"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('group', $event)"
                     @select="handleFilter('group', $event)">
          <template slot="option" slot-scope="{ option }">
            <span>{{ $t(`projectCampaign.filter.inputs.groups.options.${option}`) }}</span>
          </template>
          <template slot="selection">
            {{ $t('projectCampaign.filter.inputs.groups.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
    </div>

    <div class="row  mb-s">
      <div class="col-24">
        <div class="_filter-info">
          <div class="mb-xs">
            {{ $t('projectCampaign.selectedFilters') }} <a v-if="isFiltered" class="_remove-all ml-s" @click="handleRemoveAll">{{ $t('usersList.filter.removeAll') }}</a>
          </div>
          <InputTag class="-light"
                    v-for="tag in getTags('availability')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('availability', $event)">
            {{ $t(`usersList.filter.inputs.availability.options.${tag}`) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="tag in getTags('group')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('group', $event)">
            {{ $t(`projectCampaign.filter.inputs.groups.options.${tag}`) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="(tag, index) in getTags('industries')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('industries', $event)">
            <template v-if="index === 0">{{ $t('usersList.filter.inputs.industries.label') }}:</template>
            {{ getCategoryLabel('industries', tag) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="(tag, index) in getTags('operationals')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('operationals', $event)">
            <template v-if="index === 0">{{ $t('usersList.filter.inputs.operationals.label') }}:</template>
            {{ getCategoryLabel('operationals', tag) }}
          </InputTag>
        </div>
      </div>
    </div>
    <template slot="footer">
      <div class="display-flex align-items-center">
        <div class="_results">
          <strong class="font-size-xl">{{ $tc('projectCampaign.selectedRecipients', selectedManagers)}}</strong>
          <template v-if="loading">
            <Icon class="-size-20 ml-xxs"><LoadingSVG></LoadingSVG></Icon>
          </template>
        </div>
        <Button class="mr-s -orange -outlined" @click.native="closeModal">
          {{ $t('projectCampaign.buttons.cancel') }}
        </Button>
        <Button class="-orange"
                @click.native="sendCampaign"
                :disabled="sendingCampaignDisabled"
        >
        <template v-if="!sending">
          {{ $t('projectCampaign.buttons.save') }}
        </template>
        <template v-else>
          {{ $t('projectCampaign.buttons.sending') }}
        </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>
<script>
import _ from 'lodash';
import Multiselect from 'vue-multiselect';

import { sendCampaign } from '@/api/projects.api';

import Modal from '@/components/elements/Modal.vue';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import InputTag from '@/components/elements/inputs/InputTag.vue';

import {
  arrayUtils,
  queryObjects,
} from '@/mixins';
import { listUsers } from '@/api/admins.api';

export default {
  name: 'Campaign',
  mixins: [arrayUtils, queryObjects],
  props: {
    project: Object,
  },
  data() {
    const initialValues = this.getInitialFilterValuesFromProject();

    return {
      loading: false,
      sending: false,
      debounce: null,
      selectedFilter: {
        ...initialValues,
      },
      filterOptions: {},
      selectedManagers: 0,
      complexFilterOptions: {
        group: {
          expert: { group: { eq: 'expert' }, partner: { eq: '0' } },
          executive: { group: { eq: 'executive' }, partner: { eq: '0' } },
          partner: { group: { eq: 'executive' }, partner: { eq: '1' } },
        },
        availability: {
          available: { availability: { eq: true }, admin_status: { eq: null } },
          notAvailable: { availability: { eq: false }, availability_reason: { or: [{ eq: null }, { eq: 'none' }] }, admin_status: { eq: null } },
          inProject: { availability: { eq: false }, availability_reason: { eq: 'inProject' }, admin_status: { eq: null } },
          inEmployment: { availability: { eq: false }, availability_reason: { eq: 'inEmployment' }, admin_status: { eq: null } },
          retired: { availability: { eq: false }, availability_reason: { eq: 'retired' }, admin_status: { eq: null } },
          blacklisted: { admin_status: { eq: 'blacklisted' } },
          check: { admin_status: { eq: 'check' } },
        },
      },
      defaultFilter: {
        status: 'completed', // TODO: Confirm that this is right
      },
    };
  },
  mounted() {
    this.$eventBus.$on('open-modal-managerSelectModal', () => {
      this.resetFilter(this.getInitialFilterValuesFromProject());
      this.fetchUsers();
    });
  },
  beforeUnmount() {
    this.$eventBus.$off('open-modal-managerSelectModal');
  },
  computed: {
    industriesOptions() {
      return this.getCategoryOptions('industries', this.industriesSearch);
    },

    operationalsOptions() {
      return this.getCategoryOptions('operationals', this.operationalsSearch);
    },

    sendingCampaignDisabled() {
      return this.selectedManagers === 0 || this.loading || this.sending;
    },

    isFiltered() {
      const filterKeys = Object.keys(this.selectedFilter);

      if (filterKeys.length === 0) {
        return false;
      }

      return filterKeys
        .reduce((acc, key) => {
          acc.push(this.selectedFilter[key].length > 0);
          return acc;
        }, [])
        .filter((bool) => bool).length > 0;
    },
  },
  methods: {
    sendCampaign() {
      // eslint-disable-next-line
      if (!window.confirm(this.$t('projectCampaign.confirm.sendCampaign', { n: this.selectedManagers }))) {
        return;
      }

      this.sending = true;

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          sendCampaign(this.project.id, this.getFilterQuery(), token)
            .then((response) => {
              const { success, error } = response.data.mailsSend;

              this.$eventBus.$emit('notificate', {
                title: this.$tc('notifications.adminProjectDetail.sendCampaign.success', success),
                message: (error) ? this.$tc('notifications.adminProjectDetail.sendCampaign.failed', error) : '',
              });

              this.sending = false;
              this.closeModal();
              this.$emit('update', {
                success,
                error,
              });
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', {
                message: error.response.data.message,
                status: error.response.status,
              });

              this.sending = false;
            });
        });
    },

    getInitialFilterValuesFromProject() {
      return {
        availability: ['available'],
        group: ['expert'],
        industries: this.project.main_industry ? [this.project.main_industry] : [],
        operationals: [...(this.project.main_operationals || [])],
      };
    },

    resetFilter(initialFilterValues) {
      this.$set(this.selectedFilter, 'availability', initialFilterValues.availability);
      this.$set(this.selectedFilter, 'industries', initialFilterValues.industries);
      this.$set(this.selectedFilter, 'operationals', initialFilterValues.operationals);
    },

    getFilterOptions(filter) {
      if (['industries', 'operationals'].includes(filter)) {
        const categories = this.$t(`categories.${filter}`);

        return Object.keys(categories.sub).flatMap((main) => [
          main,
          ...Object.keys(categories.sub[main]).map((sub) => `${main}_${sub}`),
        ]);
      }

      if (filter === 'groups') {
        return Object.keys(this.$t(`projectCampaign.filter.inputs.${filter}.options`));
      }

      return Object.keys(this.$t(`usersList.filter.inputs.${filter}.options`));
    },

    getCategoryOptions(category, search = '') {
      const categories = this.$t(`categories.${category}`);
      const searchRegex = new RegExp(search, 'i');

      return Object.keys(categories.sub).flatMap((mainKey) => [
        ...((categories.main[mainKey].match(searchRegex) || Object.values(categories.sub[mainKey]).find((el) => el.match(searchRegex))) ? [mainKey] : []),
        ...Object.keys(categories.sub[mainKey]).filter((subKey) => categories.sub[mainKey][subKey].match(searchRegex)).map((subKey) => `${mainKey}_${subKey}`),
      ]);
    },

    getFilterQuery() {
      let scope = 'campaignRecipients';
      const filter = this.getFilter();

      if (this.selectedFilter.availability?.includes('inactive')) {
        delete filter.status;
        scope = 'inactiveUsersList';
      }

      return {
        role: 'manager',
        ...this.buildFilterQuery(filter, this.filterOptions),
        scope,
      };
    },

    getFilter() {
      const {
        availability,
        industries,
        operationals,
        group,
        ...filter
      } = this.selectedFilter;

      const multiSelectFilter = {
        ...(availability && availability.length > 0) && { availability },
        ...(group && group.length > 0) && { group },
      };

      return {
        ...this.defaultFilter,
        ...(!!industries || !!operationals || Object.keys(multiSelectFilter).length > 0) && {
          query: {
            and: [
              ...((industries) ? this.buildCategoryFilter('industries', industries) : []),
              ...((operationals) ? this.buildCategoryFilter('operationals', operationals) : []),
              ...((Object.keys(multiSelectFilter).length > 0) ? this.buildMultiSelectFilter(multiSelectFilter) : []),
            ],
          },
        },
        ...this.buildComplexFilter(filter),
      };
    },

    buildMultiSelectFilter(inputs) {
      const filterArgs = [];

      Object.keys(inputs).forEach((arg) => {
        const filterValues = (Array.isArray(inputs[arg])) ? [...inputs[arg]] : [inputs[arg]];
        const filterGroupArgs = { or: [] };

        if (this.complexFilterOptions[arg]) {
          filterValues.forEach((value) => {
            if (this.complexFilterOptions[arg][value]) {
              filterGroupArgs.or.push({ ...this.complexFilterOptions[arg][value] });
            }
          });
        } else {
          filterGroupArgs.or.push(filterValues);
        }

        if (filterGroupArgs.or.length > 0) filterArgs.push(filterGroupArgs);
      });

      return filterArgs;
    },

    buildCategoryFilter(category, input = []) {
      const main = input.filter((el) => !el.includes('_'));
      const sub = input.filter((el) => el.includes('_'));

      return (input.length > 0) ? [{
        and: [
          ...main.map((arg) => ({
            [`main_${category}`]: {
              regexp: arg,
            },
          })),
          ...sub.map((arg) => ({
            [`sub_${category}`]: {
              regexp: arg,
            },
          })),
        ],
      }] : [];
    },

    buildComplexFilter(inputs) {
      let filterArgs = {};

      Object.keys(inputs).forEach((arg) => {
        const filterValues = inputs[arg];

        if (this.complexFilterOptions[arg]) {
          if (Array.isArray(inputs[arg])) {
            filterValues.forEach((value) => {
              Object.keys(this.complexFilterOptions[arg][value] || {}).forEach((complexArg) => {
                filterArgs[complexArg] = [...new Set([...filterArgs[complexArg] || [], ...[this.complexFilterOptions[arg][value][complexArg]]])];
              });
            });
          } else {
            filterArgs = { ...filterArgs, ...this.complexFilterOptions[arg] };
          }
        } else {
          filterArgs[arg] = filterValues;
        }
      });

      return filterArgs;
    },

    getAvailabilityKey(user) {
      if (user.admin_status) return user.admin_status;
      if (user.availability) return 'available';
      if (!user.availability_reason || user.availability_reason === 'none') return 'notAvailable';

      return user.availability_reason;
    },

    getCategoryLabel(category, value = '') {
      const split = value.split('_');

      if (split.length > 1) {
        return this.$t(`categories.${category}.sub.${split[0]}.${split[1]}`);
      }

      return this.$t(`categories.${category}.main.${split[0]}`);
    },

    getTags(filter) {
      return _.concat([], this.selectedFilter[filter] ?? []);
    },

    handleRemoveSelected(field, value) {
      this.$set(this.selectedFilter, field, this.removeFromArray(this.selectedFilter[field], value));
      this.fetchUsers();
    },

    handleRemoveAll() {
      this.selectedFilter = {};
      this.fetchUsers();
    },

    handleFilter() {
      clearTimeout(this.debounce);

      this.loading = true;
      this.debounce = setTimeout(() => {
        this.fetchUsers();
      }, 1000);
    },

    fetchUsers() {
      this.loading = true;
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          listUsers(this.getFilterQuery(), token)
            .then((response) => response.data)
            .then((data) => {
              this.selectedManagers = data.data.count;
              this.loading = false;
            });
        });
    },

    closeModal() {
      this.$eventBus.$emit('close-modal-managerSelectModal');
      this.resetFilter(this.getInitialFilterValuesFromProject());
    },
  },
  components: {
    Modal,
    Multiselect,
    Button,
    Icon,
    InputTag,
    LoadingSVG: () => import('@/assets/icons/loader.svg'),
    ChevronSVG: () => import('@/assets/icons/chevron.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-select-modal.scss"></style>
<style lang="scss" src="@/sass/07_elements/multiselect.scss"></style>
