<template>
  <section class="user-categories">
    <ContentBox>
      <h4 class="h4 subheadline font-component-headline">{{ $t('userCategories.headline') }}</h4>
      <div class="row  display-flex">
        <div class="col-12  col-sm-24" v-for="(categoryType, index) in categoryTypes" :key="categoryType">
          <ContentBox :class="[{ 'mb-sm-s': index === 0 }]">
            <div class="row">
              <div class="col-20">
                <h6 class="h6  font-weight-medium  mb-xs" :class="{'font-size-l': $matchMedia.sm}">{{ $t(`userCategories.${categoryType}.headline`) }}</h6>
              </div>
              <div class="col-4  text-right">
                <Button class="-outlined  -light  pos-relative  -no-padding  -size-32" @click.native="$eventBus.$emit(`open-modal-userCategories-${categoryType}`)" v-if="canEdit">
                  <Icon class="-inline  -pos-absolute-center"><EditSVG></EditSVG></Icon>
                </Button>
              </div>
            </div>
            <div class="row">
              <div class="col-24">
                <List class="main-categories" v-if="categories[`main_${categoryType}`].length > 0">
                  <li v-for="(subs, main) in getCategories(categoryType)" :key="main">
                    {{ $t(`categories.${categoryType}.main.${main}`) }}
                    <ul :bullets="false" v-if="subs.length > 0" class="sub-categories">
                      <li v-for="sub in subs" :key="sub">
                        <Icon class="-inline  color-blue-200"><SubSVG></SubSVG></Icon>
                        {{ $t(`categories.${categoryType}.sub.${main}.${splitKeys(sub)[1]}`) }}
                      </li>
                    </ul>
                  </li>
                </List>
                <p v-else>{{ $t(`userCategories.${categoryType}.empty`) }}</p>
              </div>
            </div>
          </ContentBox>
        </div>
      </div>
    </ContentBox>
    <template v-if="canEdit">
      <Modal v-for="categoryType in categoryTypes" :key="categoryType"
             class="-thin"
             :id="`userCategories-${categoryType}`"
             :title="$t(`userCategories.${categoryType}.modal.title`)">
        <div class="site-inner">
          <p class="mb-s">{{ $t(`userCategories.${categoryType}.modal.paragraph`) }}</p>
          <ContentBox>
            <ul class="main-select-list">
              <li v-for="(maincategory, mainkey) in $t(`categories.${categoryType}.main`)" :key="mainkey">
                <InputCheckbox :inputValue="mainkey"
                               class="-medium"
                               :name="mainkey"
                               @input="handleMainSelected(mainkey, categoryType)"
                               v-model="selected[`main_${categoryType}`]">
                  <template slot="label">
                    {{ maincategory }}
                  </template>
                </InputCheckbox>
                <ul class="sub-select-list" v-if="Object.keys($t(`categories.${categoryType}.sub.${mainkey}`)).length > 0">
                  <li v-for="(subcategory, subkey) in $t(`categories.${categoryType}.sub.${mainkey}`)" :key="subkey">
                    <InputCheckbox :inputValue="`${mainkey}_${subkey}`"
                                   class="-medium"
                                   :name="`${mainkey}_${subkey}`"
                                   @input="handleSubSelected(mainkey, categoryType)"
                                   v-model="selected[`sub_${categoryType}`]">
                      <template slot="label">
                        {{ subcategory }}
                      </template>
                    </InputCheckbox>
                  </li>
                </ul>
              </li>
            </ul>
          </ContentBox>
        </div>
        <template slot="footer">
          <div class="text-right" :key="categoryType">
            <Button class="button -orange -outlined mr-s" @click.native="handleCancel(categoryType)">
              {{ $t('userCategories.buttons.cancel') }}
            </Button>
            <Button class="-orange" @click.native="handleSubmit(categoryType)">
              {{ $t('userCategories.buttons.save') }}
            </Button>
          </div>
        </template>
      </Modal>
    </template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { updateUser } from '@/api/users.api';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import List from '@/components/atoms/List.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import Modal from '@/components/elements/Modal.vue';

import EditSVG from '@/assets/icons/edit.svg';
import SubSVG from '@/assets/icons/keyboard-return.svg';

export default {
  name: 'UserCategories',
  props: {
    userUuid: String,
    categories: Object,
  },
  data() {
    return {
      categoryTypes: ['operationals', 'industries'],
      selected: {
        main_operationals: [],
        sub_operationals: [],
        main_industries: [],
        sub_industries: [],
      },
      userEdited: false,
    };
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),

    canEdit() {
      return this.userCan('edit', this.userUuid) || this.userCan('updateUserCategories');
    },
  },
  mounted() {
    this.initOperationals();
    this.initIndustries();
  },
  methods: {
    initOperationals() {
      this.selected = {
        ...this.selected,
        main_operationals: (this.categories.main_operationals.length > 0) ? [...this.categories.main_operationals] : [],
        sub_operationals: (this.categories.sub_operationals.length > 0) ? [...this.categories.sub_operationals] : [],
      };
    },

    initIndustries() {
      this.selected = {
        ...this.selected,
        main_industries: (this.categories.main_industries.length > 0) ? [...this.categories.main_industries] : [],
        sub_industries: (this.categories.sub_industries.length > 0) ? [...this.categories.sub_industries] : [],
      };
    },

    getCategories(target) {
      const categories = {};

      this.categories[`main_${target}`].forEach((key) => {
        if (this.categories[`sub_${target}`].length > 0) {
          categories[key] = this.categories[`sub_${target}`].filter((val) => val.includes(key));
        } else {
          categories[key] = [];
        }
      });

      return categories;
    },

    splitKeys(subkey) {
      return subkey.split('_');
    },

    handleSubSelected(value, target) {
      // on sub category select, auto select main category
      this.userEdited = true;
      if (!this.selected[`main_${target}`].includes(value)) {
        this.selected[`main_${target}`].push(value);
      }
    },

    handleMainSelected(value, target) {
      // on main category deselect, auto deselect all sub categories
      this.userEdited = true;
      if (!this.selected[`main_${target}`].includes(value)) {
        this.$set(this.selected, `sub_${target}`, this.selected[`sub_${target}`].filter((el) => !el.includes(value)));
      }
    },

    handleCancel(target) {
      this.userEdited = false;
      this.$eventBus.$emit(`close-modal-userCategories-${target}`);

      if (target === 'operationals') this.initOperationals();
      else this.initIndustries();
    },

    handleSubmit(target) {
      if (this.userEdited && this.canEdit) {
        this.updateCategories(target);
      }
    },

    updateCategories(target) {
      this.updateStatus = 'PENDING';
      const payload = {
        [`main_${target}`]: this.selected[`main_${target}`],
        [`sub_${target}`]: this.selected[`sub_${target}`],
      };

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          updateUser(this.userUuid, payload, token)
            .then(() => {
              this.updateStatus = 'OK';
              this.userEdited = false;
              this.$emit('update');
              this.$eventBus.$emit('notificate');
              this.$eventBus.$emit(`close-modal-userCategories-${target}`);
            })
            .catch((error) => {
              this.updateStatus = 'ERROR';
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    Button,
    Icon,
    List,
    ContentBox,
    InputCheckbox,
    Modal,
    EditSVG,
    SubSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-categories.scss"></style>
