<template>
  <ContentBox>
    <div class="row mt-xxs mb-xs">
      <div class="col-20">
        <h5 class="h5 font-weight-medium font-component-headline" :class="{ 'font-size-l': $matchMedia.sm }" v-html="$t('leadInfos.headline')"></h5>
      </div>
      <div class="col-4 text-right">
        <Button class="-outlined -light pos-relative -no-padding -size-32" @click.native="$eventBus.$emit('open-modal-leadModalEdit')">
          <Icon class="-inline -pos-absolute-center">
            <EditSVG></EditSVG>
          </Icon>
        </Button>
      </div>
    </div>

    <div class="font-size-l font-weight-medium mb-xs">
      <Icon class="mr-xxs"><TagSVG /></Icon> {{ lead.custom_id }}
    </div>

    <div class="font-size-l font-weight-medium mb-xs text-truncate">
      <Icon class="mr-xxs"><FileSVG /></Icon> {{ lead.title }}
    </div>

    <div class="font-size-l font-weight-medium mb-xs">
      <Icon class="mr-xxs"><IndustrySVG /></Icon> {{ lead.client }}
    </div>

    <div class="font-size-l font-weight-medium mb-xs">
      <Icon class="mr-xxs"><BusinessCenterSVG /></Icon> {{ $t(`projectInternals.inputs.pc.values.${lead.profit_center}`) }}
    </div>

    <div class="font-size-l font-weight-medium mb-xs">
      <Icon class="mr-xxs"><AccountSVG /></Icon>
      <template v-if="lead.responsible">
        {{ lead.responsible.User.lastname }}, {{ lead.responsible.User.firstname }}
      </template>
      <template v-else>
        {{ $t('comments.deleted_user') }}
      </template>
    </div>

    <div class="mt-m">
      <div class="sub-headline">{{ $t('leadInfos.status') }}</div>
      <InputSelect base="leadInfos" name="status" :value="lead.status" @input="handleStatusChange">
        <template v-slot:options>
          <option v-for="(option, key) in $t('leadStatus')" :value="key" :key="key">{{ option.label }}</option>
        </template>
      </InputSelect>
    </div>

    <div class="mt-m">
      <div clasS="sub-headline">{{ $t('leadInfos.actions') }}</div>
      <ul class="list">
        <li v-if="lead.project_id" class="display-block">
          <Link class="-orange" @click.native="showRelatedProject(lead.project_id)">
            <Icon class="mr-xxs -inline -is-left">
              <ContentPasteSVG></ContentPasteSVG>
            </Icon>
            <span>{{ $t('leadActions.actions.showRelatedProject') }}</span>
          </Link>
        </li>

        <li v-else class="display-block">
          <Link class="-orange" @click.native="createProjectFromLead(lead)">
            <Icon class="mr-xxs -inline -is-left">
              <ContentPasteSVG></ContentPasteSVG>
            </Icon>
            <span>{{ $t('leadActions.actions.createProjectFromLead') }}</span>
          </Link>
        </li>

        <li class="display-block">
          <Link class="-orange" @click.native="handleDeleteLead">
            <Icon class="mr-xxs -inline -is-left">
              <TrashSVG></TrashSVG>
            </Icon>
            <span>{{ $t('leadActions.actions.delete') }}</span>
          </Link>
        </li>
      </ul>
    </div>

    <div class="mt-m">
      <div clasS="sub-headline mb-0">{{ $t('leadInfos.updatedAt') }}</div>
      <div class="font-size-l font-weight-medium">{{ lead.updatedAt | formatDate }} | {{ lead.updatedAt | formatTime }} {{ $t('dateTime.time.clock') }}</div>
    </div>
    <LeadModal base="leadModalEdit" @update="$emit('update')" :lead="lead" />
  </ContentBox>
</template>

<script>
import ContentBox from '@/components/elements/ContentBox.vue';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Link from '@/components/atoms/Link.vue';
import EditSVG from '@/assets/icons/edit.svg';
import FileSVG from '@/assets/icons/file.svg';
import ContentPasteSVG from '@/assets/icons/content-paste.svg';
import IndustrySVG from '@/assets/icons/industry.svg';
import BusinessCenterSVG from '@/assets/icons/business_center.svg';
import AccountSVG from '@/assets/icons/account.svg';
import TagSVG from '@/assets/icons/tag.svg';
import TrashSVG from '@/assets/icons/trash.svg';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import LeadModal from '@/components/modules/Lead/admin/LeadModal.vue';
import { parseDates, leadActions } from '@/mixins';

export default {
  name: 'LeadInfos',
  props: {
    id: Number,
    lead: Object,
  },
  mixins: [parseDates, leadActions],
  methods: {
    handleDeleteLead() {
      if (this.handleDelete(false) !== false) {
        this.$router.push({ name: 'Leads', params: { lang: this.$i18n.locale } });
      }
    },
  },
  components: {
    ContentBox,
    Button,
    Icon,
    EditSVG,
    FileSVG,
    ContentPasteSVG,
    IndustrySVG,
    BusinessCenterSVG,
    AccountSVG,
    TagSVG,
    InputSelect,
    TrashSVG,
    Link,
    LeadModal,
  },
};
</script>
