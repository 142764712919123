<template>
  <a :class="['flyout-trigger', { 'is-active': flyoutOpen }]" @click.prevent="localEl.$emit('toggle', $event)">
    <slot>
      <Icon class="-size-32"><DotsSVG></DotsSVG></Icon>
    </slot>
  </a>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';
import DotsSVG from '@/assets/icons/dots.svg';

export default {
  name: 'FlyoutTrigger',
  props: {
    flyoutEl: [Object, String],
    parentRefs: [Object],
  },
  data() {
    return {
      flyoutOpen: false,
      localEl: this.flyoutEl,
    };
  },
  mounted() {
    if (typeof this.flyoutEl === 'string') {
      try {
        [this.localEl] = this.parentRefs[this.flyoutEl];
      } catch {
        this.localEl = this.$parent.$refs[this.flyoutEl];
      }
    }

    this.localEl.$on('flyout-change', this.handleFlyoutChange);
  },
  methods: {
    handleFlyoutChange(state) {
      this.flyoutOpen = state;
    },
  },
  components: {
    Icon,
    DotsSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/flyout-trigger.scss"></style>
