<template>
  <section class="project-internals">
    <ContentBox>
      <FormElement>
        <div class="row  mb-m">
          <div class="col-6  col-xs-12  mb-xs-xs">
            <span class="font-size-s  mr-xxs">{{ $t('projectInternals.inputs.pc.label') }}:</span>
            <InputSelect alt="small"
                         :class="['display-inlineblock', { '-has-error': $v.projectInternals.pc.$error }]"
                         name="pc"
                         base="projectInternals"
                         :disabled="!editMode"
                         :options="$t('projectInternals.inputs.pc.values')"
                         @input="userEdited = true"
                         v-model="$v.projectInternals.pc.$model">
              <option disabled selected value="">{{ $t('projectInternals.inputs.pc.placeholder') }}</option>
            </InputSelect>
          </div>
          <div class="col-10  col-xs-12  mb-xs-xs">
            <span class="font-size-s  mr-xxs">{{ $t('projectInternals.inputs.assignee.label') }}:</span>
            <InputSelect alt="small"
                         :class="['display-inlineblock', { '-has-error': $v.projectInternals.assignee.$error }]"
                         name="assignee"
                         base="projectInternals"
                         :disabled="!editMode"
                         @input="userEdited = true"
                         v-model="$v.projectInternals.assignee.$model">
              <option disabled selected value="">{{ $t('projectInternals.inputs.assignee.placeholder') }}</option>
              <template v-slot:options>
                <option v-for="admin in assigneeList" :key="admin.uuid" :value="admin.uuid" :selected="projectInternals.assignee === admin.uuid">{{ admin.firstname }} {{ admin.lastname }}</option>
              </template>
            </InputSelect>
          </div>
        </div>
        <div class="row">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectInternals.inputs.notes.headline') }}</h6>
            <InputRichText name="notes"
                           :class="{ '-has-error': $v.projectInternals.notes.$error }"
                           :disabled="!editMode"
                           @input="userEdited = true"
                           v-model="$v.projectInternals.notes.$model"></InputRichText>
          </div>
        </div>
      </FormElement>
      <div class="_footer">
        <hr class="-grey-200  mb-s">
        <div class="row">
          <div class="col-24  text-right">
            <Button v-if="editMode" class="-orange  -thin" @click.native="toggleEditMode">{{ $t('projectContent.buttons.save') }}</Button>
            <Button v-if="!editMode" class="-grey  -thin" @click.native="toggleEditMode">{{ $t('projectContent.buttons.edit') }}</Button>
            <Button class="-grey  -thin  ml-xs" @click.native="cancelEdit" v-if="editMode && !init">
              <Icon class="-size-20"><CloseSVG></CloseSVG></Icon>
            </Button>
          </div>
        </div>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { patchProject } from '@/api/projects.api';
import { possibleAssignees } from '@/values';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputRichText from '@/components/elements/inputs/InputRichText.vue';

import CloseSVG from '@/assets/icons/close.svg';

export default {
  name: 'ProjectInternals',
  mixins: [validationMixin],
  props: {
    project: Object,
    init: Boolean,
  },
  data() {
    return {
      editMode: this.init,
      userEdited: this.init,
      assigneeList: [],
      projectInternals: {},
    };
  },
  watch: {
    project() {
      this.initInternals();
    },
  },
  validations: {
    projectInternals: {
      pc: { required },
      notes: {},
      assignee: { required },
    },
  },
  mounted() {
    this.initInternals();
    this.getPossibleAssignees();
  },
  methods: {
    initInternals() {
      this.projectInternals = {
        pc: this.project.pc,
        notes: this.project.notes,
        assignee: this.project.assignee,
      };
    },

    getPossibleAssignees() {
      this.$store.dispatch('getAdminList')
        .then((adminList) => {
          this.assigneeList = adminList.filter((admin) => admin.group.some((group) => possibleAssignees.projects.includes(group)));
        })
        .catch((error) => console.error(error));
    },

    toggleEditMode() {
      if (!this.editMode) {
        this.editMode = true;
      } else if (this.userEdited) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.updateProjectInternals();
        }
      } else {
        this.editMode = false;
      }
    },

    cancelEdit() {
      if (this.userEdited) {
        this.initInternals();
      }

      this.editMode = false;
    },

    updateProjectInternals() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchProject(this.project.id, this.projectInternals, token)
            .then(() => {
              this.editMode = false;
              this.userEdited = false;
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    Button,
    Icon,
    ContentBox,
    FormElement,
    InputSelect,
    InputRichText,
    CloseSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-internals.scss"></style>
