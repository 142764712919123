<template>
  <section class="project-content">
    <ContentBox>
      <FormElement>
        <div class="row  mb-m">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.intro.headline') }}</h6>
            <InputRichText name="intro"
                           :disabled="!editMode"
                           :class="{ '-has-error': $v.projectContent.intro.$error }"
                           @input="userEdited = true"
                           v-model="$v.projectContent.intro.$model" />
          </div>
        </div>
        <div class="row  mb-m">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.description.headline') }}</h6>
            <InputRichText name="description"
                           :disabled="!editMode"
                           :class="{ '-has-error': $v.projectContent.description.$error }"
                           @input="userEdited = true"
                           v-model="$v.projectContent.description.$model" />
          </div>
        </div>
        <div class="row  mb-m">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.requirements.headline') }}</h6>
            <InputRichText name="requirements"
                           :disabled="!editMode"
                           :class="{ '-has-error': $v.projectContent.requirements.$error }"
                           @input="userEdited = true"
                           v-model="$v.projectContent.requirements.$model" />
          </div>
        </div>
        <div class="row  mb-m">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.additional_infos.headline') }}</h6>
            <InputRichText name="additional_infos"
                           :disabled="!editMode"
                           :class="{ '-has-error': $v.projectContent.additional_infos.$error }"
                           @input="userEdited = true"
                           v-model="$v.projectContent.additional_infos.$model" />
          </div>
        </div>
      </FormElement>
      <div class="_footer">
        <hr class="-grey-200  mb-s">
        <div class="row">
          <div class="col-24  text-right">
            <Button v-if="editMode" class="-orange  -thin" @click.native="toggleEditMode">{{ $t('projectContent.buttons.save') }}</Button>
            <Button v-if="!editMode" class="-grey  -thin" @click.native="toggleEditMode">{{ $t('projectContent.buttons.edit') }}</Button>
            <Button class="-grey  -thin  ml-xs" @click.native="cancelEdit" v-if="editMode && !init">
              <Icon class="-size-20"><CloseSVG></CloseSVG></Icon>
            </Button>
          </div>
        </div>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { patchProject } from '@/api/projects.api';
import parseDates from '@/mixins/parseDates';
import formatStrings from '@/mixins/formatStrings';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputRichText from '@/components/elements/inputs/InputRichText.vue';
// import RichText from '@/components/elements/RichText.vue';

import CloseSVG from '@/assets/icons/close.svg';

export default {
  name: 'ProjectContent',
  mixins: [parseDates, formatStrings, validationMixin],
  props: {
    project: Object,
    init: Boolean,
  },
  data() {
    return {
      editMode: this.init,
      userEdited: this.init,
      projectContent: {},
    };
  },
  watch: {
    project() {
      this.initContent();
    },
  },
  validations: {
    projectContent: {
      intro: { required },
      description: { required },
      requirements: { required },
      additional_infos: {},
    },
  },
  mounted() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.projectContent = {
        intro: this.project.intro,
        description: this.project.description,
        requirements: this.project.requirements,
        additional_infos: this.project.additional_infos,
      };
    },

    toggleEditMode() {
      if (!this.editMode) {
        this.editMode = true;
      } else if (this.userEdited) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.updateProjectContent();
        }
      } else {
        this.editMode = false;
      }
    },

    cancelEdit() {
      if (this.userEdited) {
        this.initContent();
      }

      this.editMode = false;
    },

    updateProjectContent() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchProject(this.project.id, this.projectContent, token)
            .then(() => {
              this.editMode = false;
              this.userEdited = false;
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    Button,
    Icon,
    ContentBox,
    FormElement,
    InputRichText,
    CloseSVG,
    // RichText,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-content.scss"></style>
