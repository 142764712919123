import httpClient from './httpClient';

const mapAuthHeader = (token, opts = {}) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
});

const superUserAction = (action, { id, payload }, token) => httpClient.post(`users/${id}/super/${action}`, payload, {
  ...mapAuthHeader(token),
});

const superProjectAction = () => {};

export {
  superUserAction,
  superProjectAction,
};
