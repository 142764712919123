<template>
  <section class="manager-document-manager">
    <ContentBox class="mb-xs">
      <div class="row">
        <div class="col-20">
          <h5 class="h5 font-weight-medium mb-xs font-component-headline" :class="{'font-size-l': $matchMedia.sm}">{{ $t('managerDocumentManager.headline') }}</h5>
        </div>
        <div class="col-4 "></div>
      </div>
      <div class="font-size-base  font-weight-medium line-height-1">
        <ul class="_document_list">
          <li v-for="document in documents" :key="document.name" class="_document-item display-flex justify-content-between align-items-start" :title="document.name">
            <Button @click.native="showDocument(document)" class="-transparent">
              <Icon class="-size-24 mr-xxs">
                <component :is="fileIcons[document.mimetype] ? fileIcons[document.mimetype] : fileIcons.generic" class="_type"></component>
              </Icon>
              <span class="_documentname">{{ document.filename }}</span>
            </Button>
            <Button v-if="!groups.includes('pending')" @click.native="deleteDocument(document)" class="-transparent -compact-square ml-xxs"><Icon class="-size-16"><TrashSVG /></Icon></Button>
          </li>
        </ul>
        <FormElement v-if="!groups.includes('pending')" @submit="uploadDocument">
          <InputFile base="managerDocumentManager"
                    name="newDocument"
                    accept=".pdf"
                    class="-blue"
                    :class="{ '-has-error': false }"
                    v-model="newDocument"
          />
          <Submit v-show="newDocument !== null" class="-thin  -orange">Hochladen</Submit>
        </FormElement>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import Submit from '@/components/atoms/Submit.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputFile from '@/components/elements/inputs/InputFile.vue';
import FormElement from '@/components/elements/FormElement.vue';

import FileSvg from '@/assets/icons/file.svg';
import FilePdfSvg from '@/assets/icons/file-pdf.svg';
import FileDocSvg from '@/assets/icons/file-doc.svg';
import {
  getDocuments,
  uploadDocument,
  getDocument,
  deleteDocument,
} from '@/api/users.api';

export default {
  name: 'ManagerDocumentManager',
  mixins: [formatNumbers, validationMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      fileIcons: {
        'application/pdf': FilePdfSvg,
        doc: FileDocSvg,
        generic: FileSvg,
      },
      documents: [],
      newDocument: null,
    };
  },

  computed: {
    ...mapState([
      'uuid',
      'group',
    ]),

    groups() {
      return (Array.isArray(this.group)) ? this.group : [this.group];
    },
  },

  mounted() {
    this.getDocumentList();
  },
  methods: {
    getDocumentList() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => getDocuments(this.user.uuid, token))
        .then((response) => {
          this.documents = response.data.data;
        });
    },
    uploadDocument() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          const file = new FormData();
          file.append('document', this.newDocument);
          return uploadDocument(this.user.uuid, file, token);
        })
        .then(() => {
          this.$eventBus.$emit('notificate', {
            title: this.$t('notifications.managerDocumentManager.document_uploaded.title'),
            message: this.$t('notifications.managerDocumentManager.document_uploaded.message'),
          });
          this.getDocumentList();
          this.newDocument = null;
        })
        .catch((error) => {
          console.error(error);
          this.$eventBus.$emit('notificate', {
            status: error.response.status,
            message: error.response.message,
          });
          this.newDocument = null;
        });

      return true;
    },
    showDocument(document) {
      const cvWindow = window.open();

      this.$store.dispatch('isLoggedIn')
        .then((token) => getDocument(this.user.uuid, document.filename, token, true))
        .then((response) => {
          const file = new Blob([response.data], { type: response.data.type });
          cvWindow.location.href = URL.createObjectURL((file));
        })
        .catch(() => {
          cvWindow.location.href = '/404';
          this.$eventBus.$emit('notificate', { status: 404, title: this.$t('userMeta.fields.managerPdf.error') });
        });
    },
    deleteDocument(document) {
      if (this.confirmAction('delete')) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => deleteDocument(this.user.uuid, document.filename, token))
          .then(() => {
            this.$eventBus.$emit('notificate', {
              title: this.$t('notifications.managerDocumentManager.document_deleted.title'),
              message: this.$t('notifications.managerDocumentManager.document_deleted.message'),
            });
            this.getDocumentList();
            this.newDocument = null;
          })
          .catch((error) => {
            console.error(error);
            this.$eventBus.$emit('notificate', {
              status: error.response.status,
              message: error.response.message,
            });
          });
      }

      return false;
    },
    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t('managerDocumentManager.confirmAction')} ${this.$t(`managerDocumentManager.actions.${action}`)}`);
    },
  },
  components: {
    ContentBox,
    Button,
    Icon: () => import('@/components/atoms/Icon.vue'),
    InputFile,
    FileSvg,
    FilePdfSvg,
    FileDocSvg,
    FormElement,
    Submit,
    TrashSVG: () => import('@/assets/icons/trash.svg'),
    UploadSVG: () => import('@/assets/icons/upload.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-document-manager.scss"></style>
