<template>
  <section class="manager-history -leads">
    <ContentBox class="content-box">

      <div class="row mb-s _list-title">
        <div class="col-20">
          <h4 class="h4 mb-0 font-component-headline">{{ $t('managerLeadHistory.headline') }}</h4>
        </div>
      </div>

      <div class="_list-wrapper">

        <div class="row _list-head">
          <div class="col-5">
            <a class="label" @click="handleSort('Lead.custom_id')">
              {{ $t('managerLeadHistory.leadName') }}
              <Icon v-if="selectedSort.value === 'Lead.custom_id'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-4 col-offset-1">
            <a class="label" @click="handleSort('Lead.client')">
              {{ $t('managerLeadHistory.client') }}
              <Icon v-if="selectedSort.value === 'Lead.client'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-7 col-offset-1">
            <a class="label" @click="handleSort('phase')">
              {{ $t('managerLeadHistory.phase') }}
              <Icon v-if="selectedSort.value === 'phase'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-4 col-offset-1">
            <a class="label" @click="handleSort('updatedAt')">
              {{ $t('managerLeadHistory.updatedAt') }}
              <Icon v-if="selectedSort.value === 'updatedAt'" :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
        </div>

        <div v-if="isLoading" class="row text-center pt-xs pb-xs">
          <Icon class="-size-24"><LoadingSVG></LoadingSVG></Icon>
        </div>
        <template v-else>
          <div v-if="matches.length === 0" class="row">
            <div class="col-24 pt-xs pb-xs">
              <span class="font-weight-semibold color-grey-300">{{ $t('managerLeadHistory.noMatches') }}</span>
            </div>
          </div>
          <template v-else>
            <div class="_list-items">
              <div
                class="row _item"
                v-for="match in matches"
                :key="match.id"
                @click="openLeadDetail(match.lead_id)"
              >
                <div class="col-5">
                  <span class="font-weight-semibold text-truncate">{{ match.Lead.custom_id }}: {{ match.Lead.title }}</span>
                </div>
                <div class="col-4 col-offset-1">
                  <span class="font-weight-medium text-truncate">{{ match.Lead.client }}</span>
                </div>
                <div class="col-7 col-offset-1">
                  <MatchPhaseSelect :id="match.id" :phase="match.phase" />
                </div>
                <div class="col-4 col-offset-1">
                  <span class="font-weight-medium">{{ match.updatedAt | formatDate }}</span
                  >&nbsp;
                  <span class="font-weight-medium color-grey-400">({{ match.updatedAt | formatTime }})</span>
                </div>
                <div class="col-1 pos-relative text-right">
                  <FlyoutNav class="mt-xs" :ref="`flyout-match-${match.id}`">
                    <div>
                      <a @click.stop="openLeadDetail(match.lead_id)">{{ $t('managerLeadHistory.actions.lead') }}</a>
                      <a @click.stop="openComments(match.manager_uuid, match.lead_id)">{{ $t('managerLeadHistory.actions.note') }}</a>
                      <a @click.stop="handleDelete(match.id)">{{ $t('managerLeadHistory.actions.delete') }}</a>
                    </div>
                  </FlyoutNav>
                  <FlyoutTrigger :flyoutEl="`flyout-match-${match.id}`" :parentRefs="$refs">
                    <Icon class="-size-40 -outlined p-xxs-less -bg-white">
                      <DotsSVG></DotsSVG>
                    </Icon>
                  </FlyoutTrigger>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <div v-if="matches.length < total || showAllMatches" class="text-center mt-xs">
        <Button class="color-brand-orange" @click.native="showAll">
          <span v-if="showAllMatches">
            {{ $t('managerLeadHistory.showLess') }}
          </span>
          <span v-else>
            {{ $t('managerLeadHistory.showAll') }}
          </span>
          <Icon :class="[{ '-rotate-180': showAllMatches }, '-size-22']">
            <ChevronSVG></ChevronSVG>
          </Icon>
        </Button>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import { listMatches } from '@/api/admins.api';
import { parseDates, matchActions } from '@/mixins';
import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import Icon from '@/components/atoms/Icon.vue';
import ChevronFullSVG from '@/assets/icons/chevron-full.svg';
import ChevronSVG from '@/assets/icons/chevron.svg';
import DotsSVG from '@/assets/icons/dots.svg';
import LoadingSVG from '@/assets/icons/loader.svg';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import MatchPhaseSelect from '@/components/modules/Lead/admin/MatchPhaseSelect.vue';

export default {
  name: 'ManagerLeadHistory',

  mixins: [parseDates, matchActions],

  props: {
    uuid: String,
  },

  data() {
    return {
      isLoading: true,
      allMatches: [],
      showAllMatches: false,
      limit: 5,
      total: 0,
      selectedSort: {
        value: 'updatedAt',
        direction: 'desc',
      },
    };
  },

  created() {
    this.fetchMatches();
    this.$on('update', this.fetchMatches);
    this.$eventBus.$on('match-modal-created', this.onMatchCreated);
  },

  destroyed() {
    this.$off('update', this.fetchMatches);
    this.$eventBus.$off('match-modal-created', this.onMatchCreated);
  },

  computed: {
    filter() {
      return {
        sort_by: `${this.selectedSort.value}.${this.selectedSort.direction}`,
      };
    },

    matches() {
      if (!this.showAllMatches) {
        return this.allMatches.slice(0, this.limit);
      }

      return this.allMatches;
    },
  },

  methods: {
    fetchMatches() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          this.isLoading = true;

          listMatches(this.uuid, this.filter, token)
            .then((r) => r.data)
            .then((data) => {
              this.total = data.count;
              this.$set(this, 'allMatches', data.rows);
              this.isLoading = false;
              this.$emit('fetchedMatches', this.allMatches);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    },

    onMatchCreated(match) {
      if (match.manager_uuid === this.uuid) {
        this.fetchMatches();
      }
    },

    showAll() {
      this.showAllMatches = !this.showAllMatches;
    },

    handleSort(value) {
      if (this.selectedSort.value !== value) {
        this.$set(this.selectedSort, 'direction', 'desc');
      } else {
        this.$set(this.selectedSort, 'direction', this.selectedSort.direction === 'asc' ? 'desc' : 'asc');
      }

      this.$set(this.selectedSort, 'value', value);
      this.fetchMatches();
    },

    openComments(managerUuid, leadId) {
      this.$eventBus.$emit('open-modal-comments', {
        manager: managerUuid,
        commentableType: 'Lead',
        commentableId: leadId,
      });
    },

    openLeadDetail(leadId) {
      this.$router.push({
        name: 'LeadDetail',
        params: {
          id: leadId,
          lang: this.$i18n.locale,
        },
      });
    },
  },

  components: {
    ContentBox,
    Button,
    Icon,
    ChevronFullSVG,
    ChevronSVG,
    FlyoutNav,
    FlyoutTrigger,
    DotsSVG,
    MatchPhaseSelect,
    LoadingSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-history.scss"></style>
