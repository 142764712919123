<template>
  <div :class="[checked === true ? `color-${colorChecked}` :  `color-${colorUnChecked}`]">
    <template v-if="checked === true">
      <Icon class="-inline"><CheckSVG /></Icon> {{ text }}
    </template>
    <template v-else>
      <Icon class="-inline"><CloseSVG /></Icon> {{ textUnchecked || text }}
    </template>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon.vue';
import CheckSVG from '@/assets/icons/check.svg';
import CloseSVG from '@/assets/icons/close.svg';

export default {
  props: {
    checked: Boolean,
    text: String,
    textUnchecked: String,
    colorChecked: {
      type: String,
      default: 'success',
    },
    colorUnChecked: {
      type: String,
      default: 'error',
    },
  },
  components: {
    Icon,
    CheckSVG,
    CloseSVG,
  },
};
</script>
