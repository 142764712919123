<template>
  <section class="self-assessment-detail">
    <ContentBox>
      <h4 class="h4">{{ $t('selfAssessmentDetail.headline') }}</h4>
      <div class="row  display-flex">
        <div class="col-8  col-md-24  -same-height">
          <ContentBox>
            <h6 class="h6">{{ $t('selfAssessmentDetail.steps.experiences.headline') }}</h6>
            <List v-if="Object.keys(filteredExperiences).length > 0">
              <li v-for="(val, key) in filteredExperiences" :key="key">
                {{ $t(`categories.experiences.${key}`) }}: <b class="-semi">{{ val }} {{ $t('abbreviations.year.short') }}</b>
              </li>
            </List>
            <template v-else>
              <span class="_empty-note">{{ $t('selfAssessmentDetail.steps.experiences.empty') }}</span>
            </template>
          </ContentBox>
        </div>
        <div class="col-8  col-md-24  -same-height">
          <ContentBox>
            <h6 class="h6">{{ $t('selfAssessmentDetail.steps.operationals.headline') }}</h6>
            <List v-if="operationals.length > 0">
              <li v-for="(val) in operationals" :key="val">
                {{ $t(`categories.operationals.main.${val}`) }}
              </li>
            </List>
            <template v-else>
              <span class="_empty-note">{{ $t('selfAssessmentDetail.steps.operationals.empty') }}</span>
            </template>
          </ContentBox>
        </div>
        <div class="col-8  col-md-24  -same-height">
          <ContentBox>
            <h6 class="h6">{{ $t('selfAssessmentDetail.steps.industries.headline') }}</h6>
            <List v-if="Object.keys(filteredIndustries).length > 0">
              <li v-for="(val, key) in filteredIndustries" :key="key">
                {{ $t(`categories.industries.main.${key}`) }}:
                <b class="-semi">{{ $t(`selfAssessment.steps.industries.choices.${val}`) }} {{ $t('abbreviations.year.short') }}</b>
              </li>
            </List>
            <template v-else>
              <span class="_empty-note">{{ $t('selfAssessmentDetail.steps.industries.empty') }}</span>
            </template>
          </ContentBox>
        </div>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import objectUtils from '@/mixins/objectUtils';

import List from '@/components/atoms/List.vue';
import ContentBox from '@/components/elements/ContentBox.vue';

export default {
  name: 'SelfAssessmentDetail',
  mixins: [objectUtils],
  props: {
    experiences: Object,
    operationals: Array,
    industries: Object,
  },
  computed: {
    filteredExperiences() {
      return this.filterObj(this.experiences, (exp) => exp > 0);
    },

    filteredIndustries() {
      return this.filterObj(this.industries, (industry) => industry !== 'none');
    },
  },
  components: {
    ContentBox,
    List,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/self-assessment-detail.scss"></style>
