<template>
  <Modal id="managerCategorization"
         class="-wide"
         :title="$t('managerCategorization.headline')"
         @close="handleCloseModal">
    <FormElement @submit.prevent>
      <ContentBox>
        <h6 class="h6">{{ $t(`managerCategorization.operationals.headline`) }}</h6>
        <div class="row">
          <div class="col-12  col-md-24"
               v-for="category in ['fn_management', 'fn_business', 'fn_it', 'fn_operations']"
               :key="category">
            <p class="text-mb-xs">{{ $t(`managerCategorization.categories.${category}.headline`) }}</p>
            <List>
              <InputCheckbox v-for="(input, key) in $t(`managerCategorization.categories.${category}.inputs`)"
                             :key="key"
                             :base="category"
                             :name="key"
                             :inputValue="key"
                             @input="setEdited"
                             v-model="categories[category]">
                <template slot="label">
                  {{ input }}
                </template>
              </InputCheckbox>
            </List>
          </div>
        </div>
      </ContentBox>
      <ContentBox>
        <h6 class="h6">{{ $t(`managerCategorization.categories.industries.headline`) }}</h6>
        <div class="row">
          <div class="col-8  col-md-24" v-for="(group, index) in groupedAllIndustries" :key="index">
            <List>
              <InputCheckbox v-for="input in group" :key="input"
                             base="industries"
                             :name="input"
                             :inputValue="input"
                             @input="setEdited"
                             v-model="categories.industries">
                <template slot="label">
                  {{ $t(`managerCategorization.categories.industries.inputs.${input}`) }}
                </template>
              </InputCheckbox>
            </List>
          </div>
        </div>
      </ContentBox>
      <div class="row">
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">{{ $t(`managerCategorization.categories.company_sizes.headline`) }}</h6>
            <List class="-inlineblock  mb-0">
              <InputCheckbox v-for="(input, key) in $t('managerCategorization.categories.company_sizes.inputs')"
                             :key="key"
                             base="company_sizes"
                             :name="key"
                             :inputValue="key"
                             @input="setEdited"
                             v-model="categories.company_sizes">
                <template slot="label">
                  {{ input }}
                </template>
              </InputCheckbox>
            </List>
          </ContentBox>
        </div>
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">{{ $t(`managerCategorization.categories.daily_price.headline`) }}</h6>
            <InputCurrency v-for="(input, key) in $t('managerCategorization.categories.daily_price.inputs')"
                           :key="key"
                           class="-inline  mr-xs"
                           alt="small"
                           type="number"
                           base="managerCategorization.categories.daily_price"
                           :name="key"
                           @input="setEdited"
                           v-model="categories[`daily_price_${key}`]">
              <template slot="label">{{ input.label }}</template>
            </InputCurrency>
          </ContentBox>
        </div>
      </div>
      <div class="row">
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">
              {{ $t(`managerCategorization.categories.international_exp.headline`) }}
              <span class="_hnote">
                  {{ $t('managerCategorization.categories.international_exp.note') }}
                </span>
            </h6>
            <div class="_tag-wrapper">
              <InputTag class="-light"
                        v-for="exp in categories.international_exp"
                        :value="exp"
                        :key="exp"
                        @remove="handleRemoveSelected($event, 'international_exp')">
                {{ ($te(`countries.popular.${exp}`)) ? $t(`countries.popular.${exp}`) : $t(`countries.default.${exp}`) }}
              </InputTag>
            </div>
            <div class="_select-wrapper">
              <InputSelect class="-dark  display-inlineblock"
                           @input="categories.international_exp.push($event); setEdited();">
                <option disabled selected value="">{{ $t('managerCategorization.categories.international_exp.placeholder') }}</option>
                <template slot="options">
                  <optgroup :label="$t('countries.labels.popular')">
                    <option v-for="(country, key) in $t('countries.popular')" :key="key"
                            :disabled="categories.international_exp.includes(key)"
                            :value="key">
                      {{ country }}
                    </option>
                  </optgroup>
                  <optgroup :label="$t('countries.labels.default')">
                    <option v-for="(country, key) in $t('countries.default')" :key="key"
                            :disabled="categories.international_exp.includes(key)"
                            :value="key">
                      {{ country }}
                    </option>
                  </optgroup>
                </template>
              </InputSelect>
            </div>
          </ContentBox>
        </div>
        <div class="col-12  col-md-24">
          <ContentBox>
            <h6 class="h6">{{ $t(`managerCategorization.categories.languages.headline`) }}
              <span class="_hnote">{{ $t('managerCategorization.categories.languages.note') }}</span>
            </h6>
            <div class="_tag-wrapper">
              <InputTag class="-light"
                        v-for="lang in categories.languages"
                        :value="lang"
                        :key="lang"
                        @remove="handleRemoveSelected($event, 'languages')">
                {{ ($te(`languages.popular.${lang}`)) ? $t(`languages.popular.${lang}`) : $t(`languages.default.${lang}`) }}
              </InputTag>
            </div>
            <div class="_select-wrapper">
              <InputSelect class="-dark  display-inlineblock"
                           @input="categories.languages.push($event); setEdited();">
                <option disabled selected value="">{{ $t('managerCategorization.categories.languages.placeholder') }}</option>
                <template slot="options">
                  <optgroup :label="$t('languages.labels.popular')">
                    <option v-for="(language, key) in $t('languages.popular')" :key="key"
                            :disabled="categories.languages.includes(key)"
                            :value="key">
                      {{ language }}
                    </option>
                  </optgroup>
                  <optgroup :label="$t('languages.labels.default')">
                    <option v-for="(language, key) in $t('languages.default')" :key="key"
                            :disabled="categories.languages.includes(key)"
                            :value="key">
                      {{ language }}
                    </option>
                  </optgroup>
                </template>
              </InputSelect>
            </div>
          </ContentBox>
        </div>
      </div>
      <ContentBox class="mb-s">
        <h6 class="h6">{{ $t(`managerCategorization.categories.skills.headline`) }}</h6>
        <div class="row">
          <div class="col-8  col-md-12  col-sm-24" v-for="(group, index) in groupedAllSkills" :key="index">
            <InputCheckbox v-for="input in group" :key="input"
                           base="industries"
                           :name="input"
                           :inputValue="input"
                           @input="setEdited"
                           v-model="categories.skills">
              <template slot="label">
                {{ $t(`managerCategorization.categories.skills.inputs.${input}`) }}
              </template>
            </InputCheckbox>
          </div>
        </div>
      </ContentBox>
    </FormElement>
    <template slot="footer">
      <div class="text-right">
        <Button class="button -orange -outlined mr-s" @click.native="$eventBus.$emit('close-modal-managerCategorization')">
          {{ $t('managerCategorization.buttons.cancel') }}
        </Button>
        <Button class="-orange" @click.native="saveAndExit">
          {{ $t('managerCategorization.buttons.save') }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import List from '@/components/atoms/List.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputCurrency from '@/components/elements/inputs/InputCurrency.vue';
import InputTag from '@/components/elements/inputs/InputTag.vue';
import Modal from '@/components/elements/Modal.vue';

import arrayUtils from '@/mixins/arrayUtils';

export default {
  name: 'ManagerCategorizationModal',
  mixins: [arrayUtils, formatNumbers],
  props: {
    uuid: String,
    categorization: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userEdited: false,
      categories: {},
    };
  },
  computed: {
    groupedSelectedIndustries() {
      return this.getGrouped(Object.keys(this.$t('managerCategorization.categories.industries.inputs')), 'industries');
    },

    groupedAllIndustries() {
      return this.getGrouped(Object.keys(this.$t('managerCategorization.categories.industries.inputs')), 'industries', false);
    },

    groupedAllSkills() {
      return this.getGrouped(Object.keys(this.$t('managerCategorization.categories.skills.inputs')), 'skills', false);
    },

    allBuzzwords() {
      return [...this.categories.skills, ...this.categories.buzzwords];
    },
  },
  watch: {
    categorization(newValue) {
      this.initDefaults();
      this.initCategorization(newValue);
    },
  },
  created() {
    this.initDefaults();
  },
  mounted() {
    this.initCategorization(this.categorization);
  },
  methods: {
    initDefaults() {
      Object.keys(this.$t('managerCategorization.categories')).forEach((category) => {
        if (category === 'daily_price') {
          this.$set(this.categories, 'daily_price_from', 0);
          this.$set(this.categories, 'daily_price_to', 0);
        } else {
          this.$set(this.categories, category, []);
        }
      });
    },

    initCategorization(newValue) {
      Object.keys(newValue).forEach((key) => {
        if (newValue[key] !== null) {
          if (newValue[key].length) {
            this.$set(this.categories, key, [...this.categories[key], ...newValue[key]]);
          } else if (newValue[key] > 0) {
            this.$set(this.categories, key, newValue[key]);
          }
        }
      });
    },

    getGrouped(items, cat, filterSelected = true) {
      const filtered = (filterSelected) ? items.filter((el) => this.categories[cat].includes(el)) : items;
      return this.chunkArray(filtered, Math.ceil(filtered.length / 3));
    },

    handleRemoveSelected(target, category) {
      this.$set(this.categories, category, this.removeFromArray(this.categories[category], target));
      this.setEdited();
    },

    setEdited() {
      this.userEdited = true;
    },

    handleCloseModal() {
      if (this.userEdited) {
        this.initDefaults();
        this.initCategorization(this.categorization);
      }
    },

    saveAndExit() {
      this.$emit('update', { ...this.categories });
    },
  },
  components: {
    Button,
    List,
    ContentBox,
    FormElement,
    InputCheckbox,
    InputSelect,
    InputCurrency,
    InputTag,
    Modal,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-categorization.scss"></style>
