<template>
  <div class="project-detail">
    <ProjectHeader :project="project"
                   :id="project.id"
                   :title="project.title"
                   :status="project.status"
                   :privatized="project.privatized"
                   @update="fetchProject"
                   @publish="publishProject"
                   @approve="approveProject"
                   @sendCampaign="$eventBus.$emit('open-modal-managerSelectModal');"
    >
    </ProjectHeader>
    <ProjectMeta ref="meta" v-if="project.id" :project="project" :init="init" @update="fetchProject"></ProjectMeta>
    <ProjectContent ref="content" v-if="project.id" :project="project" :init="init" @update="fetchProject"></ProjectContent>
    <ProjectDetails ref="details" v-if="project.id" :project="project" :init="init" @update="fetchProject"></ProjectDetails>
    <ProjectInternals ref="internals" v-if="project.id" :project="project" :init="init" @update="fetchProject"></ProjectInternals>
    <ProjectCampaign ref="campaign" v-if="project.id" :project="project" @update="fetchProject" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getProject,
  publishProject,
  approveProject,
} from '@/api/projects.api';

import ProjectHeader from '@/components/modules/Project/admin/Header.vue';
import ProjectMeta from '@/components/modules/Project/admin/Meta.vue';
import ProjectContent from '@/components/modules/Project/admin/Content.vue';
import ProjectDetails from '@/components/modules/Project/admin/Details.vue';
import ProjectInternals from '@/components/modules/Project/admin/Internals.vue';
import ProjectCampaign from '@/components/modules/Project/admin/Campaign.vue';

export default {
  name: 'AdminProjectDetail',
  props: {
    id: Number,
    init: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      project: {},
      valid: false,
    };
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),
  },
  mounted() {
    this.fetchProject();
  },
  methods: {
    fetchProject() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          getProject(parseInt(this.id, 10), token)
            .then((response) => response.data)
            .then(({ data }) => {
              this.$set(this, 'project', data);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.$router.replace({ name: 'NotFound', params: { lang: this.$i18n.locale } });
              }
            });
        });
    },

    validateProject() {
      const {
        meta,
        content,
        details,
        internals,
      } = this.$refs;

      meta.$v.$touch();
      content.$v.$touch();
      details.$v.$touch();
      internals.$v.$touch();

      return ![meta.$v.$invalid, content.$v.$invalid, details.$v.$invalid, internals.$v.$invalid].includes(true);
    },

    publishProject(action = 'publish') {
      if (!this.userCan('publishProject')) {
        this.$eventBus.$emit('notificate', { status: 401 });
      }

      if (this.validateProject()) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            publishProject(this.id, { action }, token)
              .then(() => {
                this.$eventBus.$emit('notificate', {
                  title: this.$t('notifications.adminProjectDetail.project.published.label'),
                  message: this.$t('notifications.adminProjectDetail.project.published.message'),
                });
                this.fetchProject();
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              });
          });
      } else {
        this.$eventBus.$emit('notificate', { message: this.$t('notifications.adminProjectDetail.project.missingFields.message'), status: 400 });
      }
    },

    approveProject() {
      if (this.validateProject()) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            approveProject(this.id, token)
              .then(() => {
                this.$eventBus.$emit('notificate');
                this.fetchProject();
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', {
                  message: error.response.data.message,
                  status: error.response.status,
                });
              });
          });
      } else {
        this.$eventBus.$emit('notificate', { message: this.$t('notifications.adminProjectDetail.project.missingFields.message'), status: 400 });
      }
    },
  },

  components: {
    ProjectHeader,
    ProjectMeta,
    ProjectContent,
    ProjectDetails,
    ProjectInternals,
    ProjectCampaign,
  },
};
</script>
