<template>
  <Modal :id="base" :title="$t(`${base}.title`)" class="-thin">
    <FormElement>
      <div class="row mb-s">
        <div class="col-24">
          <InputText :base="base" name="title" type="text" :class="{ '-has-error': $v.leadModel.title.$error }" v-model="$v.leadModel.title.$model" />
        </div>
      </div>
      <div class="row mb-s">
        <div class="col-24">
          <InputText :base="base" name="client" type="text" :class="{ '-has-error': $v.leadModel.client.$error }" v-model="$v.leadModel.client.$model" />
        </div>
      </div>
      <div class="row mb-s">
        <div class="col-12">
          <div class="form-element" :class="{ '-has-error': notUniqueError }">
            <InputText :base="base" name="custom_id" type="text" :class="{ '-has-error': notUniqueError || $v.leadModel.custom_id.$error }" v-model="$v.leadModel.custom_id.$model" />
            <div class="error-msg" v-if="notUniqueError">
              {{ $t(`${base}.inputs.custom_id.errorNotUnique`) }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <InputSelect
            :base="base"
            name="profit_center"
            :class="{ '-has-error': $v.leadModel.profit_center.$error }"
            v-model="$v.leadModel.profit_center.$model"
            :options="$t('projectInternals.inputs.pc.values')"
          >
            <option selected value="">
              {{ $t(`${base}.inputs.profit_center.placeholder`) }}
            </option>
          </InputSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputSelect :base="base" name="assignee" :class="{ '-has-error': $v.leadModel.assignee.$error }" v-model="$v.leadModel.assignee.$model">
            <option selected value="">
              {{ $t(`${base}.inputs.assignee.placeholder`) }}
            </option>
            <option v-for="admin in assigneeList" :key="admin.uuid" :value="admin.uuid">{{ admin.lastname }}, {{ admin.firstname }}</option>
          </InputSelect>
        </div>
        <div class="col-12">
          <InputSelect :base="base" name="status" :class="{ '-has-error': $v.leadModel.status.$error }" v-model="$v.leadModel.status.$model">
            <template v-slot:options>
              <option v-for="(option, key) in $t('leadStatus')" :value="key" :key="key">{{ option.label }}</option>
            </template>
          </InputSelect>
        </div>
      </div>
    </FormElement>
    <template v-slot:footer>
      <div class="text-right">
        <Button class="mr-s -orange -outlined" @click.native="$eventBus.$emit(`close-modal-${base}`)">
          {{ $t(`${base}.buttons.cancel`) }}
        </Button>
        <Button class="-orange" @click.native="saveAndExit">
          {{ $t(`${base}.buttons.save`) }}
        </Button>
      </div>
    </template>
  </Modal>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { possibleAssignees } from '@/values';
import { createLead, updateLead } from '@/api/leads.api';
import Button from '@/components/atoms/Button.vue';
import Modal from '@/components/elements/Modal.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';

const blankLead = {
  title: '',
  client: '',
  custom_id: '',
  profit_center: '',
  assignee: '',
  status: 'opportunity',
};

export default {
  name: 'LeadModal',
  props: {
    base: String,
    lead: Object,
  },
  mixins: [validationMixin],
  data() {
    return {
      notUniqueError: false,
      assigneeList: [],
      leadModel: {
        ...blankLead,
      },
    };
  },
  async mounted() {
    await this.getPossibleAnsignees();
    this.leadModel = this.lead ? { ...this.lead } : { ...blankLead };
  },
  watch: {
    lead(newVal) {
      if (newVal && newVal.id) {
        this.leadModel = { ...newVal };
      } else {
        this.leadModel = { ...blankLead };
      }
    },
  },
  methods: {
    getPossibleAnsignees() {
      return this.$store
        .dispatch('getAdminList')
        .then((adminList) => {
          this.assigneeList = adminList.filter((admin) => admin.group.some((group) => possibleAssignees.leads.includes(group)));
        })
        .catch((error) => console.error(error));
    },

    resetLead() {
      this.$set(this, 'leadModel', { ...blankLead });
      this.notUniqueError = false;
      this.$v.$reset();
    },

    saveAndExit() {
      this.notUniqueError = false;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store.dispatch('isLoggedIn').then((token) => {
          if (!this.leadModel.id) {
            createLead(this.leadModel, token).then(this.handleApiSuccess).catch(this.handleApiError);
          } else {
            updateLead(this.leadModel.id, this.leadModel, token).then(this.handleApiSuccess).catch(this.handleApiError);
          }
        });
      }
    },

    handleApiSuccess() {
      this.$eventBus.$emit(`close-modal-${this.base}`, true);
      this.$emit('update');
      this.resetLead();
      this.$eventBus.$emit('notificate', { title: this.$t(`notifications.${this.base}.success.title`), message: this.$t(`notifications.${this.base}.success.message`) });
    },

    handleApiError(error) {
      if (error.response.status === 409 && error.response.data.message === 'notUnique') {
        this.notUniqueError = true;
      } else {
        console.log(error);
        this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
      }
    },
  },
  validations: {
    leadModel: {
      title: { required, minLength: minLength(2), maxLength: maxLength(255) },
      client: { minLength: minLength(2), maxLength: maxLength(255) },
      custom_id: { required, maxLength: maxLength(255), onlyNumbers: (value) => /^[\d]+$/.test(value) },
      profit_center: { required },
      status: {},
      assignee: { required },
    },
  },
  components: {
    Modal,
    Button,
    InputText,
    FormElement,
    InputSelect,
  },
};
</script>
