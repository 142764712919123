<template>
  <section class="project-applications-list">
    <div class="border-bottom  -blue-100">
      <div class="row">
        <div class="col-8  border-right  -blue-100">
          <div class="headline  pl-xs">
            {{ $t('projectApplications.project.headline') }}
          </div>
        </div>
        <div class="col-16">
          <div class="headline  pl-xxs">
            {{ $t('projectApplications.application.headline') }}
          </div>
        </div>
      </div>
    </div>
    <div class="border-bottom  -blue-100">
      <div class="row">
        <div class="col-8  pt-xxs  pb-xxs  border-right  -blue-100">
          <div class="filter-input  pl-xs">
            <Icon class="-inline">
              <CheckmarkCircleSVG></CheckmarkCircleSVG>
            </Icon>
            <select v-model="selectedFilter.projects.assigned" @change="fetchProjects">
              <option :value="{}">{{ $t('projectApplications.filter.projects.assigned.all') }}</option>
              <option v-for="(text, value) in $t('projectInternals.inputs.pc.values')"
                      :key="value"
                      :value="{ ['$Project.pc$']: value }">{{ text }}</option>
            </select>
          </div>
          <div class="filter-input">
            <Icon class="-inline">
              <FilterSVG></FilterSVG>
            </Icon>
            <select v-model="selectedFilter.projects.status" @change="fetchProjects">
              <option value="" selected>{{ $t('projectApplications.filter.projects.status.all') }}</option>
              <option value="published">{{ $t('projectApplications.filter.projects.status.published') }}</option>
              <option value="closed">{{ $t('projectApplications.filter.projects.status.closed') }}</option>
              <option value="staffed">{{ $t('projectApplications.filter.projects.status.staffed') }}</option>
            </select>
          </div>
          <div class="filter-input">
            <Icon class="-inline">
              <SwapSVG></SwapSVG>
            </Icon>
            <select v-model="selectedSort.projects" @change="handleSelectedSort('projects', fetchProjects)">
              <option value="" selected disabled>{{ $t('projectApplications.sort.projects.placeholder') }}</option>
              <option :value="{ value: 'unsetApplications', direction: 'desc', frontend: true }">
                {{ $t('projectApplications.sort.projects.unsetApplications.label') }} - {{ $t('projectApplications.sort.projects.unsetApplications.desc') }}
              </option>
              <option :value="{ value: 'unsetApplications', direction: 'asc', frontend: true }">
                {{ $t('projectApplications.sort.projects.unsetApplications.label') }} - {{ $t('projectApplications.sort.projects.unsetApplications.asc') }}
              </option>
              <option :value="{ value: 'count', direction: 'desc', frontend: true }">
                {{ $t('projectApplications.sort.projects.count.label') }} - {{ $t('projectApplications.sort.projects.count.desc') }}
              </option>
              <option :value="{ value: 'count', direction: 'asc', frontend: true }">
                {{ $t('projectApplications.sort.projects.count.label') }} - {{ $t('projectApplications.sort.projects.count.asc') }}
              </option>
              <option :value="{ value: 'Project.application_deadline', direction: 'desc' }">
                {{ $t('projectApplications.sort.projects.application_deadline.label') }} - {{ $t('projectApplications.sort.projects.application_deadline.desc') }}
              </option>
              <option :value="{ value: 'Project.application_deadline', direction: 'asc' }">
                {{ $t('projectApplications.sort.projects.application_deadline.label') }} - {{ $t('projectApplications.sort.projects.application_deadline.asc') }}
              </option>
              <option :value="{ value: 'Project.title', direction: 'asc' }">
                {{ $t('projectApplications.sort.projects.title.label') }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-16  pt-xxs  pb-xxs">
          <div class="filter-input">
            <Icon>
              <SwapSVG></SwapSVG>
            </Icon>
            <select v-model="selectedSort.applicants" @change="handleSelectedSort('applicants', fetchApplications)" :disabled="!projectId">
              <option value="" selected disabled>{{ $t('projectApplications.sort.applicants.placeholder') }}</option>
              <option :value="{ value: 'message.receivedDateTime', direction: 'desc', frontend: true, type: 'date' }">
                {{ $t('projectApplications.sort.applicants.receivedDateTime.label') }} - {{ $t('projectApplications.sort.applicants.receivedDateTime.desc') }}
              </option>
              <option :value="{ value: 'message.receivedDateTime', direction: 'asc', frontend: true, type: 'date' }">
                {{ $t('projectApplications.sort.applicants.receivedDateTime.label') }} - {{ $t('projectApplications.sort.applicants.receivedDateTime.asc') }}
              </option>
              <option :value="{ value: 'createdAt', direction: 'desc' }">
                {{ $t('projectApplications.sort.applicants.createdAt.label') }} - {{ $t('projectApplications.sort.applicants.createdAt.desc') }}
              </option>
              <option :value="{ value: 'createdAt', direction: 'asc' }">
                {{ $t('projectApplications.sort.applicants.createdAt.label') }} - {{ $t('projectApplications.sort.applicants.createdAt.asc') }}
              </option>
              <option :value="{ value: 'Manager.User.lastname', direction: 'asc' }">
                {{ $t('projectApplications.sort.applicants.lastname.label') }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8  pr-0  border-right  -blue-100">
        <div class="project-wrapper">
          <router-link v-for="{ Project: project, ...applications } in projectsSorted" :key="applications.id"
                       :to="{ name: 'ProjectApplications', params: { projectId: applications.ProjectId, lang: $i18n.locale }}">
            <Project :project="project"
                     :count="applications.count"
                     :unsetApplications="(applications.ProjectId === projectId) ? countUnsetApplications : applications.unsetApplications">
            </Project>
          </router-link>
          <div v-if="projects.length <= 0" class="_empty-note">
            {{ $t('projectApplications.emptyResult.projects') }}
          </div>
        </div>
      </div>
      <div class="col-16  pl-0">
        <div class="project-wrapper">
          <div v-for="status in applicantStatus" :key="status">
            <div v-if="applicantStatusCollection(status).length" class="status">
              {{ $t(`projectApplications.fields.${status}.label`) }}
            </div>
            <div v-for="({ Manager: { User: user }, ...application }) in validApplicants" :key="application.id">
              <div v-if="application.status === status">
                <router-link :to="{ name: 'ProjectApplications', params: { projectId, applicationId: application.id, lang: $i18n.locale }}">
                  <ProjectApplicant :message="getMessage(application.mailId)" :application="application" :user="user" :editable="currentProjectOpen">
                    <template slot="actionsNav">
                      <a v-for="state in applicantStatus" :key="state"
                         :disabled="application.status === state"
                         @click.prevent="handleSetStatus(state, application.id)">{{ $t(`projectApplications.actions.${state}`) }}</a>
                    </template>
                  </ProjectApplicant>
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="projectId && applicants.length <= 0" class="_empty-note">
            {{ $t('projectApplications.emptyResult.applicants') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { listProjectApplications, patchProjectApplication } from '@/api/projects.api';
import { queryObjects, objectUtils } from '@/mixins';

import Icon from '@/components/atoms/Icon.vue';
import Project from '@/components/modules/Project/Project.vue';
import ProjectApplicant from '@/components/modules/Project/ProjectApplicant.vue';

import CheckmarkCircleSVG from '@/assets/icons/checkmark-circle.svg';
import FilterSVG from '@/assets/icons/filter.svg';
import SwapSVG from '@/assets/icons/swap.svg';

export default {
  name: 'ProjectApplicationsList',
  mixins: [queryObjects, objectUtils],
  props: {
    projectId: Number,
    defaultFilter: {
      type: Object,
      default: () => ({
        projects: {
          assigned: {},
          status: '',
        },
      }),
    },
    defaultSort: {
      type: Object,
      default: () => ({
        projects: {
          frontend: true,
          value: 'unsetApplications',
          direction: 'desc',
        },
        applicants: {
          value: 'createdAt',
          direction: 'desc',
        },
      }),
    },
  },
  data() {
    return {
      applicantStatus: ['unset', 'shortlist', 'longlist', 'backlog', 'onHold', 'accepted', 'declined'],
      messages: [],
      projects: [],
      currentProject: {},
      applicants: [],
      selectedFilter: { ...this.defaultFilter },
      selectedSort: { ...this.defaultSort },
    };
  },
  computed: {
    validApplicants() {
      return this.applicantsSorted.filter((applicant) => applicant.Manager !== null);
    },

    countUnsetApplications() {
      return this.applicants.filter((applicant) => applicant.status === 'unset').length;
    },

    currentProjectOpen() {
      return (this.currentProject?.status !== 'staffed');
    },

    projectsSorted() {
      if (this.selectedSort.projects.frontend) {
        return [...this.projects].sort(this.customSort('projects'));
      }

      return this.projects;
    },

    projectsFilter() {
      const { assigned, status, ...others } = this.selectedFilter.projects;
      return {
        ...assigned,
        ...this.projectStatusFilter,
        ...others,
      };
    },

    projectsQuery() {
      return {
        ...this.buildFilterQuery(this.projectsFilter),
        ...((!this.selectedSort.projects.frontend) && this.buildSortQuery(this.selectedSort.projects)),
      };
    },

    projectStatusFilter() {
      const filter = {};

      switch (this.selectedFilter.projects.status) {
        case 'closed':
          filter.query = {
            and: [
              {
                '$Project.status$': {
                  ne: 'staffed',
                },
              },
              {
                or: [
                  {
                    '$Project.status$': this.selectedFilter.projects.status,
                  },
                  {
                    '$Project.application_deadline$': {
                      lte: new Date().toISOString(),
                    },
                  },
                ],
              },
            ],
          };
          break;
        case 'published':
          filter['$Project.status$'] = this.selectedFilter.projects.status;
          filter['$Project.application_deadline$'] = `gt:${new Date().toISOString()}`;
          break;
        default:
          filter['$Project.status$'] = this.selectedFilter.projects.status;
      }

      return filter;
    },

    applicantsWithMessage() {
      return this.applicants.map((applicant) => ({ ...applicant, message: Object.values(this.getMessage(applicant.mailId) || {}).shift() }));
    },

    applicantsSorted() {
      if (this.selectedSort.applicants.frontend) {
        return [...this.applicantsWithMessage].sort(this.customSort('applicants'));
      }

      return this.applicants;
    },

    applicantsQuery() {
      return {
        ...((!this.selectedSort.applicants.frontend) && this.buildSortQuery(this.selectedSort.applicants)),
      };
    },
  },
  mounted() {
    this.fetchLists();
    this.$eventBus.$on('handle-update-applicant-status', this.handleSetStatus);
  },
  watch: {
    projectId(newVal) {
      if (!newVal) {
        this.$set(this, 'applicants', []);
        this.$set(this, 'currentProject', {});
      } else {
        this.fetchApplications();
      }
    },
  },
  methods: {
    customSort(target) {
      return (a, b) => {
        let aVal = this.resolvePath(a, this.selectedSort[target].value);
        let bVal = this.resolvePath(b, this.selectedSort[target].value);

        switch (this.selectedSort[target].type) {
          case 'date':
            aVal = new Date(aVal);
            bVal = new Date(bVal);
            break;
          default:
            break;
        }

        return (this.selectedSort[target].direction === 'desc') ? bVal - aVal : aVal - bVal;
      };
    },

    applicantStatusCollection(status) {
      return this.validApplicants.filter((applicant) => applicant.status === status);
    },

    getMessage(mailId) {
      return this.messages.find((message) => Object.keys(message).includes(mailId));
    },

    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t(`projectApplications.confirm.${action}`)}`);
    },

    handleSetStatus(status, applicationId) {
      if (this.currentProject.status !== 'accepted') {
        if (status === 'accepted' && !this.confirmAction('setAccepted')) {
          return;
        }

        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            patchProjectApplication(this.projectId, applicationId, {
              status,
            }, token)
              .then(() => {
                this.$eventBus.$emit('notificate', { message: this.$t('notifications.applicationsList.message') });
                this.fetchLists();
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { status: error.status, message: error.response.message });
              });
          });
      }
    },

    handleSelectedSort(target, update) {
      if (!this.selectedSort[target].frontend) {
        update();
      }
    },

    fetchLists() {
      this.fetchProjects()
        .then(() => {
          if (this.projectId) this.fetchApplications();
        });
    },

    fetchProjects() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            listProjectApplications(false, this.projectsQuery, token)
              .then((result) => result.data)
              .then(({ data }) => {
                this.$set(this, 'projects', data);
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
          });
      });
    },

    fetchApplications() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            listProjectApplications(this.projectId, this.applicantsQuery, token)
              .then((result) => result.data)
              .then(({ data, messages }) => {
                this.$set(this, 'currentProject', this.projects.find(({ ProjectId }) => ProjectId === this.projectId)?.Project || {});
                this.$set(this, 'applicants', data);

                if (messages?.status !== 400) {
                  this.$set(this, 'messages', messages);
                }

                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
          });
      });
    },
  },
  components: {
    Icon,
    Project,
    ProjectApplicant,
    CheckmarkCircleSVG,
    FilterSVG,
    SwapSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-applications.scss"></style>
