<template>
  <section class="manager-skills" v-if="skills">
    <ContentBox>
      <div class="row">
        <div class="col-20">
          <h4 class="h4 font-component-headline">{{ $t('managerSkills.headline') }}</h4>
        </div>
        <div class="col-4  text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32" @click.native="openSkillsModal" v-if="canEdit">
            <Icon class="-inline  -pos-absolute-center"><EditSVG></EditSVG></Icon>
          </Button>
        </div>
      </div>
      <ContentBox v-if="skills.length <= 0 && role === 'manager'">
        <div class="row">
          <div class="col-20  col-offset-2  col-md-24  col-md-offset-0  text-center  valign-middle">
            <p v-html="$t('managerSkills.empty')"></p>
          </div>
          <div class="col-2  valign-middle" v-if="!$matchMedia.md">
            <Icon class="_info-arrow  -size-74  color-grey-200"><InfoArrowSVG></InfoArrowSVG></Icon>
          </div>
        </div>
      </ContentBox>
      <template v-else>
        <ContentBox class="mb-s">
          <h6 class="h6">{{ $t(`managerSkills.${role}.top.headline`) }}</h6>
          <template v-if="topSkills.length <= 0 && role === 'manager'">
            <p v-html="$t('managerSkills.manager.top.empty.paragraph')"></p>
            <AnchorLink class="-orange"
                        @click.native="openSkillsModal">
              {{ $t('managerSkills.manager.top.empty.link') }}
              <Icon class="-inline  -is-right"><PlusSVG></PlusSVG></Icon>
            </AnchorLink>
          </template>
          <Tag v-for="el in topSkills"
               :key="el.skill.name"
               class="-slim  -yellow">
            <Icon class="-size-18  _star"><TopSVG></TopSVG></Icon>
            {{ el.skill.name }}
          </Tag>
          <p v-if="otherSkills.length <= 0 && role === 'admin'" class="_placeholder">
            {{ $t('managerSkills.admin.top.empty.placeholder') }}
          </p>
        </ContentBox>
        <ContentBox>
          <h6 class="h6">{{ $t(`managerSkills.${role}.other.headline`) }}</h6>
          <p v-if="otherSkills.length <= 0" class="_placeholder">
            {{ $t(`managerSkills.${role}.other.empty.placeholder`) }}
          </p>
          <Tag v-for="el in otherSkills"
               :key="el.skill.name"
               class="-slim  -blue">
            {{ el.skill.name }}
          </Tag>
        </ContentBox>
      </template>
    </ContentBox>
    <template v-if="canEdit">
      <Modal id="managerSkills"
             :title="$t('managerSkills.modal.title')"
             @close="handleCloseModal"
             class="-thin">
        <p class="mb-s">{{ $t(`managerSkills.${role}.modal.paragraph`) }}</p>
        <div>
          <h6 class="font-size-l  font-weight-medium  text-mb-xs">{{ $t('managerSkills.modal.add.title') }}</h6>
          <p class="font-size-s  font-weight-medium">{{ $t('managerSkills.modal.add.paragraph') }}</p>
          <div class="mb-m">
              <Multiselect
                :value="selectedSuggestions"
                :options="availableSkills"
                :searchable="true"
                :multiple="true"
                :taggable="true"
                :show-labels="false"
                :hide-selected="true"
                :close-on-select="true"
                :options-limit="100"
                :placeholder="$t('managerSkills.multiselect.placeholder')"
                tag-placeholder=""
                :selectLabel="$t('managerSkills.multiselect.enterToSelect')"
                label="name"
                track-by="name"
                @tag="addSkill"
                @select="selectSkill"
                ref="multiselect"
              >
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.isTag" class="display-flex justify-content-between">
                    {{ props.option.label }}
                    <Button v-if="searchInput.value.length <= maxSkillLength" @click.stop.prevent="addSkill" class="-small -no-padding" style="color: #006b4f">
                      {{ $t ('managerSkills.multiselect.tagPlaceholder') }} <Icon class="-inline -size-14"><PlusSVG></PlusSVG></Icon>
                    </Button>
                    <Button v-if="searchInput.value.length > maxSkillLength" class="-small -no-padding" style="color: #0d2745">
                      {{ $t ('managerSkills.multiselect.tooLongPlaceholder', { maxCharacters: maxSkillLength }) }} <Icon class="-inline -size-14"><NotInterestedSVG></NotInterestedSVG></Icon>
                    </Button>
                  </span>
                  <span v-else>
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="tag">{{ '' }}</template>
                <template slot="placeholder">{{ '' }}</template>
              </Multiselect>
          </div>
        </div>
        <ContentBox class="mb-s">
          <h6 class="h6">{{ $t(`managerSkills.${role}.top.headline`) }} ({{ selectedTopSkills.length }}/5)</h6>
          <p v-if="selectedTopSkills.length <= 0" class="_placeholder">
            {{ $t(`managerSkills.${role}.top.empty.placeholder`) }}
          </p>
          <InputTag v-for="(el) in selectedTopSkills"
                    :key="el.skill.name"
                    @click.native="toggleTopSkill(el)"
                    @remove="removeSkill(el)"
                    class="-yellow">
            <Icon class="-size-20  _star"><TopSVG></TopSVG></Icon>
            {{ el.skill.name }}
          </InputTag>
        </ContentBox>
        <ContentBox>
          <h6 class="h6">{{ $t(`managerSkills.${role}.other.headline`) }}</h6>
          <p v-if="selectedOtherSkills.length <= 0" class="_placeholder">
            {{ $t(`managerSkills.${role}.other.empty.placeholder`) }}
          </p>
          <InputTag v-for="(el) in selectedOtherSkills"
                    :key="el.skill.name"
                    @click.native="toggleTopSkill(el)"
                    @remove="removeSkill(el)"
                    class="-light">
            <Icon class="-size-20  _star"><TopOutlineSVG></TopOutlineSVG></Icon>
            {{ el.skill.name }}
          </InputTag>
          <p class="_note  mt-xs">{{ $t(`managerSkills.modal.star`) }}</p>
        </ContentBox>
        <template slot="footer">
          <div class="text-right">
            <Button class="mr-s -orange -outlined" @click.native="$eventBus.$emit('close-modal-managerSkills');">
              {{ $t('managerSkills.buttons.cancel') }}
            </Button>
            <Button class="-orange" @click.native="saveAndExit">
              {{ $t('managerSkills.buttons.save') }}
            </Button>
          </div>
        </template>
      </Modal>
    </template>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { updateSkills } from '@/api/users.api';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Tag from '@/components/atoms/Tag.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import Modal from '@/components/elements/Modal.vue';

const alphabeticSkillsAsc = (a, b) => ((b.skill.name > a.skill.name) ? -1 : 1);

export default {
  name: 'ManagerSkills',
  props: {
    userUuid: String,
    skills: Array,
  },
  data() {
    return {
      query: '',
      selectedSkills: [],
      maxSkillLength: 50,
    };
  },
  computed: {
    ...mapState(['user', 'role']),
    ...mapGetters([
      'userCan',
      'availableSkills',
    ]),

    searchInput() {
      return this.$refs.multiselect.$refs.search;
    },

    canEdit() {
      return this.userCan('edit', this.userUuid) || this.userCan('updateManagerSkills');
    },

    topSkills() {
      return this.filterTopSkills(this.skills);
    },

    otherSkills() {
      return this.filterOtherSkills(this.skills);
    },

    selectedTopSkills() {
      return this.filterTopSkills(this.selectedSkills);
    },

    selectedOtherSkills() {
      return this.filterOtherSkills(this.selectedSkills);
    },

    selectedSuggestions() {
      return this.selectedSkills.map((managerSkill) => ({ id: managerSkill.SkillId, name: managerSkill.skill.name }));
    },

    queryMatchesExistingAvailableSkill() {
      return this.availableSkills.filter((skill) => skill.name === this.query).length === 1;
    },

    queryMatchesSkillAlreadySelected() {
      return this.selectedSkills.filter((managerSkill) => managerSkill.skill.name === this.query).length === 1;
    },
  },
  created() {
    this.$store.dispatch('fetchAvailableSkills');
  },
  mounted() {
    this.initSkills();
  },
  methods: {
    initSkills() {
      if (this.skills) {
        this.selectedSkills = JSON.parse(JSON.stringify(this.skills)).sort(alphabeticSkillsAsc);
      }
    },

    filterTopSkills(skills) {
      return [...skills.filter((skill) => !!(skill.top))].sort(alphabeticSkillsAsc);
    },

    filterOtherSkills(skills) {
      return [...skills.filter((skill) => !(skill.top))].sort(alphabeticSkillsAsc);
    },

    openSkillsModal() {
      this.initSkills();
      this.$eventBus.$emit('open-modal-managerSkills');
    },

    handleCloseModal() {
      this.initSkills();
    },

    saveAndExit() {
      const skills = this.selectedSkills.map((skill) => ({
        id: skill.SkillId,
        name: skill.skill.name,
        top: skill.top,
      }));

      this.$store.dispatch('isLoggedIn')
        .then((token) => updateSkills(this.userUuid, skills, token))
        .then(() => {
          this.$store.dispatch('fetchAvailableSkills');
          this.$emit('update');
          this.$eventBus.$emit('close-modal-managerSkills');
        })
        .catch(() => {
          // TODO: Handle error
        });
    },

    toggleTopSkill(skill) {
      if (!skill.top) {
        if (this.selectedTopSkills.length < 5) {
          // eslint-disable-next-line
          skill.top = true;
        }
      } else {
        // eslint-disable-next-line
        skill.top = false;
      }
    },

    selectSkill(suggestion) {
      this.selectedSkills.push({
        ManagerId: this.user.id,
        SkillId: suggestion.id ?? null,
        top: false,
        skill: {
          id: suggestion.id ?? null,
          name: suggestion.name,
        },
      });
    },

    addSkill(query) {
      if (query.length <= this.maxSkillLength) {
        return this.selectSkill({ id: null, name: query });
      }

      this.$eventBus.$emit('notificate', { status: 400, message: this.$t('managerSkills.multiselect.tooLongPlaceholder', { maxCharacters: this.maxSkillLength }) });
      return null;
    },

    removeSkill(option) {
      this.selectedSkills.splice(this.selectedSkills.findIndex((el) => el.skill.name === option.skill.name), 1);
    },
  },
  components: {
    AnchorLink: () => import('@/components/atoms/Link.vue'),
    Button,
    Icon,
    Tag,
    ContentBox,
    Modal,
    Multiselect,
    PlusSVG: () => import('@/assets/icons/plus.svg'),
    EditSVG: () => import('@/assets/icons/edit.svg'),
    TopSVG: () => import('@/assets/icons/star.svg'),
    TopOutlineSVG: () => import('@/assets/icons/star-outline.svg'),
    InfoArrowSVG: () => import('@/assets/icons/info-arrow.svg'),
    NotInterestedSVG: () => import('@/assets/icons/not-interested.svg'),
    InputTag: () => import('@/components/elements/inputs/InputTag.vue'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-skills.scss"></style>
<style lang="scss" src="@/sass/07_elements/multiselect.scss"></style>
