<template>
  <section class="manager-daily-rate">
    <ContentBox>
      <div class="row">
        <div class="col-20">
          <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}">{{ $t('managerDailyRate.headline') }}</h5>
        </div>
        <div class="col-4  text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32"
                  v-if="canEdit"
                  @click.native="$eventBus.$emit('open-modal-managerDailyRateEdit')">
            <Icon class="-inline -pos-absolute-center">
              <EditSVG></EditSVG>
            </Icon>
          </Button>
        </div>
      </div>
      <div class="font-size-base  font-weight-medium line-height-1">
        {{ this.userData.dailyRate | toCurrency }}
      </div>
    </ContentBox>
    <Modal id="managerDailyRateEdit"
           :title="$t('managerDailyRate.headline')"
           class="-thin"
           v-if="canEdit"
           @close="handleModalClose">
      <FormElement>
        <div class="row">
          <div class="col-24">
            <h6 class="h6 font-size-l  font-weight-medium  text-mb-xs">{{ $t('managerDailyRate.subheadings.indicativeDailyRate') }}</h6>
          </div>
        </div>
          <div class="row">
            <div class="col-12 mb-xs">
              <div class="label">{{ $t('managerDailyRate.inputs.dailyRate.label') }}</div>
              <InputCurrency alt="small"
                             :class="['-full-width','-no-label', {'-has-error': $v.userData.dailyRate.$error }]"
                             type="number"
                             base="user"
                             @input="userEdited = true"
                             name="dailyRate"
                             v-model="$v.userData.dailyRate.$model">
              </InputCurrency>
            </div>
          </div>
      </FormElement>
      <template slot="footer">
        <div class="text-right">
          <Button class="button -orange -outlined mr-s" @click.native="$eventBus.$emit('close-modal-managerDailyRateEdit')">
            {{ $t('managerDailyRate.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="saveAndExit">
            {{ $t('managerDailyRate.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { numeric } from 'vuelidate/lib/validators';
import { updateUser } from '@/api/users.api';
import parseDates from '@/mixins/parseDates';
import userAssets from '@/mixins/userAssets';
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import Icon from '@/components/atoms/Icon.vue';
import InputCurrency from '@/components/elements/inputs/InputCurrency.vue';
import Modal from '@/components/elements/Modal.vue';

import EditSVG from '@/assets/icons/edit.svg';

export default {
  name: 'ManagerDailyRate',
  mixins: [parseDates, userAssets, formatNumbers, validationMixin],
  props: {
    user: Object,
    updatedAt: String,
  },
  data() {
    return {
      userEdited: false,
      userData: {
        dailyRate: 0,
      },
    };
  },

  computed: {
    ...mapState([
      'uuid',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    canEdit() {
      return this.userCan('edit', this.uuid) || this.userCan('updateDailyRate', this.uuid);
    },
  },

  mounted() {
    this.initData();
  },
  validations: {
    userData: {
      dailyRate: {
        numeric,
      },
    },
  },
  methods: {
    initData() {
      this.userData.dailyRate = this.user.dailyRate;
    },
    handleModalClose() {
      if (this.userEdited) {
        this.initData();
      }
    },

    saveAndExit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateDailyRate();
      }
    },

    updateDailyRate() {
      if (this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            updateUser(this.user.uuid, this.userData, token)
              .then(() => {
                this.userEdited = false;
                this.$emit('update');
                this.$eventBus.$emit('notificate');
                this.$eventBus.$emit('close-modal-managerDailyRateEdit');
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              });
          });
      }
    },
  },
  components: {
    Button,
    ContentBox,
    EditSVG,
    FormElement,
    Icon,
    InputCurrency,
    Modal,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-daily-rate.scss"></style>
