<template>
  <div class="application-detail">
    <Background></Background>
    <UserHeader v-if="user.uuid"
                :user="user"
                :avatar="avatar">
    </UserHeader>
    <div class="row">
      <div class="col-18">
        <SelfAssessmentDetail v-if="user.uuid"
                              :experiences="user.experiences"
                              :operationals="user.main_operationals"
                              :industries="user.industries">
        </SelfAssessmentDetail>
        <ManagerComments v-if="user.uuid" :uuid="user.uuid">
        </ManagerComments>
        <ManagerEvaluation v-if="user.uuid"
                           :user="user"
                           @update="fetchUser">
        </ManagerEvaluation>
        <ManagerInterviews v-if="user.uuid"
                           :status="user.status"
                           :uuid="user.uuid"
                           :interviews="user.interviews"
                           @update="fetchUser">
        </ManagerInterviews>
        <ManagerCategorization v-if="user.uuid"
                               :uuid="user.uuid"
                               :categorization="user.categorization"
                               @update="fetchUser">
        </ManagerCategorization>
        <UserMeta v-if="user.uuid"
                  :user="user"
                  :avatar="avatar"
                  @update="fetchUser">
        </UserMeta>
      </div>
      <div class="col-6">
        <ManagerAdminActions v-if="user.uuid"
                             :uuid="user.uuid"
                             :userId="user.id"
                             :group="user.group"
                             :status="user.status"
                             :partner_potential="user.partner_potential"
                             :partner="user.partner"
                             :active="user.active"
                             :username="username"
                             :adminStatus="user.admin_status"
                             :owner="user.owner"
                             :redirect="true"
                             @update="fetchUser">
        </ManagerAdminActions>
        <ManagerApplicationInfo v-if="user.uuid"
                                :uuid="user.uuid"
                                :status="user.status"
                                :assignee="user.assignee"
                                :createdAt="user.createdAt"
                                :updatedAt="user.updatedAt"
                                :username="user.username">
        </ManagerApplicationInfo>
        <ManagerDocumentManager v-if="user.uuid" :user="{...user }">
        </ManagerDocumentManager>
      </div>
    </div>
  </div>
</template>

<script>
import { findUser } from '@/api/admins.api';
import { parseDates, userAssets } from '@/mixins';

import Background from '@/components/atoms/Background.vue';
import UserHeader from '@/components/modules/User/UserHeader.vue';
import UserMeta from '@/components/modules/User/UserMeta.vue';
import SelfAssessmentDetail from '@/components/modules/User/admin/SelfAssessmentDetail.vue';
import ManagerComments from '@/components/modules/User/admin/ManagerComments.vue';
import ManagerEvaluation from '@/components/modules/User/admin/ManagerEvaluation.vue';
import ManagerInterviews from '@/components/modules/User/admin/ManagerInterviews.vue';
import ManagerCategorization from '@/components/modules/User/admin/ManagerCategorization.vue';
import ManagerAdminActions from '@/components/modules/User/admin/ManagerAdminActions.vue';
import ManagerApplicationInfo from '@/components/modules/User/admin/ManagerApplicationInfo.vue';
import ManagerDocumentManager from '@/components/modules/User/ManagerDocumentManager.vue';

export default {
  name: 'ApplicationDetail',
  mixins: [parseDates, userAssets],
  props: {
    username: String,
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user: {},
      avatar: '',
    };
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          findUser(this.username, token)
            .then((r) => r.data)
            .then(({ data }) => {
              const { User, ...fields } = data;
              this.$set(this, 'user', { ...User, ...fields });
              this.getAvatar(this.user.uuid);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.$router.replace({ name: 'NotFound', params: { lang: this.$i18n.locale } });
              }
            });
        });
    },
  },
  components: {
    Background,
    UserMeta,
    UserHeader,
    SelfAssessmentDetail,
    ManagerEvaluation,
    ManagerComments,
    ManagerInterviews,
    ManagerCategorization,
    ManagerAdminActions,
    ManagerApplicationInfo,
    ManagerDocumentManager,
  },
};
</script>
