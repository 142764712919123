<template>
  <div class="project-applications">
    <Background></Background>
    <div class="row  mt-xs  mb-m-more  pos-relative">
      <div class="col-24">
        <h2 class="h2  mt-0  mb-0  font-weight-normal  color-white">{{ $t('projectApplications.headline') }}</h2>
      </div>
    </div>
    <ProjectApplicationsList :defaultFilter="filter" :projectId="projectId"></ProjectApplicationsList>
    <transition name="slide-fade">
      <ProjectApplicantDetail
        v-if="applicationId"
        :applicationId="applicationId"
        :projectId="projectId">
      </ProjectApplicantDetail>
    </transition>
  </div>
</template>

<script>
import ProjectApplicationsList from '@/components/modules/Project/ApplicationsList.vue';
import ProjectApplicantDetail from '@/components/modules/Project/ProjectApplicantDetail.vue';

export default {
  name: 'AdminProjectApplications',
  props: {
    projectId: Number,
    applicationId: Number,
  },
  data() {
    return {
      show: true,
      filter: {
        projects: {
          assigned: {
            ...((this.$store.state.group.includes('management')) && { '$Project.responsible.uuid$': this.$store.state.uuid }),
          },
          status: '',
        },
      },
      sort: {
        value: 'createdAt',
        direction: 'desc',
      },
    };
  },
  components: {
    ProjectApplicantDetail,
    ProjectApplicationsList,
    Background: () => import('@/components/atoms/Background.vue'),
  },
};
</script>
<style scoped lang="scss" src="@/sass/09_helpers/_vuejs-transitions.scss"></style>
