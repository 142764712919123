<template>
  <div class="user-list">
    <div class="_filter">
        <div class="_filter-item">
          <InputText
            name="search"
            base="usersList.filter"
            class="_search  -light"
            v-model="selectedFilter.search"
            @input="updateList"
            type="text">
            <Icon class="-size-20"><SearchSVG></SearchSVG></Icon>
          </InputText>
        </div>
        <div class="_filter-item">
          <InputSelect v-model="selectedFilter.status"
                       class="-light"
                       name="status"
                       base="applicationList.filter"
                       @input="updateList">
            <option selected :value="defaultFilter.status">
              {{ $t('applicationList.filter.inputs.status.placeholder') }}
            </option>
            <template v-slot:options>
              <option v-for="state in states" :key="state" :value="state">{{ $t(`states.${state}.title`) }}</option>
            </template>
          </InputSelect>
        </div>
        <div class="_filter-item">
          <InputCheckbox v-model="frontendFilter.assignee"
                         name="assignee"
                         base="applicationList.filter"
                         class="-light  mb-xxs">
          </InputCheckbox>
        </div>
    </div>
    <div class="row  user-list-head">
      <div class="col-6">
        <a class="label  -light" @click="handleSort('User.lastname')">
          {{ $t('userMeta.fields.name.label') }}
          <Icon v-if="selectedSort.value === 'User.lastname'"
                :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
      <div class="col-6">
        <a class="label  -light" @click="handleSort('status')">
          {{ $t('userMeta.fields.status.label') }}
          <Icon v-if="selectedSort.value === 'status'"
                :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
      <div class="col-5">
            <span class="label  -light  -no-pointer">
              {{ $t('userMeta.fields.assignee.label') }}
            </span>
      </div>
      <div class="col-4">
        <a class="label  -light" @click="handleSort('createdAt')">
          {{ $t('userMeta.fields.createdAt.label') }}
          <Icon v-if="selectedSort.value === 'createdAt'"
                :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
      <div class="col-3">
        <a class="label  -light" @click="handleSort('updatedAt')">
          {{ $t('userMeta.fields.updatedAt.label') }}
          <Icon v-if="selectedSort.value === 'updatedAt'"
                :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
    </div>
    <div class="user-list-wrapper">
        <router-link class="row  user-item" v-for="(user) in userList" :key="user.User.uuid" :to="{
              name: 'ApplicationDetail',
                params: {
                  username: user.username,
                  lang: $i18n.locale
                }
              }">
          <div class="col-6">
            <span class="font-weight-semibold">{{ user.User.firstname }} {{ user.User.lastname }}</span>
          </div>
          <div class="col-6">
            <span class="font-weight-medium  pr-xxs">{{ $t(`states.${user.status}.title`) }}</span>
            <template v-if="$te(`states.${user.status}.note`)"><span class="font-weight-medium">{{ $t(`states.${user.status}.note`) }}</span></template>
          </div>
          <div class="col-5">
            <span class="font-weight-medium">{{ ($te(`groups.${user.assignee}`)) ? $t(`groups.${user.assignee}`) : user.assignee }}</span>
          </div>
          <div class="col-4">
            <span class="font-weight-medium">{{ user.createdAt | formatDate }}</span>
          </div>
          <div class="col-3">
            <span class="font-weight-medium">{{ user.updatedAt | formatDate }}</span>
          </div>
        </router-link>
    </div>
    <div v-if="userList.length <= 0" class="mt-m">
      <h5 class="h5  color-white">{{ $t('applicationList.noResults') }}</h5>
    </div>
    <div v-if="userList.length === (offset+1) * limit" class="mt-m  text-center">
        <Button class="-orange" @click.native="loadMore">{{ $t('applicationList.loadMore') }}</Button>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { listUsers } from '@/api/admins.api';
import { parseDates, queryObjects } from '@/mixins';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ChevronFullSVG from '@/assets/icons/chevron-full.svg';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputText from '@/components/elements/inputs/InputText.vue';

import SearchSVG from '@/assets/icons/search.svg';

export default {
  name: 'ApplicationList',
  mixins: [parseDates, queryObjects],
  props: {
    role: String,
    defaultFilter: Object,
    defaultSort: Object,
  },
  data() {
    return {
      selectedFilter: { ...this.defaultFilter },
      selectedSort: { ...this.defaultSort },
      statusFilter: {
        open: ['checkup', 'selection', 'sighting', 'consolidation', 'decision'],
      },
      frontendFilter: {
        assignee: true,
      },
      limit: 100,
      offset: 0,
      users: [],
      states: ['self-assessment', 'checkup', 'selection', 'sighting', 'consolidation', 'decision'],
    };
  },
  computed: {
    ...mapState(['uuid']),

    ...mapGetters([
      'userCan',
    ]),

    filter() {
      const { status, search, ...filter } = this.selectedFilter;

      return {
        ...(!!search) && {
          query: {
            and: [
              ...((search) ? this.buildSearchFilter(search) : []),
            ],
          },
        },
        ...((status) && { status: this.statusFilter[status] || status }),
        ...filter,
      };
    },

    sort() {
      return { ...this.defaultSort, ...this.selectedSort };
    },

    queryFilter() {
      return {
        role: this.role,
        ...this.buildFilterQuery(this.filter),
        ...this.buildSortQuery(this.sort),
        limit: this.limit,
        offset: this.offset,
        scope: 'applicantList',
      };
    },

    userList() {
      if (this.frontendFilter.assignee) {
        return this.users.filter((user) => {
          const assigned = (user.assignee === this.$store.getters.fullName);
          let groupAssigned = (this.$store.state.group.includes(user.assignee));

          if (groupAssigned && this.userCan('voteSelection')) {
            // include check if already has voted
            groupAssigned = (user.selectionVotes.filter((vote) => vote.AdminUuid === this.uuid).length === 0);
          }

          return assigned || groupAssigned;
        });
      }

      return this.users;
    },
  },
  activated() {
    if (!this.$parent.savedPosition) {
      this.updateList();
    }
  },
  methods: {
    buildSearchFilter(input) {
      const splitInput = input.split(/[\s,]+/).filter((el) => el.trim().length > 0);
      const searchIn = ['$User.firstname$', '$User.lastname$', '$User.email$'];

      return splitInput.map((el) => ({
        or: searchIn.map((arg) => ({
          [arg]: {
            regexp: `${el}`,
          },
        })),
      }));
    },

    handleSort(value) {
      if (this.selectedSort.value !== value) {
        this.$set(this.selectedSort, 'direction', 'desc');
      } else {
        this.$set(this.selectedSort, 'direction', (this.selectedSort.direction === 'asc') ? 'desc' : 'asc');
      }

      this.$set(this.selectedSort, 'value', value);
      this.updateList();
    },

    updateList() {
      this.offset = 0;
      this.fetchUsers();
    },

    loadMore() {
      this.offset += 1;
      this.fetchUsers();
    },

    fetchUsers() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          listUsers(this.queryFilter, token)
            .then((response) => response.data)
            .then(this.handleUserData);
        });
    },

    handleUserData({ data }) {
      if (this.offset <= 0) {
        this.$set(this, 'users', data.rows);
      } else {
        this.$set(this, 'users', [...this.users, ...data.rows]);
      }
    },
  },
  components: {
    Button,
    Icon,
    ChevronFullSVG,
    InputSelect,
    InputCheckbox,
    InputText,
    SearchSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-list.scss"></style>
