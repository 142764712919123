<template>
  <div class="leads-list">
    <Background></Background>

    <template v-if="loading">
      <div class="row mt-xxl">
        <div class="col-24 color-white text-center">
          <Icon class="-size-24"><LoaderSVG /></Icon>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="row mt-xs mb-m pos-relative">
        <div class="col-16 col-md-24">
          <h2 class="h2 mb-0 mt-0 color-white font-weight-normal" :class="{ 'font-size-xl  font-weight-medium': $matchMedia.xs }">{{ lead.title }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-18">
          <MatchList :leadId="lead.id" :matches="lead.matches" @update="fetchLead(false)"></MatchList>
        </div>
        <div class="col-6">
          <LeadInfos :id="lead.id" :lead="lead" @update="fetchLead(false)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MatchList from '@/components/modules/Lead/admin/MatchList.vue';
import LeadInfos from '@/components/modules/Lead/admin/LeadInfos.vue';
import Background from '@/components/atoms/Background.vue';
import Icon from '@/components/atoms/Icon.vue';
import { getLead } from '@/api/leads.api';
import LoaderSVG from '@/assets/icons/loader.svg';

export default {
  name: 'LeadDetail',

  data() {
    return {
      id: Number,
      lead: Object,
      loading: true,
    };
  },

  activated() {
    this.id = this.$route.params.id;
    this.$eventBus.$emit('visited-lead-detail', this.id);
    this.fetchLead();
  },

  methods: {
    fetchLead(showLoader = true) {
      this.loading = showLoader;

      this.$store.dispatch('isLoggedIn').then((token) => {
        getLead(this.id, token)
          .then(({ data }) => {
            this.$set(this, 'lead', data.data);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      });
    },
  },

  components: {
    MatchList,
    Background,
    LeadInfos,
    Icon,
    LoaderSVG,
  },
};
</script>
