<template>
  <div class="applications-list">
    <Background></Background>
    <div class="row  mt-xs  mb-m  pos-relative">
      <div class="col-24">
        <h2 class="h2  mb-0  mt-0  color-white  font-weight-normal">{{ $t('applicationList.headline') }}</h2>
      </div>
    </div>
    <ApplicationList role="manager" :defaultFilter="filter" :defaultSort="sort"></ApplicationList>
  </div>
</template>

<script>
import Background from '@/components/atoms/Background.vue';
import ApplicationList from '@/components/modules/User/ApplicationList.vue';

export default {
  name: 'Applications',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.savedPosition = window.event?.type === 'popstate';
    });
  },
  data() {
    return {
      filter: {
        status: 'open',
      },
      sort: {
        value: 'createdAt',
        direction: 'desc',
      },
      savedPosition: false,
    };
  },
  components: {
    ApplicationList,
    Background,
  },
};
</script>
