<template>
  <div class="accordion-content" :aria-disabled="disabled">
    <div
      class="handle"
      :class="{'disabled': disabled}"
      :aria-expanded="isOpen"
      :aria-controls="`content-${uniqueId}`"
      @click="toggleAccordion()"
    >
      <div class="title">
        <slot name="beforeTitle"/>
        <slot name="title"/>
        <slot name="afterTitle"></slot>
      </div>
      <Icon
        class="icon-expand -inline -right-0"
        :class="{'-rotate-180': isOpen,'-rotate-0': !isOpen}"
        v-show="!disabled">
        <ExpandMoreSVG/>
      </Icon>
    </div>

    <div class="content"
         :id="`content-${uniqueId}`"
         ref="content"
         :style="{ maxHeight: contentMaxHeight }"
         :class="{'mb-s': isOpen}"
         v-show="!disabled"
    >
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import Icon from '@/components/atoms/Icon.vue';

import ExpandMoreSVG from '@/assets/icons/expand_more.svg';

export default {
  name: 'AccordionContent',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    recommendation: String,
  },
  data() {
    return {
      isOpen: false,
      isFinished: false,
      uniqueId: null,
      contentMaxHeight: null,
    };
  },
  beforeMount() {
    this.uniqueId = _.uniqueId();
  },
  mounted() {
    this.isOpen = this.open;
    this.isFinished = this.finished;
    this.setContentMaxHeight();

    this.$eventBus.$on('richTextInputCreated', () => this.setContentMaxHeight());
    this.$eventBus.$on('richTextInputUpdated', () => this.setContentMaxHeight());
  },
  watch: {
    open() {
      if (this.isOpen !== this.open) {
        this.isOpen = this.open;
        this.$nextTick(() => this.setContentMaxHeight());
      }
    },
    isOpen() {
      this.setContentMaxHeight();
    },
  },
  methods: {
    toggleAccordion() {
      if (this.disabled) {
        return;
      }

      this.isOpen = !this.isOpen;
    },

    setContentMaxHeight() {
      if (this.isOpen) {
        this.contentMaxHeight = `${this.$refs.content.scrollHeight}px`;
      } else {
        this.contentMaxHeight = null;
      }
    },
  },
  components: {
    Icon,
    ExpandMoreSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/accordion-content.scss"></style>
