<template>
  <section class="subscribe-marketing-mails  mb-xs">
    <ContentBox>
      <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}" v-html="$t('subscribeMarketingMails.headline')"></h5>
      <p>{{ $t('subscribeMarketingMails.paragraph') }}</p>
      <InputToggleSwitch base="subscribeMarketingMails"
                         name="subscribed"
                         @input="updateSubscribed"
                         v-model="subscribed">
      </InputToggleSwitch>
    </ContentBox>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { subscribeMarketingMails } from '@/api/users.api';

import ContentBox from '@/components/elements/ContentBox.vue';
import InputToggleSwitch from '@/components/elements/inputs/InputToggleSwitch.vue';

export default {
  name: 'SubscribeMarketingMails',
  props: {
    user: Object,
  },
  data() {
    return {
      subscribed: false,
    };
  },
  computed: {
    ...mapState([
      'uuid',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    canEdit() {
      return this.userCan('edit', this.uuid) || this.userCan('updateDailyRate', this.uuid);
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.subscribed = this.user.subscribe_projects;
    },

    updateSubscribed(subscribe) {
      if (this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            subscribeMarketingMails(this.user.uuid, subscribe, token)
              .then(() => {
                this.$emit('update');
                this.$eventBus.$emit('notificate', {
                  message: this.$t('subscribeMarketingMails.messages.success.msg'),
                });
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', {
                  message: this.$t('subscribeMarketingMails.messages.error.msg'),
                  status: error.response.status,
                });
                this.subscribed = !subscribe;
              });
          });
      }
    },
  },
  components: {
    ContentBox,
    InputToggleSwitch,
  },
};
</script>
