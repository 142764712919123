<template>
  <section class="user-meta">
    <ContentBox>
      <div class="row  mb-s">
        <div class="col-20  valign-middle">
          <h4 class="h4  mb-0  subheadline font-component-headline">{{ $t('userMeta.headline') }}</h4>
        </div>
        <div class="col-4  text-right  valign-middle">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32"
                  v-if="canEdit"
                  @click.native="$eventBus.$emit('open-modal-userMetaEdit')">
            <Icon class="-inline  -pos-absolute-center">
              <EditSVG></EditSVG>
            </Icon>
          </Button>
        </div>
      </div>
      <div class="row">
        <div class="col-16  col-sm-24">
          <ContentBox>
            <h6 class="h6  font-weight-medium">{{ $t('userMeta.subheadings.personalData') }}</h6>
            <div class="row">
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">
                  {{ $t('userMeta.inputs.formalTitle.label') }}
                </div>
                <div class="_value">
                  {{ $t(`userMeta.inputs.formalTitle.values.${userMeta.formalTitle}`) }}
                </div>
              </div>
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.academicTitle.label') }}</div>
                <div class="_value">
                  {{ $t(`userMeta.inputs.academicTitle.values.${userMeta.academicTitle}`) }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12  col-xs-24  mb-xs-xs">
                <div class="label">{{ $t('userMeta.inputs.firstname.label') }}</div>
                <div class="_value">
                  {{ userMeta.firstname }}
                </div>
              </div>
              <div class="col-12  col-xs-24">
                <div class="label">{{ $t('userMeta.inputs.lastname.label') }}</div>
                <div class="_value">
                  {{ userMeta.lastname }}
                </div>
              </div>
            </div>
          </ContentBox>
          <ContentBox>
            <h6 class="h6  font-weight-medium">{{ $t('userMeta.subheadings.contactData') }}</h6>
            <div class="row">
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.street.label') }}</div>
                <div class="_value">
                  {{ userMeta.street }}
                </div>
              </div>
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.streetNumber.label') }}</div>
                <div class="_value">
                  {{ userMeta.streetNumber }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.zipcode.label') }}</div>
                <div class="_value">
                  {{ userMeta.zipcode }}
                </div>
              </div>
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.city.label') }}</div>
                <div class="_value">
                  {{ userMeta.city }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.country.label') }}</div>
                <div class="_value">
                  {{ country }}
                </div>
              </div>
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.email.label') }}</div>
                <div class="_value">
                  <AnchorLink :href="`mailto:${ userMeta.email }`"
                              class="-orange">
                    {{ userMeta.email }}
                  </AnchorLink>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.phone.label') }}</div>
                <div class="_value">
                  {{ userMeta.phone }}
                </div>
              </div>
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.mobile.label') }}</div>
                <div class="_value">
                  {{ userMeta.mobile }}
                </div>
              </div>
            </div>
          </ContentBox>
        </div>
        <div class="col-8  col-sm-24">
          <ContentBox>
            <div class="mb-s">
              <h6 class="h6  font-weight-medium">{{ $t('userMeta.subheadings.generalInfos') }}</h6>
              <div class="label">{{ $t('userMeta.generalInfos.photo') }}</div>
              <Avatar class="-size-150">
                <img :src="userAvatar">
              </Avatar>
            </div>
            <div>
              <div class="label">{{ $t('userMeta.inputs.birthday.label') }}</div>
              <h6 class="font-size-s  font-weight-medium">
                <template v-if="userMeta.birthday">
                  {{ formatDateToReadable(userMeta.birthday) }}, {{ calculateYears(new Date(userMeta.birthday)) }} {{ $t('userMeta.generalInfos.years.label') }}
                </template>
                <template v-else>
                  <i>{{ $t('userMeta.generalInfos.years.error') }}</i>
                </template>
              </h6>
            </div>
            <div>
              <div class="label">{{ $t('userMeta.inputs.nationality.label') }}</div>
              <h6 class="font-size-s  font-weight-medium">
                <template v-if="nationality">
                  {{ nationality }}
                </template>
                <template v-else>
                  {{ $t('userMeta.inputs.nationality.placeholder') }}
                </template>
              </h6>
            </div>
          </ContentBox>
        </div>
      </div>
    </ContentBox>
    <Modal id="userMetaEdit"
           :title="$t('userMeta.headline')"
           class="-thin"
           v-if="canEdit"
           @close="handleCloseModal">
      <FormElement>
        <ContentBox>
          <h6 class="h6  font-weight-medium">{{ $t('userMeta.subheadings.personalData') }}</h6>
          <div class="row">
            <div class="col-8  col-xs-24  mb-s">
              <div class="label  mb-xs">{{ $t('userMeta.generalInfos.photo') }}</div>
              <Avatar class="-size-150" @click.native="chooseAvatar"
                      :editable="canEdit">
                <img :src="userAvatar">
                <input type="file"
                       ref="avatarInput"
                       accept=".jpg,.png,.jpeg"
                       @change="uploadAvatar"
                       v-if="canEdit"
                       hidden>
              </Avatar>
              <div v-if="fileSizeError" class="mt-xs">
                <span class="color-error font-size-xs">{{ $t('userMeta.avatar.error.fileSize') }}</span>
              </div>
            </div>

            <div class="col-16 col-xs-24 pl-0 pr-0">
              <div class="col-12  col-xs-24  mb-s">
                <div class="label">
                  {{ $t('userMeta.inputs.formalTitle.label') }}
                </div>
                <InputSelect alt="small"
                             :class="{ '-has-error': $v.userMeta.formalTitle.$error }"
                             name="formalTitle"
                             base="userMeta"
                             :options="$t('signUpForm.inputs.formalTitle.options')"
                             @input="userEdited = true"
                             v-model="$v.userMeta.formalTitle.$model"></InputSelect>
              </div>
              <div class="col-12  col-xs-24  mb-s">
                <div class="label">{{ $t('userMeta.inputs.academicTitle.label') }}</div>
                <InputSelect alt="small"
                             :class="{ '-has-error': $v.userMeta.academicTitle.$error }"
                             name="academicTitle"
                             base="userMeta"
                             :options="$t('signUpForm.inputs.academicTitle.options')"
                             @input="userEdited = true"
                             v-model="$v.userMeta.academicTitle.$model"></InputSelect>
              </div>
              <div class="col-12  col-xs-24  mb-s">
                <div class="label">{{ $t('userMeta.inputs.firstname.label') }}</div>
                <InputText alt="small"
                           :class="{ '-has-error': $v.userMeta.firstname.$error }"
                           type="text"
                           name="firstname"
                           base="userMeta"
                           @input="userEdited = true"
                           v-model="$v.userMeta.firstname.$model"></InputText>
              </div>
              <div class="col-12  col-xs-24  mb-xs">
                <div class="label">{{ $t('userMeta.inputs.lastname.label') }}</div>
                <InputText alt="small"
                           :class="{ '-has-error': $v.userMeta.lastname.$error }"
                           type="text"
                           name="lastname"
                           base="userMeta"
                           @input="userEdited = true"
                           v-model="$v.userMeta.lastname.$model"></InputText>
              </div>
              <div class="col-12  col-xs-24  mb-s">
                <div class="label  mb-xxs">{{ $t('userMeta.inputs.birthday.label') }}</div>
                <InputText alt="small"
                           :class="{ '-has-error': $v.userMeta.birthday.$error }"
                           type="date"
                           name="birthday"
                           base="userMeta"
                           :emptyValue="null"
                           @input="userEdited = true"
                           v-model="$v.userMeta.birthday.$model"></InputText>
              </div>
              <div class="col-12  col-xs-24  mb-s">
                <div class="label  mb-xxs">{{ $t('userMeta.inputs.nationality.label') }}</div>
                <InputSelect alt="small"
                             :class="{ '-has-error': $v.userMeta.nationality.$error }"
                             name="nationality"
                             base="userMeta"
                             @input="userEdited = true"
                             v-model="$v.userMeta.nationality.$model">
                  <template slot="options">
                    <option selected value="">{{ $t('userMeta.inputs.nationality.placeholder') }}</option>
                    <optgroup :label="$t('nationalities.labels.popular')">
                      <option v-for="(nationality, key) in $t('nationalities.popular')" :key="key"
                              :value="key">
                        {{ nationality }}
                      </option>
                    </optgroup>
                    <optgroup :label="$t('nationalities.labels.default')">
                      <option v-for="(nationality, key) in $t('nationalities.default')" :key="key"
                              :value="key">
                        {{ nationality }}
                      </option>
                    </optgroup>
                  </template>
                </InputSelect>
              </div>
            </div>
          </div>
          <div class="row">

          </div>
        </ContentBox>
        <ContentBox>
          <h6 class="h6  font-weight-medium">{{ $t('userMeta.subheadings.contactData') }}</h6>
          <div class="row">
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.street.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.street.$error }"
                         type="text"
                         name="street"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.street.$model"></InputText>
            </div>
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.streetNumber.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.streetNumber.$error }"
                         type="text"
                         name="streetNumber"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.streetNumber.$model"></InputText>
            </div>
          </div>
          <div class="row">
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.zipcode.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.zipcode.$error }"
                         type="text"
                         name="zipcode"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.zipcode.$model"></InputText>
            </div>
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.city.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.city.$error }"
                         type="text"
                         name="city"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.city.$model"></InputText>
            </div>
          </div>
          <div class="row">
            <div class="col-12  col-xs-24  mb-s">
              <div class="label  mb-xxs">{{ $t('userMeta.inputs.country.label') }}</div>
              <InputSelect alt="small"
                            :class="{ '-has-error': $v.userMeta.country.$error }"
                            name="country"
                            base="userMeta"
                            @input="userEdited = true"
                            v-model="$v.userMeta.country.$model">
                <template slot="options">
                  <option selected value="">{{ $t('userMeta.inputs.country.placeholder') }}</option>
                  <optgroup :label="$t('countries.labels.popular')">
                    <option v-for="(country, key) in $t('countries.popular')" :key="key"
                            :value="key">
                      {{ country }}
                    </option>
                  </optgroup>
                  <optgroup :label="$t('countries.labels.default')">
                    <option v-for="(country, key) in $t('countries.default')" :key="key"
                            :value="key">
                      {{ country }}
                    </option>
                  </optgroup>
                </template>
              </InputSelect>
            </div>
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.email.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.email.$error }"
                         type="email"
                         name="email"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.email.$model"></InputText>
            </div>
          </div>
          <div class="row">
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.phone.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.phone.$error }"
                         type="phone"
                         name="phone"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.phone.$model"></InputText>
            </div>
            <div class="col-12  col-xs-24  mb-xs">
              <div class="label">{{ $t('userMeta.inputs.mobile.label') }}</div>
              <InputText alt="small"
                         :class="{ '-has-error': $v.userMeta.mobile.$error }"
                         type="phone"
                         name="mobile"
                         base="userMeta"
                         @input="userEdited = true"
                         v-model="$v.userMeta.mobile.$model"></InputText>
            </div>
          </div>
        </ContentBox>
      </FormElement>
      <template slot="footer">
        <div class="text-right">
          <Button class="button -orange -outlined mr-s" @click.native="$eventBus.$emit('close-modal-userMetaEdit')">
            {{ $t('userMeta.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="saveAndExit">
            {{ $t('userMeta.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, email, numeric,
} from 'vuelidate/lib/validators';
import { updateUser, updateUserAsset } from '@/api/users.api';
import parseDates from '@/mixins/parseDates';
import userAssets from '@/mixins/userAssets';
import formatNumbers from '@/mixins/formatNumbers';

import AnchorLink from '@/components/atoms/Link.vue';
import Avatar from '@/components/atoms/Avatar.vue';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Modal from '@/components/elements/Modal.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import FormElement from '@/components/elements/FormElement.vue';

import EditSVG from '@/assets/icons/edit.svg';

const validCity = (value) => new RegExp("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$", 'gi').test(value);
const validZipcodeLength = (value, vm) => {
  const allowedLengths = {
    DE: 5,
    AT: 4,
    CH: 4,
  };
  const testLength = allowedLengths[vm.country];
  return (testLength) ? value.toString().length === testLength : true;
};

export default {
  mixins: [parseDates, validationMixin, userAssets, formatNumbers],
  name: 'UserMeta',
  props: {
    user: Object,
    avatar: String,
  },
  data() {
    return {
      userEdited: false,
      userMeta: {},
      fileSizeError: false,
    };
  },
  computed: {
    ...mapState([
      'uuid',
      'group',
    ]),

    ...mapGetters([
      'userCan',
    ]),

    userAvatar() {
      // eslint-disable-next-line
      return this.avatar || require('@/assets/default-avatar.png');
    },

    websiteUrl() {
      let url = this.userMeta.website;

      if (url) {
        try {
          url = new URL(this.userMeta.website);
        } catch (e) {
          try {
            url = new URL(`https://${this.userMeta.website}`);
          } catch (err) {
            return '';
          }
        }
      }

      return url;
    },

    nationality() {
      return { ...this.$t('nationalities.popular'), ...this.$t('nationalities.default') }[this.userMeta.nationality];
    },

    country() {
      return { ...this.$t('countries.popular'), ...this.$t('countries.default') }[this.userMeta.country];
    },

    canEdit() {
      return this.userCan('edit', this.user.uuid) || this.userCan('updateUserMeta');
    },
  },
  validations: {
    userMeta: {
      email: { required, email },
      formalTitle: { required },
      academicTitle: {},
      firstname: { required },
      lastname: { required },
      phone: {},
      mobile: {},
      street: {},
      streetNumber: {},
      zipcode: { required, numeric, validZipcodeLength },
      city: { required, validCity },
      country: { required },
      website: {},
      birthday: {},
      nationality: {},
    },
  },
  mounted() {
    this.initMeta();
    this.$v.$touch();
  },
  watch() {

  },
  methods: {
    initMeta() {
      this.userMeta = {
        formalTitle: this.user.formalTitle,
        academicTitle: this.user.academicTitle,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.email,
        phone: this.user.phone,
        mobile: this.user.mobile,
        street: this.user.street,
        streetNumber: this.user.streetNumber,
        zipcode: this.user.zipcode,
        city: this.user.city,
        country: this.user.country,
        group: this.user.group,
        website: this.user.website || '',
        birthday: (this.user.birthday) ? this.user.birthday.split('T')[0] : null,
        nationality: this.user.nationality,
      };
    },

    handleCloseModal() {
      if (this.userEdited) {
        this.initMeta();
      }
    },

    saveAndExit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateUserMeta();
      }
    },

    updateUserMeta() {
      if (this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            updateUser(this.user.uuid, this.userMeta, token)
              .then(() => {
                this.userEdited = false;
                this.$emit('update');
                this.$eventBus.$emit('notificate');
                this.$eventBus.$emit('close-modal-userMetaEdit');
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              });
          });
      }
    },

    chooseAvatar() {
      if (this.canEdit) {
        this.fileSizeError = false;
        this.$refs.avatarInput.dispatchEvent(new MouseEvent('click'));
      }
    },

    uploadAvatar(e) {
      if (this.canEdit) {
        const file = e.target.files.item(0);
        const formData = new FormData();

        if (file.size <= 2097152) {
          formData.append('avatar', file);
          this.$store.dispatch('isLoggedIn')
            .then((token) => {
              updateUserAsset(this.user.uuid, 'avatar', formData, token)
                .then(() => {
                  this.$emit('update');
                  this.$eventBus.$emit('notificate');
                })
                .catch((error) => {
                  console.error(error);
                  this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
                });
            });
        } else {
          this.fileSizeError = true;
        }
      }
    },
  },
  components: {
    AnchorLink,
    Avatar,
    Button,
    Icon,
    ContentBox,
    FormElement,
    Modal,
    InputSelect,
    InputText,
    EditSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-meta.scss"></style>
