import { render, staticRenderFns } from "./LeadList.vue?vue&type=template&id=4a95448a&scoped=true&"
import script from "./LeadList.vue?vue&type=script&lang=js&"
export * from "./LeadList.vue?vue&type=script&lang=js&"
import style0 from "@/sass/08_modules/lead-list.scss?vue&type=style&index=0&id=4a95448a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a95448a",
  null
  
)

export default component.exports