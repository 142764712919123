<template>
  <div class="select-wrapper" :class="`-${phaseSelected}`">
    <select name="matchPhase" id="matchPhase" class="tag -slim match-phase-select text-truncate" v-model="phaseSelected" v-on:click.stop v-on:change="updatePhase">
      <option v-for="(option, key) in $t('matchPhaseSelect.values')" :key="key" :value="key">{{ option }}</option>
    </select>
  </div>
</template>

<script>
import { updatePhase } from '@/api/matches.api';

export default {
  props: {
    id: Number,
    phase: {
      type: String,
      default: 'found',
    },
    save: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      phaseSelected: this.phase,
    };
  },
  methods: {
    updatePhase() {
      if (this.save) {
        this.$store.dispatch('isLoggedIn').then((token) => {
          updatePhase(this.id, this.phaseSelected, token)
            .then(() => {
              this.$eventBus.$emit('notificate', { title: this.$t('notifications.matchActions.updatePhase.title'), message: this.$t('notifications.matchActions.updatePhase.message.label') });
              this.$emit('update');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
      }

      this.$emit('update:phase', this.phaseSelected);
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/match-phase-select.scss"></style>
