<template>
  <button type="submit" class="button  -submit">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Submit',
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/button.scss"></style>
