<template>
  <div class="user-list">
    <div class="_filter">
      <div class="_filter-item">
        <InputText
          name="search"
          base="usersList.filter"
          class="_search  -light"
          v-model="selectedFilter.search"
          @input="updateList"
          type="text">
          <Icon class="-size-20"><SearchSVG></SearchSVG></Icon>
        </InputText>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.availability"
                     :options="getFilterOptions('availability')"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('availability', $event)"
                     @select="handleFilter('availability', $event)">
          <template slot="option" slot-scope="{ option }">
            <span>{{ $t(`usersList.filter.inputs.availability.options.${option}`) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.availability.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.group"
                     :options="getFilterOptions('groups')"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('group', $event)"
                     @select="handleFilter('group', $event)">
          <template slot="option" slot-scope="{ option }">
            <span>{{ $t(`usersList.filter.inputs.groups.options.${option}`) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.groups.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.industries"
                     ref="industriesMultiselect"
                     :options="industriesOptions"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('industries', $event)"
                     @select="handleFilter('industries', $event)">
          <template slot="beforeList">
            <div class="_option-search" v-if="false">
              <InputText
                name="industriesSearch"
                base="usersList.filter"
                class="_search  -light"
                v-model="industriesSearch"
                type="text">
                <Icon class="-size-18"><SearchSVG></SearchSVG></Icon>
              </InputText>
            </div>
          </template>
          <template slot="option" slot-scope="{ option }">
            <span :class="[ option.split('_').length > 1 ? '_sub' : '_main' ]">{{ getCategoryLabel('industries', option) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.industries.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Multiselect v-model="selectedFilter.operationals"
                     ref="operationalsMultiselect"
                     :options="operationalsOptions"
                     :multiple="true"
                     :searchable="false"
                     :closeOnSelect="false"
                     :showLabels="false"
                     placeholder=""
                     :max-height="400"
                     @remove="handleFilter('operationals', $event)"
                     @select="handleFilter('operationals', $event)">
          <template slot="beforeList">
            <div class="_option-search" v-if="false">
              <InputText
                name="operationalsSearch"
                base="usersList.filter"
                class="_search  -light"
                v-model="operationalsSearch"
                type="text">
                <Icon class="-size-18"><SearchSVG></SearchSVG></Icon>
              </InputText>
            </div>
          </template>
          <template slot="option" slot-scope="{ option }">
            <span :class="[ option.split('_').length > 1 ? '_sub' : '_main' ]">{{ getCategoryLabel('operationals', option) }}</span>
          </template>
          <template slot="selection">
            {{ $t('usersList.filter.inputs.operationals.label') }}
            <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
          </template>
        </Multiselect>
      </div>
      <div class="_filter-item">
        <Button class="-white  -outlined" @click.prevent.native="$eventBus.$emit('open-modal-managerCategorization')">{{ $t('usersList.filter.inputs.categorization.label') }}</Button>
        <ManagerCategorizationModal :categorization="selectedFilter.categorization"
                                    @update="handleCategorizationModalUpdate">
        </ManagerCategorizationModal>
      </div>
      <div class="_filter-item">
        <div class="_fulltext-search">
          <Multiselect
                :value="selectedFilter.fulltextSearch"
                :options="fulltextOptions"
                :searchable="true"
                :multiple="true"
                :taggable="true"
                :show-labels="false"
                :hideSelected="true"
                :close-on-select="true"
                :options-limit="100"
                :placeholder="$t('usersList.filter.inputs.fulltextSearch.placeholder')"
                tag-placeholder=""
                :selectLabel="''"
                label="name"
                track-by="name"
                :internal-search="false"
                @search-change="findFulltextSearchTag"
                @tag="addFulltextSearchTag"
                @select="addFulltextSearchTag"
                ref="fulltextSearchMultiselect"
                class="multiselect-fulltext"
              >
              <template slot="option" slot-scope="props">
                <div class="_option">
                  <template v-if="props.option.isTag">
                    {{ props.option.label }}
                  </template>
                  <template v-else>
                    {{ props.option.name }}
                  </template>
                </div>
              </template>
              <template slot="selection">
                <Icon class="-inline  -is-right"><ChevronSVG></ChevronSVG></Icon>
              </template>
              <template slot="tag">{{ '' }}</template>
              <template slot="placeholder">{{ '' }}</template>
              <template slot="noOptions">
                <div class="_option">{{ $t('usersList.filter.inputs.fulltextSearch.placeholder') }}</div>
              </template>
          </Multiselect>
        </div>
      </div>
      <div class="_filter-item">
        <InputText
          name="zipcode"
          base="usersList.filter"
          class="_search -light"
          v-model="zipSearch"
          @keyup.native.enter="handleZipEntered"
          type="text">
          <Icon class="-size-20 -clickable" @click.native="handleZipEntered"><ReturnSVG></ReturnSVG></Icon>
        </InputText>
      </div>
    </div>
    <div class="row  mb-s">
      <div class="col-24">
        <div class="_filter-info">
          <span class="font-weight-bold">{{ $tc('usersList.results', count) }}</span>
          <InputTag class="-light"
                    v-for="tag in getTags('availability')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('availability', $event)">
            {{ $t(`usersList.filter.inputs.availability.options.${tag}`) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="tag in getTags('group')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('group', $event)">
            {{ $t(`usersList.filter.inputs.groups.options.${tag}`) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="(tag, index) in getTags('industries')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('industries', $event)">
            <template v-if="index === 0">{{ $t('usersList.filter.inputs.industries.label') }}:</template>
            {{ getCategoryLabel('industries', tag) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="(tag, index) in getTags('operationals')"
                    :key="tag"
                    :value="tag"
                    @remove="handleRemoveSelected('operationals', $event)">
            <template v-if="index === 0">{{ $t('usersList.filter.inputs.operationals.label') }}:</template>
            {{ getCategoryLabel('operationals', tag) }}
          </InputTag>
          <InputTag class="-light"
                    v-for="(tag, index) in getTags('fulltextSearch')"
                    :key="tag.id"
                    :value="tag.name"
                    @remove="() => handleRemoveSelected('fulltextSearch', tag)">
            <template v-if="index === 0">{{ $t('usersList.filter.inputs.fulltextSearch.label') }}:</template>
            {{ tag.name }}
          </InputTag>
          <InputTag class="-light"
                    v-for="tag in getTags('zipcode')"
                    :key="tag.like"
                    :value="tag.like"
                    @remove="handleRemoveSelected('zipcode', tag)">
            {{ tag.like.replace('%', (6 > tag.like.length ? '*' : '')) }}
          </InputTag>
          <span v-if="Object.keys(selectedFilter).length > 0">
            <a class="_remove-all" @click="handleRemoveAll">{{ $t('usersList.filter.removeAll') }}</a>
          </span>
          <span v-if="loading">
            <Icon class="-size-24"><LoadingSVG></LoadingSVG></Icon>
          </span>
        </div>
      </div>
    </div>
    <div class="row  user-list-head">
          <div class="col-5">
            <a class="label" @click="handleSort('User.lastname')">
              {{ $t('usersList.fields.manager.title') }}
              <Icon v-if="selectedSort.value === 'User.lastname'"
                    :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-4">
            <a class="label" @click="handleSort('availability')">
              {{ $t('usersList.fields.status.label') }}
              <Icon v-if="selectedSort.value === 'availability'"
                    :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-4">
            <a class="label" @click="handleSort('partner,am,group')">
              {{ $t('usersList.fields.group.label') }}
              <Icon v-if="selectedSort.value === 'partner,am,group'"
                    :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-3">
            <a class="label" @click="handleSort('dailyRate')">
              {{ $t('usersList.fields.dailyRate.label') }}
              <Icon v-if="selectedSort.value === 'dailyRate'"
                    :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
          </div>
          <div class="col-4">
            <div v-if="isAdminUser" class="label">
              <a class="label" @click="handleSort('averageRating')">
                {{ $t('usersList.fields.rating.label') }}
                <Icon v-if="selectedSort.value === 'averageRating'"
                      :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                  <ChevronFullSVG></ChevronFullSVG>
                </Icon>
              </a>
            </div>
          </div>
          <div class="col-3">
            <a class="label" @click="handleSort('updatedAt')">
              {{ $t('usersList.fields.lastUpdate.label') }}
              <Icon v-if="selectedSort.value === 'updatedAt'"
                    :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
                <ChevronFullSVG></ChevronFullSVG>
              </Icon>
            </a>
      </div>
    </div>
    <div class="user-list-wrapper">
      <router-link class="row  user-item" v-for="user in users" :key="user.User.uuid" :to="{
              name: 'UserDetail',
              params: {
                username: user.username,
                lang: $i18n.locale
              },
              query: {
                keywords: encodeURIComponent(getFulltextSearchString(',', true)),
              },
            }" :class="{ '-last-visited': user.username === lastVisited }">
        <div class="col-5">
          <span class="_name font-weight-semibold">{{ user.User.lastname }}, {{ user.User.firstname }}</span>
        </div>
        <div class="col-4">
          <div class="font-weight-medium"></div>
          <Status :statusKey="getAvailabilityKey(user)"></Status>
        </div>
        <div class="col-4">
          <Tag :class="['-slim  -no-margin-bottom', getGroupTagClass(getUserGroup(user))]">
            {{ $t(`groups.${getUserGroup(user)}`) }}
          </Tag>
          <Tag v-if="user.partner_potential && !user.partner" :class="['-slim  -no-margin-bottom', getGroupTagClass('executivePartnerPotential')]">
            {{ $t(`groups.executivePartnerPotential`) }}
          </Tag>
        </div>
        <div class="col-3">
          <div class="font-weight-medium">{{ user.dailyRate | toCurrency }}</div>
        </div>
        <div class="col-4">
          <StarRating v-if="isAdminUser" :rating="user.averageRating" :increment="0.5" :show-rating="false" :star-size="16" :padding="4" :read-only="true" active-color="#F5AE2A" inactive-color="#e5e8ea" />
        </div>
        <div class="col-2">
          <div class="font-weight-medium">{{ user.updatedAt | formatDate }}</div>
        </div>
        <div class="col-1 pos-relative text-right">
          <div v-if="user.documents && user.documents.some(pdf => pdf.mimetype === 'application/pdf')"
            @click.prevent="() => openPdfViewerModal(user.uuid, user.documents)"
          >
            <Icon class="-size-40  -outlined  p-xxs-less  -bg-white" ><PdfSVG></PdfSVG></Icon>
          </div>
        </div>
        <div class="col-1  pos-relative  text-right">
          <FlyoutNav class="mt-xs" :ref="`flyout${user.username}`">
            <ActionsNavAdmin :uuid="user.User.uuid"
                             :userId="user.id"
                             :username="user.username"
                             :group="user.group"
                             :role="role"
                             :partner_potential="user.partner_potential"
                             :partner="user.partner"
                             :active="user.User.active"
                             @update="updateList"
            />
          </FlyoutNav>
          <FlyoutTrigger :flyoutEl="`flyout${user.username}`" :parentRefs="$refs">
            <Icon class="-size-40  -outlined  p-xxs-less  -bg-white"><DotsSVG></DotsSVG></Icon>
          </FlyoutTrigger>
        </div>
      </router-link>
    </div>
    <div v-if="users && users.length <= 0" class="mt-m">
      <h5 class="h5">{{ $t('usersList.noResults') }}</h5>
    </div>
    <div v-if="users && users.length === (offset+1) * limit" class="mt-m  text-center">
      <Button class="-orange" @click.native="loadMore">{{ $t('usersList.loadMore') }}</Button>
    </div>

    <!-- TODO: Refactor to own PdfViewerModal component which can handle multiple files -->
    <Modal ref="pdfViewerModal" class="pdfViewerModal" title="Manager CV" :closeOnEsc="true" :closeOnBgClick="true" @close="onClosePdfViewerModal">
      <div v-if="pdfViewerFiles.length > 1" class="pdfViewerModal-buttons">
        <Button v-for="pdf in pdfViewerFiles"
          :key="pdf.filename"
          class="-compact"
          @click.native="pdfViewerFile = pdf.filename"
          :class="{ 'is-active': pdfViewerFile === pdf.filename }">
          {{ pdf.filename }}
        </Button>
      </div>
      <PdfViewer :file="pdfViewerFile" :manager_uuid="pdfViewerManagerUuid" :highlightWords="getFulltextSearchString(' ', true)" />
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import StarRating from 'vue-star-rating';
import { listUsers } from '@/api/admins.api';
import {
  parseDates,
  queryObjects,
  arrayUtils,
  objectUtils,
} from '@/mixins';
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Status from '@/components/atoms/Status.vue';
import ChevronFullSVG from '@/assets/icons/chevron-full.svg';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputTag from '@/components/elements/inputs/InputTag.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import ActionsNavAdmin from '@/components/elements/ActionsNavAdmin.vue';
import ManagerCategorizationModal from '@/components/modules/User/admin/ManagerCategorizationModal.vue';

import SearchSVG from '@/assets/icons/search.svg';
import PdfSVG from '@/assets/icons/pdf.svg';
import ReturnSVG from '@/assets/icons/return.svg';
import Modal from '../../elements/Modal.vue';
import PdfViewer from '../../elements/PdfViewer.vue';

export default {
  name: 'UserList',
  mixins: [parseDates, queryObjects, formatNumbers, arrayUtils, objectUtils],
  props: {
    role: String,
    defaultFilter: Object,
    defaultSort: Object,
    lastVisited: String,
  },
  data() {
    return {
      users: [],
      userMeta: {},
      selectedFilter: { ...this.getQueryFilter() },
      selectedSort: {},
      complexFilterOptions: {
        group: {
          pending: { group: { eq: 'pending' } },
          expert: { group: { eq: 'expert' }, partner: { eq: '0' } },
          executive: { group: { eq: 'executive' }, partner: { eq: '0' } },
          partner_potential: {
            group: {
              or: [{
                eq: 'executive',
              }, {
                eq: 'pending',
              }],
            },
            partner_potential: { eq: 1 },
            partner: { eq: '0' },
          },
          partner: { group: { eq: 'executive' }, partner: { eq: '1' } },
        },
        availability: {
          available: { availability: { eq: true }, admin_status: { eq: null } },
          notAvailable: { availability: { eq: false }, availability_reason: { or: [{ eq: null }, { eq: 'none' }] }, admin_status: { eq: null } },
          inProject: { availability: { eq: false }, availability_reason: { eq: 'inProject' }, admin_status: { eq: null } },
          inEmployment: { availability: { eq: false }, availability_reason: { eq: 'inEmployment' }, admin_status: { eq: null } },
          retired: { availability: { eq: false }, availability_reason: { eq: 'retired' }, admin_status: { eq: null } },
          blacklisted: { admin_status: { eq: 'blacklisted' } },
          check: { admin_status: { eq: 'check' } },
        },
      },
      filterOptions: {},
      limit: 50,
      offset: 0,
      count: 0,
      debounce: null,
      loading: true,
      industriesSearch: '',
      operationalsSearch: '',
      zipSearch: '',
      fulltextOptions: [],
      fulltextCollator: new Intl.Collator(this.$i18n.locale),
      pdfViewerManagerUuid: null,
      pdfViewerFile: null,
      pdfViewerFiles: [],
    };
  },
  computed: {
    ...mapState([
      'usermeta',
    ]),
    ...mapGetters([
      'availableSkills',
    ]),

    sort() {
      return { ...this.defaultSort, ...this.selectedSort };
    },

    industriesOptions() {
      return this.getCategoryOptions('industries', this.industriesSearch);
    },

    operationalsOptions() {
      return this.getCategoryOptions('operationals', this.operationalsSearch);
    },
    isAdminUser() {
      return this.usermeta.role === 'admin';
    },
  },
  activated() {
    if (!this.$parent.savedPosition) {
      this.$store.dispatch('fetchAvailableSkills');
      this.updateList();
    }
  },
  methods: {
    getCategoryOptions(category, search = '') {
      const categories = this.$t(`categories.${category}`);
      const searchRegex = new RegExp(search, 'i');

      return Object.keys(categories.sub).flatMap((mainKey) => [
        ...((categories.main[mainKey].match(searchRegex) || Object.values(categories.sub[mainKey]).find((el) => el.match(searchRegex))) ? [mainKey] : []),
        ...Object.keys(categories.sub[mainKey]).filter((subKey) => categories.sub[mainKey][subKey].match(searchRegex)).map((subKey) => `${mainKey}_${subKey}`),
      ]);
    },

    getTags(filter) {
      return _.concat([], this.selectedFilter[filter] ?? []);
    },

    getUserGroup(user) {
      if (user.partner) {
        return 'partner';
      }

      return user.group;
    },

    getGroupTagClass(groupType) {
      switch (groupType) {
        case 'expert':
          return '-blue';
        case 'executive':
          return '-green';
        case 'executivePartnerPotential':
          return '-yellow';
        case 'partner':
          return '-orange';
        default:
          return '-grey';
      }
    },

    getFilterOptions(filter) {
      if (['industries', 'operationals'].includes(filter)) {
        const categories = this.$t(`categories.${filter}`);

        return Object.keys(categories.sub).flatMap((main) => [
          main,
          ...Object.keys(categories.sub[main]).map((sub) => `${main}_${sub}`),
        ]);
      }

      return Object.keys(this.$t(`usersList.filter.inputs.${filter}.options`));
    },

    getAvailabilityKey(user) {
      if (user.admin_status) return user.admin_status;
      if (user.availability) return 'available';
      if (!user.availability_reason || user.availability_reason === 'none') return 'notAvailable';

      return user.availability_reason;
    },

    getCategoryLabel(category, value = '') {
      const split = value.split('_');

      if (split.length > 1) {
        return this.$t(`categories.${category}.sub.${split[0]}.${split[1]}`);
      }

      return this.$t(`categories.${category}.main.${split[0]}`);
    },

    getQueryFilter() {
      const {
        availability,
        group,
        industries,
        operationals,
        sort,
        fulltextSearch,
        zipcode,
        ...query
      } = this.$route.query;

      return {
        ...(availability && { availability: _.concat([], availability) }),
        ...(group && { group: _.concat([], group) }),
        ...(industries && { industries: _.concat([], industries) }),
        ...(operationals && { operationals: _.concat([], operationals) }),
        ...(fulltextSearch && { fulltextSearch: _.concat([], fulltextSearch) }),
        ...(zipcode && { zipcode: _.concat([], zipcode) }),
        ...query,
      };
    },

    getFilter() {
      const {
        search,
        industries,
        operationals,
        group,
        availability,
        am,
        partner,
        categorization,
        zipcode,
        ...filter
      } = this.selectedFilter;

      // remove fulltextSearch because its added by getFilterQuery already
      delete filter.fulltextSearch;

      const multiSelectFilter = {
        ...(group && group.length > 0) && { group },
        ...(availability && availability.length > 0) && { availability },
        ...(zipcode && zipcode.length > 0) && { zipcode },
      };

      return {
        ...this.defaultFilter,
        ...(!!search || !!industries || !!operationals || Object.keys(multiSelectFilter).length > 0 || !!categorization) && {
          query: {
            and: [
              ...((search) ? this.buildSearchFilter(search) : []),
              ...((industries) ? this.buildCategoryFilter('industries', industries) : []),
              ...((operationals) ? this.buildCategoryFilter('operationals', operationals) : []),
              ...((Object.keys(multiSelectFilter).length > 0) ? this.buildMultiSelectFilter(multiSelectFilter) : []),
              ...((categorization && Object.keys(categorization).length > 0) ? this.buildManagerCategorizationFilter(categorization) : []),
            ],
          },
        },
        ...this.buildComplexFilter(filter),
      };
    },

    getFilterQuery() {
      let scope = 'usersList';
      const filter = this.getFilter();

      if (this.selectedFilter.availability?.includes('inactive')) {
        delete filter.status;
        scope = 'inactiveUsersList';
      }

      return {
        role: this.role,
        ...this.buildFilterQuery(filter, this.filterOptions),
        ...this.buildSortQuery(this.sort),
        limit: this.limit,
        offset: this.offset,
        scope,
        fulltextSearch: this.getFulltextSearchString(','),
      };
    },

    buildComplexFilter(inputs) {
      let filterArgs = {};

      Object.keys(inputs).forEach((arg) => {
        const filterValues = inputs[arg];

        if (this.complexFilterOptions[arg]) {
          if (Array.isArray(inputs[arg])) {
            filterValues.forEach((value) => {
              Object.keys(this.complexFilterOptions[arg][value] || {}).forEach((complexArg) => {
                filterArgs[complexArg] = [...new Set([...filterArgs[complexArg] || [], ...[this.complexFilterOptions[arg][value][complexArg]]])];
              });
            });
          } else {
            filterArgs = { ...filterArgs, ...this.complexFilterOptions[arg] };
          }
        } else {
          filterArgs[arg] = filterValues;
        }
      });

      return filterArgs;
    },

    buildMultiSelectFilter(inputs) {
      const filterArgs = [];

      Object.keys(inputs).forEach((arg) => {
        const filterValues = (Array.isArray(inputs[arg])) ? [...inputs[arg]] : [inputs[arg]];
        const filterGroupArgs = { or: [] };

        if (this.complexFilterOptions[arg]) {
          filterValues.forEach((value) => {
            if (this.complexFilterOptions[arg][value]) {
              filterGroupArgs.or.push({ ...this.complexFilterOptions[arg][value] });
            }
          });
        } else {
          filterValues.forEach((value) => {
            filterGroupArgs.or.push({ [arg]: value });
          });
        }

        if (filterGroupArgs.or.length > 0) filterArgs.push(filterGroupArgs);
      });

      return filterArgs;
    },

    buildSearchFilter(input) {
      const splitInput = input.split(/[\s,]+/).filter((el) => el.trim().length > 0);
      const searchIn = ['$User.firstname$', '$User.lastname$', '$User.email$'];

      return splitInput.map((el) => ({
        or: searchIn.map((arg) => ({
          [arg]: {
            regexp: `${el}`,
          },
        })),
      }));
    },

    buildCategoryFilter(category, input = []) {
      const main = input.filter((el) => !el.includes('_'));
      const sub = input.filter((el) => el.includes('_'));

      return (input.length > 0) ? [{
        or: [
          ...main.map((arg) => ({
            [`main_${category}`]: {
              regexp: arg,
            },
          })),
          ...sub.map((arg) => ({
            [`sub_${category}`]: {
              regexp: arg,
            },
          })),
        ],
      }] : [];
    },

    buildManagerCategorizationFilter(inputs) {
      const catArgs = [];

      Object.keys(inputs).forEach((arg) => {
        const filterValues = (Array.isArray(inputs[arg])) ? [...inputs[arg]] : [inputs[arg]];

        if (arg.includes('daily_price')) {
          catArgs.push({
            [`$categorization.${arg}$`]: { lte: filterValues },
          });
        } else {
          catArgs.push({
            [`$categorization.${arg}$`]: { regexp: filterValues.join('|') },
          });
        }
      });

      return catArgs;
    },

    updateList() {
      this.offset = 0;
      this.fetchUsers();
      this.updateQueryParams();
    },

    loadMore() {
      this.offset += 1;
      this.fetchUsers();
    },

    fetchUsers() {
      this.loading = true;
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          listUsers(this.getFilterQuery(), token)
            .then((response) => response.data)
            .then(this.handleUserData);
        });
    },

    updateQueryParams() {
      if (!_.isEqual(this.getQueryFilter(), this.selectedFilter)) {
        this.$router.replace({
          name: 'Users',
          query: this.selectedFilter,
          params: { lang: this.$i18n.locale },
        }).catch(() => {
          // prevent 'NavigationDuplicated' error: https://github.com/vuejs/vue-router/issues/2872
        });
      }
    },

    handleUserData({ data }) {
      this.count = data.count;

      if (this.offset <= 0) {
        this.$set(this, 'users', data.rows);
      } else {
        this.$set(this, 'users', [...this.users, ...data.rows]);
      }

      this.loading = false;
    },

    handleSort(value) {
      if (this.selectedSort.value !== value) {
        this.$set(this.selectedSort, 'direction', 'desc');
      } else {
        this.$set(this.selectedSort, 'direction', (this.selectedSort.direction === 'asc') ? 'desc' : 'asc');
      }

      this.$set(this.selectedSort, 'value', value);
      this.updateList();
    },

    handleFilter() {
      clearTimeout(this.debounce);

      this.loading = true;
      this.debounce = setTimeout(() => {
        this.updateList();
      }, 1000);
    },

    handleZipEntered() {
      if (this.zipSearch === '') return;

      if (!this.selectedFilter.zipcode) {
        this.$set(this.selectedFilter, 'zipcode', []);
      }

      const zipValue = `${this.zipSearch}%`;

      if (this.selectedFilter.zipcode.find((el) => el.like === zipValue)) {
        return;
      }

      this.selectedFilter.zipcode.push({ like: zipValue });
      this.zipSearch = '';

      this.updateList();
    },

    handleCategorizationModalUpdate(categories) {
      this.$set(this.selectedFilter, 'categorization', this.filterObj(categories, (el) => el > 0 || el.length > 0));
      this.$eventBus.$emit('close-modal-managerCategorization');
      this.updateList();
    },

    handleRemoveSelected(field, value) {
      this.$set(this.selectedFilter, field, this.removeFromArray(this.selectedFilter[field], value));
      this.updateList();
    },

    handleRemoveAll() {
      this.selectedFilter = {};
      this.updateList();
    },

    findFulltextSearchTag(query) {
      if (query === '') {
        this.fulltextOptions = [];
      } else {
        const queryWithoutOperators = this.removeSearchOperators(query);

        let prefix = '';
        let suffix = '';

        // check for suffix search operator
        if (['*'].includes(query.charAt(query.length - 1))) {
          suffix = query.charAt(query.length - 1);
        }

        // check for prefix search operator
        if (['+', '-', '~', '<', '>'].includes(query.charAt(0))) {
          prefix = query.charAt(0);

          if (prefix === '"') {
            suffix += '"';
          } else if (query.charAt(1) === '"') {
            prefix += '"';
            suffix += '"';
          }
        }

        this.fulltextOptions = this.availableSkills
          .filter((skill) => skill.name.toLowerCase().search(queryWithoutOperators.toLowerCase()) > -1)
          .map((skill) => ({
            ...skill,
            name: `${prefix}${skill.name}${suffix}`,
          }))
          .sort(this.fulltextCollator.compare);
      }
    },

    getFulltextSearchString(separator = ' ', removeOperators = false) {
      if (!this.selectedFilter.fulltextSearch) return '';

      if (removeOperators) {
        return this.selectedFilter.fulltextSearch
          .map((tag) => this.removeSearchOperators(tag.name))
          .join(separator);
      }
      return this.selectedFilter.fulltextSearch.map((tag) => tag.name).join(separator);
    },

    addFulltextSearchTag(tag) {
      const fulltextSearch = _.cloneDeep(this.selectedFilter.fulltextSearch) ?? [];
      const newTag = (typeof tag !== 'object') ? { name: tag, id: tag } : tag;
      const isInArray = fulltextSearch.some((filterTag) => filterTag.name === newTag.name);

      if (!isInArray) {
        fulltextSearch.push(newTag);
        this.$set(this.selectedFilter, 'fulltextSearch', fulltextSearch);
        this.updateList();
      }
    },

    removeSearchOperators(query) {
      // removes [+,",-,~,<,>] from beginning and [*] from end
      return query
        // eslint-disable-next-line no-useless-escape
        .replace(new RegExp('^[\+\\-~<>]?', 'g'), '')
        // eslint-disable-next-line no-useless-escape
        .replace(new RegExp('[\*]?$', 'g'), '');
    },

    openPdfViewerModal(managerUuid, documents) {
      this.pdfViewerManagerUuid = managerUuid;
      this.pdfViewerFiles = documents.filter((doc) => doc.mimetype === 'application/pdf');
      const [firstFile] = this.pdfViewerFiles;
      this.pdfViewerFile = firstFile.filename;
      this.$refs.pdfViewerModal.open();
    },

    showPdfInViewer(file) {
      this.pdfViewerFile = file;
    },

    onClosePdfViewerModal() {
      this.pdfViewerFile = null;
      this.pdfViewerManagerUuid = null;
    },
  },
  components: {
    Button,
    Icon,
    Status,
    ChevronFullSVG,
    InputText,
    InputTag,
    Multiselect,
    FlyoutNav,
    FlyoutTrigger,
    ActionsNavAdmin,
    ManagerCategorizationModal,
    Tag: () => import('@/components/atoms/Tag.vue'),
    DotsSVG: () => import('@/assets/icons/dots.svg'),
    ChevronSVG: () => import('@/assets/icons/chevron.svg'),
    LoadingSVG: () => import('@/assets/icons/loader.svg'),
    SearchSVG,
    StarRating,
    PdfSVG,
    Modal,
    PdfViewer,
    ReturnSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-list.scss"></style>
<style lang="scss" src="@/sass/07_elements/multiselect.scss"></style>
