<template>
  <div class="user-list">
    <div class="row  user-list-head">
      <div class="col-4">
        <a class="label -light" @click="handleSort('User.lastname')">
          {{ $t('usersList.fields.name.label') }}
          <Icon v-if="selectedSort.value === 'User.lastname'"
                :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
      <div class="col-5">
        <a class="label -light" @click="handleSort('User.email')">
          {{ $t('usersList.fields.email.label') }}
          <Icon v-if="selectedSort.value === 'User.email'"
                :class="[{ '-rotate-180': selectedSort.direction === 'asc' }, '-size-22']">
            <ChevronFullSVG></ChevronFullSVG>
          </Icon>
        </a>
      </div>
      <div class="col-14">
        <span class="label -light">
          {{ $t('usersList.fields.adminGroups.label') }}
        </span>
      </div>
    </div>
    <div class="user-list-wrapper">
      <div class="row  user-item" v-for="user in users" :key="user.User.uuid" @click.prevent="handleOpenEdit(user.uuid)">
        <div class="col-4">
          <span class="font-weight-semibold">{{ user.User.lastname }}, {{ user.User.firstname }}</span>
        </div>
        <div class="col-5">
          <span>{{ user.User.email }}</span>
        </div>
        <div class="col-14">
          <Tag
            v-for="group in user.group"
            :key="group"
            :class="['-slim  -no-margin-bottom', getGroupTagClass(group)]">
            {{ $t(`groups.${group}`) }}
          </Tag>
        </div>
        <div class="col-1  pos-relative  text-right">
          <FlyoutNav class="mt-xs" :ref="`flyout${user.uuid}`">
              <AdminListActions :user="user" @update="updateList" @openEdit="handleOpenEdit"></AdminListActions>
          </FlyoutNav>
          <FlyoutTrigger :flyoutEl="`flyout${user.uuid}`" :parentRefs="$refs">
            <Icon class="-size-40  -outlined  p-xxs-less  -bg-white"><DotsSVG></DotsSVG></Icon>
          </FlyoutTrigger>
        </div>
      </div>
    </div>
    <div v-if="users && users.length <= 0" class="mt-m">
      <h5 class="h5">{{ $t('adminList.noResults') }}</h5>
    </div>
    <div v-if="users && users.length === (offset+1) * limit" class="mt-m  text-center">
      <Button class="-orange" @click.native="loadMore">{{ $t('usersList.loadMore') }}</Button>
    </div>

    <AdminModal base="adminModalCreate" @update="updateList"></AdminModal>
    <AdminModal base="adminModalEdit" @update="updateList" :user="userToEditFields"></AdminModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { listUsers } from '@/api/admins.api';
import {
  queryObjects,
  arrayUtils,
  objectUtils,
} from '@/mixins';
import formatNumbers from '@/mixins/formatNumbers';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import AdminListActions from '@/components/modules/User/admin/AdminListActions.vue';
import AdminModal from '@/components/modules/User/admin/AdminModal.vue';

import ChevronFullSVG from '@/assets/icons/chevron-full.svg';

export default {
  name: 'AdminList',
  mixins: [queryObjects, formatNumbers, arrayUtils, objectUtils],
  props: {
    defaultSort: Object,
  },
  data() {
    return {
      users: [],
      userToEdit: null,
      selectedSort: {},
      limit: 50,
      offset: 0,
      count: 0,
      debounce: null,
      loading: true,
    };
  },
  computed: {
    ...mapState([
      'usermeta',
      'group',
    ]),

    sort() {
      return { ...this.defaultSort, ...this.selectedSort };
    },

    isAdminUser() {
      return this.usermeta.role === 'admin';
    },

    userToEditFields() {
      if (this.userToEdit) return { group: this.userToEdit.group, ...this.userToEdit.User };
      return null;
    },
  },
  activated() {
    if (!this.$parent.savedPosition) {
      this.updateList();
    }
  },
  methods: {
    getGroupTagClass(groupType) {
      if (['delivery', 'delivery-admin', 'applicant-mgmt'].includes(groupType)) {
        return '-blue';
      }

      if (['management', 'executive-mgmt'].includes(groupType)) {
        return '-green';
      }

      if (['superadmin'].includes(groupType)) {
        return '-orange';
      }

      return '-grey';
    },

    getFilterQuery() {
      const scope = 'list';

      return {
        role: 'admin',
        ...this.buildSortQuery(this.sort),
        limit: this.limit,
        offset: this.offset,
        scope,
      };
    },

    updateList() {
      this.offset = 0;
      this.fetchUsers();
    },

    loadMore() {
      this.offset += 1;
      this.fetchUsers();
    },

    fetchUsers() {
      this.loading = true;
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          listUsers(this.getFilterQuery(), token)
            .then((response) => response.data)
            .then(this.handleUserData);
        });
    },

    handleUserData({ data }) {
      this.count = data.count;

      if (this.offset <= 0) {
        this.$set(this, 'users', data.rows);
      } else {
        this.$set(this, 'users', [...this.users, ...data.rows]);
      }

      this.loading = false;
    },

    handleSort(value) {
      if (this.selectedSort.value !== value) {
        this.$set(this.selectedSort, 'direction', 'desc');
      } else {
        this.$set(this.selectedSort, 'direction', (this.selectedSort.direction === 'asc') ? 'desc' : 'asc');
      }

      this.$set(this.selectedSort, 'value', value);
      this.updateList();
    },

    handleOpenEdit(uuid) {
      this.userToEdit = this.users.find((user) => user.uuid === uuid);
      this.$eventBus.$emit('open-modal-adminModalEdit');
    },
  },
  components: {
    Button,
    ChevronFullSVG,
    Icon,
    FlyoutNav,
    FlyoutTrigger,
    AdminListActions,
    AdminModal,
    Tag: () => import('@/components/atoms/Tag.vue'),
    DotsSVG: () => import('@/assets/icons/dots.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-list.scss"></style>
