<template>
  <div>
    <Background></Background>
    <section class="project-header  pos-relative">
      <div class="row">
        <div class="col-12">
          <StatusTags :project="project"></StatusTags>
          <Tag class="-outlined  -grey" v-if="privatized">{{ $t(`states.projects.private.title`) }}</Tag>
          <h2 class="h2  mb-0  font-weight-normal  color-white">
            ID{{ project.id }}: {{ title }}
          </h2>
        </div>
        <div class="col-12  col-xs-24  text-right  valign-bottom  pos-relative  mt-xs-s">
          <div class="pos-relative" :class="{'display-flex': $matchMedia.xs}">
            <nav class="_menu">
              <span v-if="project.last_campaign_sent_at" class="display-inlineblock  p-xs  color-white">
                {{ $t('projectHeader.lastCampaignSentAt') }} {{ project.last_campaign_sent_at | formatDate }}
              </span>
              <Button v-if="canSendCampaign && status === 'published'"
                      class="-orange mr-xs"
                      @click.native="handleSendCampaign"
              >
                <template v-if="project.last_campaign_sent_at">
                  {{ $t('projectHeader.actions.resendCampaign') }}
                </template>
                <template v-else>
                  {{ $t('projectHeader.actions.sendCampaign') }}
                </template>
              </Button>
              <Button v-if="canPublish"
                      class="-orange"
                      @click.native="handlePublish('publish')"
                      :disabled="status === 'published'">
                <template v-if="status !== 'published' || privatized === true">
                  {{ $t('projectHeader.actions.publish') }}
                </template>
                <template v-else>
                  {{ $t('states.projects.published.title') }}
                </template>
              </Button>
              <Button v-if="!canPublish && ['draft', 'approval'].includes(status)"
                      class="-orange"
                      @click.native="handleApproval()"
                      :disabled="status === 'approval'">
                <template v-if="status !== 'approval'">
                  {{ $t('projectHeader.actions.approve') }}
                </template>
                <template v-else>
                  {{ $t('states.projects.approval.title') }}
                </template>
              </Button>
              <FlyoutTrigger class="ml-xs  -white" v-if="$refs.flyout" :flyoutEl="$refs.flyout"></FlyoutTrigger>
            </nav>
            <FlyoutNav ref="flyout">
              <a :disabled="status === 'draft'" @click="handleSetStatus('setAsDraft')">{{ $t('projectHeader.actions.setAsDraft') }}</a>
              <a v-if="canPublish" :disabled="status === 'published' && privatized === true" @click="handlePublish('private')">{{ $t('projectHeader.actions.private') }}</a>
              <a :disabled="status === 'closed'" @click="handleSetStatus('setAsClosed')">{{ $t('projectHeader.actions.setAsClosed') }}</a>
              <a @click="handleDelete">{{ $t('projectHeader.actions.deletePermanent') }}</a>
            </FlyoutNav>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { removeProject, patchProject } from '@/api/projects.api';
import { parseDates } from '@/mixins';

import Button from '@/components/atoms/Button.vue';
import Tag from '@/components/atoms/Tag.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import Background from '@/components/atoms/Background.vue';
import StatusTags from '@/components/modules/Project/StatusTags.vue';

export default {
  name: 'ProjectHeader',
  mixins: [parseDates],
  props: {
    title: String,
    status: String,
    project: Object,
    privatized: {
      type: Boolean,
      default: false,
    },
    id: Number,
  },
  data() {
    return {
      redirectTime: 5,
      states: {
        setAsDraft: 'draft',
        setAsPrivate: 'private',
        setAsClosed: 'closed',
        setAsApproval: 'approval',
      },
    };
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),

    canPublish() {
      return this.userCan('publishProject');
    },

    canSendCampaign() {
      return this.userCan('sendProjectCampaign');
    },
  },
  methods: {
    redirectTimeout() {
      // @TODO: discard on route change
      setTimeout(() => {
        this.$router.push({ name: 'Projects', lang: this.$i18n.locale });
      }, this.redirectTime * 1000);
    },

    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t('projectHeader.confirm')}: ${this.$t(`projectHeader.actions.${action}`)}`);
    },

    handlePublish(action = 'publish') {
      if (!this.confirmAction(action)) {
        return;
      }

      this.$emit('publish', action);
    },

    handleSendCampaign() {
      this.$emit('sendCampaign');
    },

    handleApproval() {
      if (!this.confirmAction('setAsApproval')) {
        return;
      }

      this.$emit('approve');
    },

    handleSetStatus(action, confirm = true) {
      if (confirm) {
        if (!this.confirmAction(action)) {
          return;
        }
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchProject(this.id, { status: this.states[action] }, token)
            .then(() => {
              this.$eventBus.$emit('notificate');
              this.$emit('update');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },

    handleDelete() {
      if (!this.confirmAction('deletePermanent')) {
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          removeProject(this.id, token)
            .then(() => {
              this.$eventBus.$emit('notificate', { title: this.$t('notifications.adminHeader.removed.title'), message: this.$t('notifications.adminHeader.removed.message', [this.redirectTime]) });
              this.redirectTimeout();
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
            });
        });
    },
  },
  components: {
    Background,
    Button,
    Tag,
    FlyoutNav,
    FlyoutTrigger,
    StatusTags,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-header.scss"></style>
