<template>
  <div class="lead-status" :class="colorClass">
    <slot></slot>
    <template v-if="statusKey">
      {{ $t(`leadStatus.${statusKey}.label`) }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'LeadStatus',
  props: {
    statusKey: String,
  },
  data() {
    return {
      keys: {
        opportunity: {
          color: '-yellow',
        },
        acquired: {
          color: '-green',
        },
        lost: {
          color: '-red',
        },
        current: {
          color: '-turquese',
        },
      },
    };
  },
  computed: {
    colorClass() {
      return (this.statusKey) ? this.keys[this.statusKey]?.color : '';
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/lead-status.scss"></style>
