<template>
  <div class="password-score-meter" :data-score="pwScore">
    <span class="_score-label">{{ $t('signUpForm.inputs.password.strength') }}:</span>
    <span class="_score-indicator"></span>
    <span class="_score-indicator"></span>
    <span class="_score-indicator"></span>
    <span class="_score-indicator"></span>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrength',
  props: {
    pwScore: Number,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/password-strength.scss"></style>
