<template>
  <section class="user-willingness-to-travel">
    <ContentBox>
      <div class="row">
        <div class="col-20">
          <h5 class="h5 font-weight-medium mb-s font-component-headline" :class="{'font-size-l': $matchMedia.sm}">{{ $t('userWillingnessToTravel.headline') }}</h5>
        </div>
        <div class="col-4  text-right">
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32"
                  v-if="canEdit"
                  @click.native="$eventBus.$emit('open-modal-userWillingnessToTravelEdit')">
            <Icon class="-inline  -pos-absolute-center">
              <EditSVG/>
            </Icon>
          </Button>
        </div>
      </div>
      <div class="sub-headline">{{ $t('userWillingnessToTravel.subHeadlines.location') }}</div>
      <div class="font-size-base  font-weight-medium  mb-s  line-height-1">
        {{ userWillingnessToTravel.location_primary }}
        <span v-if="!userWillingnessToTravel.location_primary"
              v-html="$t('userWillingnessToTravel.descriptions.pleaseSpecify')"
              class="_placeholder"></span>
      </div>
      <!-- Wants to travel -->
      <div v-if="userWillingnessToTravel.willingnessToTravel">
        <div class="sub-headline">{{ $t('userWillingnessToTravel.subHeadlines.travel') }}</div>
        <div class="font-size-base  font-weight-medium  line-height-1">
          {{ $t(`userWillingnessToTravel.inputs.traveling.${userWillingnessToTravel.traveling}.label`) }}
        </div>
      </div>
      <!-- Doesn't want to travel -->
      <div v-if="!userWillingnessToTravel.willingnessToTravel">
        <div class="sub-headline">{{ $t('userWillingnessToTravel.subHeadlines.remoteAndHomeOffice') }}</div>
        <div class="font-size-base  font-weight-medium  line-height-1">
          {{ $t('userWillingnessToTravel.descriptions.wantsRemoteAndHomeOffice') }}:
        </div>

        <div class="font-size-base  font-weight-medium line-height-1">
          <span v-if="!userWillingnessToTravel.location_primary && secondaryLocationsFiltered().length === 0"
                v-html="$t('userWillingnessToTravel.descriptions.pleaseSpecify')"
                class="_placeholder"></span>

          <ul class="mb-0 mt-xxs">
            <template v-if="userWillingnessToTravel.location_primary" class="mb-xs">
              <li class="font-size-base font-weight-medium mb-xxs line-height-1">
                <span class="pos-relative -ml-xxs">
                  {{ strippedLocationName(userWillingnessToTravel.location_primary) }}, {{ $t('userWillingnessToTravel.withinTheRadiusOf') }} {{ userWillingnessToTravel.radius_primary }} km
                </span>
              </li>
            </template>
            <li v-for="(secondary, index) in secondaryLocationsFiltered()"
                v-bind:key="index"
                class="font-size-base font-weight-medium line-height-1"
                :class="{'mb-xxs': index < secondaryLocationsFiltered().length -1}"
            >
              <span class="pos-relative -ml-xxs">
                {{ strippedLocationName(secondary.location) }}, {{ $t('userWillingnessToTravel.withinTheRadiusOf') }} {{ secondary.radius || userWillingnessToTravel.radiuses[0] }} km
              </span>
            </li>
          </ul>
        </div>
      </div>
    </ContentBox>
    <Modal id="userWillingnessToTravelEdit"
           :title="$t('userWillingnessToTravel.headline')"
           class="-thin"
           v-if="canEdit"
           :disable="$v.$error"
           @close="cancel"
    >
      <FormElement>
        <h6 class="h6  font-weight-medium">{{ $t('userWillingnessToTravel.sections.location_primary') }}</h6>
        <div class="row">
          <div class="col-24">
            <InputText v-model="$v.userWillingnessToTravel.location_primary.$model"
                       :class="{ '-has-error': $v.userWillingnessToTravel.location_primary.$error }"
                       type="text"
                       name="location_primary"
                       id="location_primary"
                       base="userWillingnessToTravel"
                       autocomplete="address-level2"
                       @input="dataWasChanged = true; $v.$touch();"
            />
          </div>
        </div>
        <h6 class="h6  font-weight-medium mt-xs">{{ $t('userWillingnessToTravel.sections.willingness_to_travel') }}</h6>
        <div class="row">
          <div class="col-24">
            <InputCheckbox v-model="userWillingnessToTravel.willingnessToTravel"
                           base="userWillingnessToTravel"
                           name="willingness_to_travel.yes"
                           class="text-uppercase font-weight-bold"
                           @input="dataWasChanged = true"
            />
            <div class="checkbox-offset" :class="{'font-weight-medium': userWillingnessToTravel.willingnessToTravel}">
              {{ $t('userWillingnessToTravel.descriptions.yes') }}
            </div>
          </div>
        </div>
        <div v-if="userWillingnessToTravel.willingnessToTravel" class="checkbox-offset">
          <div class="row mt-xs">
            <div class="col-24">
              <div class="radio-group">
                <InputRadio v-for="(choice, key) in $t('userWillingnessToTravel.inputs.traveling')"
                            :key="`traveling-${key}`"
                            :choice="`${key}`"
                            name="traveling"
                            base="userWillingnessToTravel"
                            class="mr-s -small valign-top"
                            @input="dataWasChanged = true"
                            v-model="userWillingnessToTravel.traveling"
                >
                  <template slot="label">
                    {{ choice['label'] }} <br>
                    <span class="checkbox-offset">{{ choice['explanation'] }}</span>
                  </template>
                </InputRadio>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-xs mb-xs"/>
        <div class="row">
          <div class="col-24">
            <InputCheckbox v-model="unwillingnessToTravel"
                           base="userWillingnessToTravel"
                           name="willingness_to_travel.no"
                           class="text-uppercase font-weight-bold"
                           @input="dataWasChanged = true"
            />
            <div class="checkbox-offset" :class="{'font-weight-medium': ! userWillingnessToTravel.willingnessToTravel}">
              {{ $t('userWillingnessToTravel.descriptions.no') }}
            </div>
          </div>
        </div>
        <div v-if="! userWillingnessToTravel.willingnessToTravel" class="checkbox-offset">
          <div class="row mt-xs">
            <div class="col-16">
              <InputText v-model="userWillingnessToTravel.location_primary"
                         type="text"
                         name="location_primary_read_only"
                         base="userWillingnessToTravel"
                         autocomplete="address-level2"
                         :readonly="true"
                         @input="dataWasChanged = true"
              />
            </div>
            <div class="col-6">
              <InputSelect :options="userWillingnessToTravel.radiuses"
                           v-model="$v.userWillingnessToTravel.radius_primary.$model"
                           :class="{ '-has-error': $v.userWillingnessToTravel.radius_primary.$error }"
                           name="radius_primary"
                           base="userWillingnessToTravel"
                           class="radius-select"
                           @input="dataWasChanged = true"
              />
            </div>
          </div>
          <div class="row mt-xs" v-for="(secondary_location, index) in $v.userWillingnessToTravel.location_secondary.$each.$iter" v-bind:key="index">
            <div class="col-16">
              <InputText v-model="secondary_location.location.$model"
                         :class="{ '-has-error': secondary_location.location.$error }"
                         type="text"
                         :name="index == 0 ? 'location_secondary' : ''"
                         base="userWillingnessToTravel"
                         autocomplete="address-level2"
                         @input="dataWasChanged = true"
              />
            </div>
            <div class="col-6">
              <InputSelect :options="userWillingnessToTravel.radiuses" v-model="secondary_location.radius.$model"
                           :class="{ '-has-error': secondary_location.radius.$error }"
                           :name="index == 0 ? 'radius' : ''"
                           base="userWillingnessToTravel"
                           class="radius-select"
                           @input="dataWasChanged = true"
              />
            </div>
            <div class="col-2 valign-bottom text-pb-xs">
              <Link href="#" class="-orange" @click.native="removeLocation(index)">
                <Icon class="-inline">
                  <RemoveCircleSVG/>
                </Icon>
              </Link>
            </div>
          </div>
          <div class="row mt-xs">
            <div class="col-24">
              <Link v-if="userWillingnessToTravel.location_secondary.length < maxSecondaryLocations"
                    href="#"
                    @click.native.prevent="addLocation"
                    class="-orange"
              >
                {{ $t('userWillingnessToTravel.addLocation') }}
                <Icon class="-inline">
                  <PlusSVG/>
                </Icon>
              </Link>
            </div>
          </div>
        </div>
      </FormElement>
      <template slot="footer">
        <div class="text-right">
          <Button class="-orange -outlined mr-xs" @click.native="$eventBus.$emit('close-modal-userWillingnessToTravelEdit');">
            {{ $t('userWillingnessToTravel.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="saveAndExit">
            {{ $t('userWillingnessToTravel.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, requiredIf, numeric, minLength,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import { updateWillingnessToTravel } from '@/api/users.api';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Link from '@/components/atoms/Link.vue';

import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputRadio from '@/components/elements/inputs/InputRadio.vue';
import Modal from '@/components/elements/Modal.vue';

import EditSVG from '@/assets/icons/edit.svg';
import PlusSVG from '@/assets/icons/plus.svg';
import RemoveCircleSVG from '@/assets/icons/remove-circle.svg';

export default {
  name: 'UserWillingnessToTravel',
  mixins: [validationMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      dataWasChanged: false,
      defaultRadius: 50,
      userWillingnessToTravel: {
        radiuses: {
          50: '50 km',
          75: '75 km',
          100: '100 km',
          125: '125 km',
          150: '150 km',
          200: '200 km',
          500: '500 km',
          1000: '1000 km',
        },
        willingnessToTravel: false,
        traveling: 'national',
        location_primary: null,
        radius_primary: this.defaultRadius,
        location_secondary: [],
      },
      maxSecondaryLocations: 4,
    };
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),

    unwillingnessToTravel: {
      get() {
        return this.userWillingnessToTravel.willingnessToTravel === false;
      },
      set(newValue) {
        this.userWillingnessToTravel.willingnessToTravel = !newValue;
      },
    },

    canEdit() {
      return this.userCan('edit', this.user.uuid) || this.userCan('updateManagerLocations');
    },
  },
  validations: {
    userWillingnessToTravel: {
      location_primary: {
        required,
        minLength: minLength(2),
      },
      radius_primary: {
        required: requiredIf((vm) => vm.willingnessToTravel),
        numeric,
      },
      location_secondary: {
        $each: {
          location: {
            required,
            minLength: minLength(2),
          },
          radius: {
            required,
          },
        },
      },
    },
  },
  mounted() {
    this.setUserWillingnessToTravelToUserObjectCurrent();
  },
  methods: {
    setUserWillingnessToTravelToUserObjectCurrent() {
      this.userWillingnessToTravel.willingnessToTravel = this.user.willingness_to_travel;
      this.userWillingnessToTravel.traveling = this.user.traveling_international ? 'international' : 'national';

      const primaryLocation = this.user.locations?.filter((location) => location.primary)?.[0];
      const secondaryLocations = this.user.locations?.filter((location) => !location.primary);

      this.userWillingnessToTravel.location_primary = primaryLocation?.location;
      this.userWillingnessToTravel.radius_primary = primaryLocation?.radius ?? this.defaultRadius;

      this.userWillingnessToTravel.location_secondary = secondaryLocations?.map((location) => _.pick(location, 'location', 'radius', 'primary'));
    },

    setWillingnessToTravelToUserObject() {
      this.user.willingness_to_travel = this.userWillingnessToTravel.willingnessToTravel;
      this.user.traveling_international = this.userWillingnessToTravel.traveling === 'international';
      this.user.locations = this.willingnessToTravelLocations();
    },

    willingnessToTravelLocations() {
      return [
        {
          location: this.userWillingnessToTravel.location_primary,
          radius: parseInt(this.userWillingnessToTravel.radius_primary ?? this.defaultRadius, 10),
          primary: true,
        },
      ].concat(
        this.secondaryLocationsFiltered().map((secondary) => ({ location: secondary.location, radius: parseInt(secondary.radius, 10), primary: false })),
      );
    },

    secondaryLocationsFiltered() {
      return this.userWillingnessToTravel.location_secondary
        ?.filter((secondary) => secondary.location && secondary.location.trim().length > 0) ?? [];
    },

    strippedLocationName(location) {
      return (typeof location === 'string' || location instanceof String) && location.includes(',')
        ? location.substring(0, location.indexOf(','))
        : location;
    },

    addLocation() {
      if (this.userWillingnessToTravel.location_secondary.length < this.maxSecondaryLocations) {
        this.dataWasChanged = true;
        this.userWillingnessToTravel.location_secondary.push({
          location: '',
          radius: this.defaultRadius,
        });
        this.$v.$touch();
      }
    },

    removeLocation(index) {
      this.dataWasChanged = true;
      this.userWillingnessToTravel.location_secondary.splice(index, 1);
      this.$v.$touch();
    },

    cancel() {
      if (this.dataWasChanged) {
        this.setUserWillingnessToTravelToUserObjectCurrent();
        this.dataWasChanged = false;
      }
    },

    saveAndExit() {
      if (this.dataWasChanged) {
        this.$v.$touch();
        if (!this.$v.$invalid && this.canEdit) {
          this.$store.dispatch('isLoggedIn')
            .then((token) => {
              updateWillingnessToTravel(
                this.user.uuid,
                this.userWillingnessToTravel.willingnessToTravel,
                this.willingnessToTravelLocations(),
                this.userWillingnessToTravel.traveling === 'international',
                token,
              )
                .then(() => {
                  this.dataWasChanged = false;
                  this.$emit('update');
                  this.$eventBus.$emit('notificate');
                })
                .catch((error) => {
                  this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
                })
                .finally(() => {
                  this.$eventBus.$emit('close-modal-userWillingnessToTravelEdit');
                });
            });
        }
      } else {
        this.$eventBus.$emit('close-modal-userWillingnessToTravelEdit');
        this.$eventBus.$emit('notificate');
      }
    },
  },
  components: {
    Button,
    ContentBox,
    EditSVG,
    FormElement,
    Icon,
    InputCheckbox,
    InputRadio,
    InputSelect,
    InputText,
    Link,
    Modal,
    PlusSVG,
    RemoveCircleSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-willingness-to-travel.scss"></style>
